import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'atoms/buttons/Button';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import CongratsScreen from 'components/congratulations/CongratsScreen';
import { ProbabilisticBundle } from 'components/congratulations/ProbabilisticBundle';
import { cardsAnimation } from 'components/congratulations/ui';
import { FOOTBALL_PLAY_INVENTORY_BOXES_TYPE } from 'constants/__generated__/routes';
import { isType } from 'gql';
import { glossary } from 'lib/glossary';
import { mobileAndAbove } from 'style/mediaQuery';

import Layout from '../Layout';
import { DecisivePurchaseNotificationDialog_decisivePurchaseNotification } from './__generated__/index.graphql';

const Wrapper = styled(Horizontal).attrs({ gap: 0 })`
  position: relative;
  height: 250px;
  ${cardsAnimation}
  @media ${mobileAndAbove} {
    height: 380px;
  }
`;

export interface Props {
  notification: DecisivePurchaseNotificationDialog_decisivePurchaseNotification;
  onClose: (markAllAsRead?: boolean) => void;
}

export const DecisivePurchaseNotificationDialog = ({
  notification,
  onClose,
}: Props) => {
  const {
    reward,
    decisiveCard: {
      rarityTyped,
      anyPlayer: { displayName },
    },
  } = notification;
  if (!isType(reward, 'ProbabilisticBundleReward')) return null;
  const {
    probabilisticBundle: {
      config: { name },
    },
  } = reward;

  return (
    <Layout onClose={onClose}>
      {({ onClose: onCloseDialog }) => (
        <CongratsScreen
          rarityBackground={rarityTyped}
          title={
            <FormattedMessage
              id="DecisivePurchaseNotificationDialog.title"
              defaultMessage="🎁{br}Decisive{br}reward"
              values={{ br: <br /> }}
            />
          }
          subtitle={
            <FormattedMessage
              id="DecisivePurchaseNotificationDialog.subtitle"
              defaultMessage="{playerDisplayName} has come up big!{br}Claim your reward."
              values={{
                playerDisplayName: displayName,
                br: <br />,
              }}
            />
          }
          main={
            <Wrapper>
              <ProbabilisticBundle probabilisticBundleReward={reward} />
            </Wrapper>
          }
          isBundle={false}
          actions={
            <Vertical center>
              <Button
                size="medium"
                color="primary"
                onClick={onCloseDialog}
                to={generatePath(FOOTBALL_PLAY_INVENTORY_BOXES_TYPE, {
                  type: rarityTyped,
                })}
              >
                <FormattedMessage
                  id="buyTokenConfirmation.buy.claimName"
                  defaultMessage="Claim {name}"
                  values={{ name }}
                />
              </Button>
              <Button size="medium" color="transparent" onClick={onCloseDialog}>
                <FormattedMessage {...glossary.close} />
              </Button>
            </Vertical>
          }
        />
      )}
    </Layout>
  );
};

DecisivePurchaseNotificationDialog.fragments = {
  notification: gql`
    fragment DecisivePurchaseNotificationDialog_decisivePurchaseNotification on DecisivePurchaseNotification {
      id
      sport
      decisiveCard: card {
        slug
        rarityTyped
        anyPlayer {
          slug
          displayName
        }
      }
      reward {
        id
        ... on ProbabilisticBundleReward {
          id
          probabilisticBundle {
            id
            config {
              id
              name
            }
          }
          ...ProbabilisticBundle_probabilisticBundleReward
        }
      }
    }
    ${ProbabilisticBundle.fragments.probabilisticBundleReward}
  ` as TypedDocumentNode<DecisivePurchaseNotificationDialog_decisivePurchaseNotification>,
};

export default DecisivePurchaseNotificationDialog;
