import { defineMessages } from 'react-intl';

import { useTypedParams } from '@sorare/routing';

import useQueryString from '@sorare/core/src/hooks/useQueryString';

import Form from 'pages/no-card-route/Form';

import { PageParams } from './__generated__/routeParams';
import useConfirmSo5NoCardLineup from './useConfirmSo5NoCardLineup';

const messages = defineMessages({
  title: {
    id: 'NoCardEntry.Confirm.title',
    defaultMessage:
      'Your team is confirmed and will be submitted in the Game Week!',
  },
});

const Accept = () => {
  const { so5LineupId } = useTypedParams<PageParams>();
  const so5LeaderboardId = useQueryString('so5_leaderboard_id');
  const confirmLineup = useConfirmSo5NoCardLineup();

  const mutate = async () => {
    const { errors } = await confirmLineup({
      so5NoCardLineupId: `So5NoCardLineup:${so5LineupId}`,
      so5LeaderboardId: `So5Leaderboard:${so5LeaderboardId}`,
    });
    return errors.length === 0;
  };

  return <Form mutate={mutate} title={messages.title} postAction="lobby" />;
};

export default Accept;
