import { ApolloQueryResult } from '@apollo/client';
import { createContext, useContext } from 'react';

import { Currency, CurrencyCode } from 'lib/fiat';

import {
  ConfigQuery,
  ConfigQuery_currentLocation,
  ConfigQuery_currentUser,
  ConfigQuery_exchangeRate,
} from './types';

export interface AlgoliaCardIndexes {
  // cards indices
  'Cards New': string;
  'Cards Highest Average Score': string;
  'Cards Highest Price': string;
  'Cards Lowest Price': string;
  'Cards Player Name': string;

  // blockchain cards indices
  'Ending Soon': string;
  'Newly Listed': string;
  'Highest Price': string;
  'Lowest Price': string;
  'Highest Average Score': string;
  'Best Value': string;
  'Popular Player': string;

  // On sale cards indices
  'Cards On Sale Ending Soon': string;
  'Cards On Sale Newly Listed': string;
  'Cards On Sale Highest Price': string;
  'Cards On Sale Lowest Price': string;
  'Cards On Sale Highest Average Score': string;
  'Cards On Sale Best Value': string;
  'Cards On Sale Popular Player': string;

  // Common cards indices
  'Common Cards': string;
  'Common Cards Highest Average Score': string;
  'Common Cards Player Name': string;
}

export interface AlgoliaIndexes extends AlgoliaCardIndexes {
  User: string;
  Player: string;
  Club: string;
  League: string;
  Country: string;
  'National Team': string;
  Competition: string;
}

export type AlgoliaIndex = keyof AlgoliaIndexes;

export type AlgoliaCardIndexesName = keyof AlgoliaCardIndexes;

export type AlgoliaCardIndexesNames = AlgoliaCardIndexesName[];

export interface ConfigContext {
  algoliaIndexes: AlgoliaIndexes;
  algoliaCardIndexes: AlgoliaCardIndexes;
  algoliaApplicationId: string;
  algoliaSearchApiKey: string;
  giphyApiKey: string;
  ethereumNetworkId: string;
  ethereumEndpoint: string;
  sorareTokensAddress: EthereumAddress;
  baseballTokensAddress: EthereumAddress;
  nbaTokensAddress: EthereumAddress;
  sorareCardsAddress: EthereumAddress;
  footballNationalSeriesTokensAddress: EthereumAddress;
  sorareEncryptionKey: string;
  sponsorAccountAddress: string;
  migratorAddress?: string | null;
  bankAddress: string;
  ethAssetType: string | null;
  ethQuantum: bigint;
  starkExchangeAddress: EthereumAddress;
  relayAddress: string;
  so5: {
    footballCompetitionEligibilityAlgoliaFilters: Record<string, string>;
    nbaCompetitionEligibilityAlgoliaFilters: Record<string, string>;
    baseballCompetitionEligibilityAlgoliaFilters: Record<string, string>;
  };
  currentUser: ConfigQuery_currentUser | null;
  refetch: () => Promise<ApolloQueryResult<ConfigQuery>>;
  updateQuery: (user: ConfigQuery_currentUser) => void;
  exchangeRate: ConfigQuery_exchangeRate & {
    getEthRate: (to: CurrencyCode) => number;
  };
  marketFeeRateBasisPoints: number;
  marketFeePercentagePoints: bigint;
  checkoutFeesBasisPoints: string;
  idealDepositFeesAmountMinor: number;
  mangopayVisaFeesBasisPoints: string;
  mangopayAmexFeesBasisPoints: string;
  paypalDepositFeesBasisPoints: number;
  mangopayApplePayDepositFeesBasisPoints: number;
  stripeCreditCardFeesBasisPoints: string;
  defaultFiatCurrency: Currency;
  minimumReceiveWeiAmount: bigint;
  currentLocation: ConfigQuery_currentLocation;
  marketplacePromotionalEvents: ConfigQuery['config']['marketplacePromotionalEvents'];
  marketplaceSpecialEvents: ConfigQuery['config']['marketplaceSpecialEvents'];
  counts: ConfigQuery['config']['counts'];
}

export const configContext = createContext<ConfigContext | null>(null);

export const useConfigContext = () => useContext(configContext)!;

export default configContext.Provider;
