import { defineMessages } from 'react-intl';

import { useTypedParams } from '@sorare/routing';

import Form from 'pages/no-card-route/Form';

import { PageParams } from './__generated__/routeParams';
import useAcceptSo5NoCardLineup from './useAcceptSo5NoCardLineup';

const messages = defineMessages({
  title: {
    id: 'NoCardEntry.Accept.title',
    defaultMessage:
      'Your team is confirmed and will be submitted in the Game Week!',
  },
  subtitle: {
    id: 'NoCardEntry.Accept.subtitle',
    defaultMessage:
      'We’ll send you an email for you to select the competition your team can be entered into.',
  },
});

const Accept = () => {
  const { so5LineupId } = useTypedParams<PageParams>();
  const acceptLineup = useAcceptSo5NoCardLineup();

  const mutate = async () => {
    const { errors } = await acceptLineup({
      so5NoCardLineupId: `So5NoCardLineup:${so5LineupId}`,
    });
    return errors.length === 0;
  };

  return (
    <Form mutate={mutate} title={messages.title} subtitle={messages.subtitle} />
  );
};

export default Accept;
