/* eslint-disable react/no-unknown-property */
import { TypedDocumentNode, gql } from '@apollo/client';
import { faCube } from '@fortawesome/pro-regular-svg-icons';
import { Suspense, lazy, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import GlareEffect from 'atoms/animations/GlareEffect';
import IconButton from 'atoms/buttons/IconButton';
import { Horizontal } from 'atoms/layout/flex';
import { proxyUrl } from 'atoms/ui/ResponsiveImg';
import useDisable3D from 'hooks/useDisable3D';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import { getCardRepresentation } from 'lib/cards';

import { Card3DWithFallback_anyCardInterface } from './__generated__/index.graphql';

// Lazy load threejs and related libraries
const ShowcaseCard = lazy(
  async () => import('@sorare/trois/src/components/ShowcaseCard')
);

const Picture = styled.div<{ visible: boolean }>`
  position: absolute;
  max-width: 100%;
  margin: auto;
  aspect-ratio: var(--card-aspect-ratio);
  /** magic numbers to create some fade out effect when the 3d model is loaded */
  transform: scale(0.845);
  height: 100%;
  ${({ visible }) =>
    visible
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
          pointer-events: none;
        `}
  transition: opacity 0.3s;
`;

const Container = styled(Horizontal).attrs({ gap: 0, center: true })`
  isolation: isolate;
  position: relative;
  width: 100%;
  aspect-ratio: var(--card-aspect-ratio);
  height: 100%;
  overflow: hidden;
  user-select: none;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const FadeIn = styled.div`
  animation: 0.3s ${fadeIn} forwards;
  width: 100%;
  height: 100%;
`;

const FallbackPicture = ({
  url,
  visible = true,
  alt,
}: {
  url: string;
  visible?: boolean;
  alt?: string;
}) => {
  return (
    <Picture visible={visible}>
      <GlareEffect
        pictureUrl={proxyUrl(url, { cropWidth: 640, xl: true })}
        alt={alt}
      />
    </Picture>
  );
};

const ThreeDButton = styled(IconButton)<{ active: boolean }>`
  position: absolute;
  bottom: 0;
  right: 0;

  &::before {
    ${({ active }) => (active ? 'display: block;' : 'display: none;')}
    content: '';
    background-color: var(--c-neutral-1000);
    width: 30px;
    position: absolute;
    height: 2px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

type Props = {
  card: Card3DWithFallback_anyCardInterface;
  forceDisable3d?: boolean;
  disableFallback?: boolean;
  startOnBack?: boolean;
};

const ThreeDToggle = ({
  disabled,
  onClick,
}: {
  disabled: boolean;
  onClick: () => void;
}) => {
  return (
    <ThreeDButton
      active={!disabled}
      onClick={onClick}
      color={disabled ? 'quaternary' : 'gray'}
      icon={faCube}
      small
    />
  );
};

export const Card3DWithFallback = ({
  card,
  forceDisable3d,
  disableFallback = false,
  startOnBack = false,
}: Props) => {
  const {
    flags: { useCardBackStats = false },
  } = useFeatureFlags();
  const { disable3D, setDisable3D } = useDisable3D();
  const { isMobileApp } = useIsMobileApp();
  const gltfUrl = card.threeDimensionalAsset?.gltfUrl;
  const [showFallback, setShowFallback] = useState(!disableFallback);
  const alt = `${card.anyPlayer.displayName}${card.rarityTyped ? ` - ${card.rarityTyped}` : ''}`;
  const fallback = (
    <FallbackPicture
      visible={showFallback}
      url={card.pictureUrl || ''}
      alt={alt}
    />
  );

  if (gltfUrl && !disable3D && !forceDisable3d) {
    const cardRepresentation = getCardRepresentation(card, {
      useCardBackStats,
    });
    return (
      <Container>
        <>
          {fallback}
          <Suspense>
            <FadeIn>
              <ShowcaseCard
                card3d={cardRepresentation}
                loadedCallback={() => {
                  // Since this callback is called when the canvas is created but not commited, change the state after the canvas is commited artifically.
                  setTimeout(() => setShowFallback(false), 30);
                }}
                onContextLost={() => setShowFallback(true)}
                onContextRestored={() => setShowFallback(false)}
                startOnBack={startOnBack}
                fallback={fallback}
              />
            </FadeIn>
          </Suspense>
          {isMobileApp && (
            <ThreeDToggle
              disabled={disable3D}
              onClick={() => setDisable3D(!disable3D)}
            />
          )}
        </>
      </Container>
    );
  }

  return (
    <Container>
      <FallbackPicture url={card.pictureUrl!} alt={alt} />
      {gltfUrl && isMobileApp && !forceDisable3d && (
        <ThreeDToggle
          disabled={disable3D}
          onClick={() => setDisable3D(!disable3D)}
        />
      )}
    </Container>
  );
};

Card3DWithFallback.fragments = {
  anyCardInterface: gql`
    fragment Card3DWithFallback_anyCardInterface on AnyCardInterface {
      slug
      rarityTyped
      anyPlayer {
        slug
        displayName
      }
      ...getCardRepresentation_anyCardInterface
    }
    ${getCardRepresentation.fragments.anyCardInterface}
  ` as TypedDocumentNode<Card3DWithFallback_anyCardInterface>,
};
