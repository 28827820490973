import OAuthContent from '@sorare/core/src/components/OAuth';
import RequireAuth from '@sorare/core/src/routing/RequireAuth';

const OAuth = () => {
  return (
    <RequireAuth>
      <OAuthContent />
    </RequireAuth>
  );
};

export default OAuth;
