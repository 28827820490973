import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import LoadingIndicator from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { AppLayout } from '@sorare/core/src/components/navigation/AppLayout';

import { RevealAnnouncement } from 'components/dialogs/RevealAnnouncement';
import { FootballMarketSubscriptions } from 'routing/FootballMarketSubscriptions';

const FootballLayout = () => {
  return (
    <AppLayout>
      <FootballMarketSubscriptions />
      <RevealAnnouncement />
      <Suspense fallback={<LoadingIndicator fullHeight />}>
        <Outlet />
      </Suspense>
    </AppLayout>
  );
};
export default FootballLayout;
