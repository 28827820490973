import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { FixtureState } from '__generated__/globalTypes';
import { Timer } from 'atoms/icons/Timer';
import { Horizontal } from 'atoms/layout/flex';
import { BodyS } from 'atoms/typography';
import { LiveDot } from 'components/live/LiveDot';
import { useTimeLeft } from 'hooks/useTimeLeft';
import { navLabels } from 'lib/glossary';

const Root = styled(Horizontal).attrs({ gap: 0.5 })`
  padding: var(--half-unit) var(--unit);
  border-radius: var(--unit);
  backdrop-filter: blur(2px);
  white-space: nowrap;

  &.opened {
    color: var(--c-yellow-600);
    background: rgba(240, 201, 86, 0.16);
  }
  &.live {
    background: rgba(245, 103, 87, 0.16);
    &.onLightBackground {
      background: var(--c-red-600);
    }
  }
  &.preparing {
    color: var(--c-brand-300);
    background: rgba(var(--c-rgb-brand-300), 0.3);
  }
`;

type CommonProps = {
  onLightBackground?: boolean;
};

type Props = CommonProps &
  (
    | {
        fixtureState: FixtureState.started;
      }
    | {
        fixtureState: FixtureState.closed;
      }
    | {
        fixtureState: FixtureState.opened;
        startDate: Date;
      }
  );

const UpcomingFixtureStatus = ({ startDate }: { startDate: Date }) => {
  const { message } = useTimeLeft(startDate);

  return (
    <Root className="opened">
      <Timer size={2} />
      <BodyS as="p" bold color="var(--c-neutral-1000)">
        {message}
      </BodyS>
    </Root>
  );
};

export const FixtureStatus = (props: Props) => {
  const { fixtureState, onLightBackground } = props;
  if (fixtureState === FixtureState.opened) {
    const { startDate } = props;
    return <UpcomingFixtureStatus startDate={startDate} />;
  }
  if (fixtureState === FixtureState.started) {
    const textColor = onLightBackground
      ? 'var(--c-neutral-1000)'
      : 'var(--c-red-600)';
    return (
      <Root
        className={classNames('live', {
          onLightBackground,
        })}
      >
        <LiveDot animate color={textColor} />
        <BodyS as="p" bold color={textColor}>
          <FormattedMessage {...navLabels.live} />
        </BodyS>
      </Root>
    );
  }

  return null;
};
