import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Sport } from '__generated__/globalTypes';
import mlbLogoSrc from 'assets/logos/mlb/mlb.svg';
import mlbpaLogoSrc from 'assets/logos/mlb/mlbpa.svg';
import nbaLogoSrc from 'assets/logos/nba/nba.svg';
import nbpaLogoSrc from 'assets/logos/nba/nbpa.svg';
import Button from 'atoms/buttons/Button';
import { SorareLogo } from 'atoms/icons/SorareLogo';
import { Horizontal, SBHorizontal } from 'atoms/layout/flex';
import { Text18 } from 'atoms/typography';
import { ContentContainer } from 'components/landing/LandingMultiSport/ui';
import { FOOTBALL_PATH, MLB_PATH, NBA_PATH } from 'constants/routes';
import { glossary, sportsLabelsMessages } from 'lib/glossary';
import { laptopAndAbove, tabletAndAbove } from 'style/mediaQuery';
import { hideScrollbar } from 'style/utils';

import playNowBaseball from './assets/playNowBaseball.jpg';
import playNowFootball from './assets/playNowFootball.jpg';
import playNowNBA from './assets/playNowNBA.jpg';

const Wrapper = styled(ContentContainer)`
  ${hideScrollbar}
  z-index: 1;
  display: grid;
  position: relative;
  overflow: auto;
  gap: var(--double-unit);
  margin: var(--double-and-a-half-unit) auto
    calc(var(--double-and-a-half-unit) * 3) auto;
  grid-template-columns: repeat(1, minmax(320px, 1fr));

  @media ${tabletAndAbove} {
    grid-auto-flow: column;
    gap: var(--unit);
    margin: var(--double-and-a-half-unit) auto
      calc(var(--double-and-a-half-unit) * 2) auto;
  }
  @media ${laptopAndAbove} {
    margin: var(--double-and-a-half-unit) auto;
  }
`;

const PlayNowButton = styled(SBHorizontal).attrs({ gap: 0 })`
  cursor: pointer;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: var(--intermediate-unit);
  padding: var(--double-unit) var(--intermediate-unit);

  &:hover {
    opacity: 0.9;
  }
`;

const Title = styled(Text18)`
  text-transform: uppercase;
  font-family: 'Druk Wide';
  margin-bottom: var(--unit);
`;

const LogosWrapper = styled(Horizontal).attrs({ gap: 0.5 })``;

const VerticalDivider = styled.div`
  width: 1px;
  height: 10px;
  background-color: var(--c-static-neutral-100);
`;

export const PlayNowSportsButtons = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <PlayNowButton
        style={{
          backgroundPosition: 'top',
          backgroundImage: `url(${playNowFootball})`,
        }}
        onClick={() => navigate(FOOTBALL_PATH)}
      >
        <div>
          <Title>
            <FormattedMessage {...sportsLabelsMessages[Sport.FOOTBALL]} />
          </Title>
          <SorareLogo />
        </div>
        <Button color="tertiary" size="small">
          <FormattedMessage {...glossary.playNow} />
        </Button>
      </PlayNowButton>
      <PlayNowButton
        style={{
          backgroundImage: `url(${playNowNBA})`,
        }}
        onClick={() => navigate(NBA_PATH)}
      >
        <div>
          <Title>
            <FormattedMessage {...sportsLabelsMessages[Sport.NBA]} />
          </Title>
          <LogosWrapper>
            <SorareLogo />
            <VerticalDivider />
            <img src={nbaLogoSrc} height={16} alt="NBA" />
            <VerticalDivider />
            <img src={nbpaLogoSrc} height={13} alt="NBPA" />
          </LogosWrapper>
        </div>
        <Button color="tertiary" size="small">
          <FormattedMessage {...glossary.playNow} />
        </Button>
      </PlayNowButton>
      <PlayNowButton
        style={{
          backgroundImage: `url(${playNowBaseball})`,
        }}
        onClick={() => navigate(MLB_PATH)}
      >
        <div>
          <Title>
            <FormattedMessage {...sportsLabelsMessages[Sport.BASEBALL]} />
          </Title>
          <LogosWrapper>
            <SorareLogo />
            <VerticalDivider />
            <img src={mlbLogoSrc} width={20} alt="MLB" />
            <VerticalDivider />
            <img src={mlbpaLogoSrc} width={55} alt="MLBPA" />
          </LogosWrapper>
        </div>
        <Button color="tertiary" size="small">
          <FormattedMessage {...glossary.playNow} />
        </Button>
      </PlayNowButton>
    </Wrapper>
  );
};
