import { ComponentProps, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { Link } from '@sorare/routing';

import { Sport } from '__generated__/globalTypes';
import { DialogKey } from 'components/navigation/WithDialogs';
import { FOOTBALL_BUNDLED_AUCTIONS_ID } from 'constants/__generated__/routes';
import { LEGACY_BUNDLED_AUCTION } from 'constants/routes';
import { useSportContext } from 'contexts/sport';
import idFromObject from 'gql/idFromObject';
import { useGenerateDialogPath } from 'hooks/navigation/useGenerateDialogPath';
import { useIsMobileApp } from 'hooks/useIsMobileApp';

import { IosOpenItemDialog } from './IosOpenItemDialog';
import { Item } from './types';

type Props = {
  item: Item;
  children?: ReactNode;
  className?: string;
  onClick?: ComponentProps<typeof Link>['onClick'];
  sport: Sport;
};

const itemRoute = (item: Item, sport?: Sport) => {
  switch (item.__typename) {
    case 'CardSubscription':
    case 'BaseballCard':
    case 'NBACard':
    case 'Card': {
      return {
        dialog: { key: DialogKey.card, value: item.slug },
      };
    }
    // This is always an auction with multiple cards as a TokenAuction with a single Card inside is passed as a regular Token.
    case 'TokenAuction': {
      if (sport === Sport.FOOTBALL) {
        return {
          route: FOOTBALL_BUNDLED_AUCTIONS_ID,
          params: { id: idFromObject(item.id) },
        };
      }
      return {
        route: LEGACY_BUNDLED_AUCTION,
        params: { id: idFromObject(item.id) },
      };
    }
    default: {
      // never reached
      return { route: undefined, params: {} };
    }
  }
};

export const OpenItemDialogLink = ({
  item,
  children,
  className,
  onClick,
  sport,
}: Props) => {
  const { canUseIosNativeCardPage } = useIsMobileApp();

  const location = useLocation();
  const generateDialogPath = useGenerateDialogPath();
  const { generateSportContextPath, sport: sportContext } = useSportContext();
  const { route, dialog, params } = itemRoute(item, sportContext); // using sport from context here so we only use the dialog if the sport is in the url and redirect to the standalone page when there is no sport.
  const normalRoute =
    route &&
    `${generateSportContextPath(route, {
      params,
      sport,
    })}${location.search}`;
  const dialogRoute =
    dialog?.value && generateDialogPath(dialog.key, dialog.value);

  const dest = normalRoute || dialogRoute || undefined;

  if (!dest) {
    return null;
  }

  if (canUseIosNativeCardPage) {
    return <IosOpenItemDialog item={item}>{children}</IosOpenItemDialog>;
  }

  return (
    <Link
      to={dest}
      onClick={onClick}
      state={{
        item,
      }}
      className={className}
    >
      {children}
    </Link>
  );
};

export default OpenItemDialogLink;
