import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { Fragment, ReactNode } from 'react';
import {
  FormattedList,
  FormattedMessage,
  MessageDescriptor,
  defineMessages,
} from 'react-intl';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { UserFlagReason } from '__generated__/globalTypes';
import { FontAwesomeIcon } from 'atoms/icons';
import { FullWidthContainer } from 'atoms/layout/FullWidthContainer';
import { Horizontal } from 'atoms/layout/flex';
import { BodyS } from 'atoms/typography';
import { TERMS_AND_CONDITIONS } from 'constants/__generated__/routes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { CurrentUserProvider_currentUser } from 'contexts/currentUser/__generated__/queries.graphql';
import Warning from 'contexts/intl/Warning';

import { useSetLocale } from '../LanguageSwitcher/useSetLocale';
import { MyClubLink } from '../MyClubLink';

const messages = defineMessages({
  beAware: {
    id: 'UserFlags.beAware',
    defaultMessage:
      'Please be aware that continued violations of our <terms>Terms & Conditions</terms> will result in a suspension of your account.',
  },
  liftOn: {
    id: 'UserFlags.liftOn',
    defaultMessage: 'You will be on probabation until {until}.',
  },
  relatedUsers: {
    id: 'UserFlags.relatedUsers',
    defaultMessage: 'Related users: {users}.',
  },
  multiAccount: {
    id: 'UserFlags.multiAccount',
    defaultMessage: "We've noticed multi-accounting behaviors on your account.",
  },
  abusiveLanguage: {
    id: 'UserFlags.abusiveLanguage',
    defaultMessage:
      "We've noticed recent use of abusive language in your communications on Sorare.",
  },
  anticompetitiveLending: {
    id: 'UserFlags.anticompetitiveLending',
    defaultMessage:
      "We've noticed anticompetitive lending behaviors involing your account.",
  },
  emailHardBounce: {
    id: 'UserFlags.emailHardBounce',
    defaultMessage:
      "We've noticed that your email address was invalid, please update it.",
  },
  referralAbuse: {
    id: 'UserFlags.referralAbuse',
    defaultMessage:
      "We've noticed referral abuse behaviors involing your account.",
  },
  referralSpam: {
    id: 'UserFlags.referralSpam',
    defaultMessage:
      "We've noticed referral abuse behaviors involing your account.",
  },
  unfairGallery: {
    id: 'UserFlags.unfairGallery',
    defaultMessage: "We've noticed unfair gallery behaviors on your account.",
  },
});

const StyledWidthContainer = styled(FullWidthContainer)`
  padding-top: var(--double-unit);
`;

const reasons: Record<UserFlagReason, MessageDescriptor> = {
  [UserFlagReason.MULTI_ACCOUNT]: messages.multiAccount,
  [UserFlagReason.ABUSIVE_LANGUAGE]: messages.abusiveLanguage,
  [UserFlagReason.ANTICOMPETITIVE_LENDING]: messages.anticompetitiveLending,
  [UserFlagReason.EMAIL_HARD_BOUNCE]: messages.emailHardBounce,
  [UserFlagReason.REFERRAL_ABUSE]: messages.referralAbuse,
  [UserFlagReason.REFERRAL_SPAM]: messages.referralSpam,
  [UserFlagReason.UNFAIR_GALLERY]: messages.unfairGallery,
};

const UserFlag = ({
  flag,
}: {
  flag: CurrentUserProvider_currentUser['warningFlags'][number];
}) => {
  const { locale } = useSetLocale();

  return (
    <>
      <FormattedMessage {...reasons[flag.reason]} />{' '}
      {flag.reason === UserFlagReason.MULTI_ACCOUNT &&
        flag.relatedUsers.length > 0 && (
          <FormattedMessage
            {...messages.relatedUsers}
            values={{
              users: (
                <FormattedList
                  type="conjunction"
                  value={flag.relatedUsers.map(user => (
                    <MyClubLink key={user.slug} user={user}>
                      {user.nickname}
                    </MyClubLink>
                  ))}
                />
              ),
            }}
          />
        )}{' '}
      {flag.until && (
        <FormattedMessage
          {...messages.liftOn}
          values={{
            until: flag.until.toLocaleDateString(locale, {
              day: 'numeric',
              month: 'short',
              year:
                flag.until.getFullYear() === new Date().getFullYear()
                  ? undefined
                  : 'numeric',
            }),
          }}
        />
      )}
    </>
  );
};

export const UserFlags = () => {
  const { currentUser } = useCurrentUserContext();

  if (!currentUser || currentUser.warningFlags.length === 0) return null;

  return (
    <StyledWidthContainer>
      <Warning variant="red">
        <Horizontal>
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <BodyS>
            {currentUser.warningFlags.map(flag => (
              <Fragment key={flag.id}>
                <UserFlag flag={flag} />{' '}
              </Fragment>
            ))}{' '}
            <FormattedMessage
              {...messages.beAware}
              values={{
                terms: (content: ReactNode[]) => (
                  <Link to={TERMS_AND_CONDITIONS}>{content}</Link>
                ),
              }}
            />
          </BodyS>
        </Horizontal>
      </Warning>
    </StyledWidthContainer>
  );
};
