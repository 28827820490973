import styled from 'styled-components';

import { Timer } from '@sorare/core/src/atoms/icons/Timer';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { BodyS } from '@sorare/core/src/atoms/typography';
import TimeLeft from '@sorare/core/src/contexts/ticker/TimeLeft';

const Root = styled(Horizontal).attrs({ gap: 0.5 })`
  padding: var(--half-unit) var(--unit);
  border-radius: var(--unit);
  backdrop-filter: blur(2px);
  white-space: nowrap;
  color: var(--c-yellow-600);
  background: rgba(240, 201, 86, 0.16);
`;

type Props = { endDate: Date };

export const SeasonTimer = ({ endDate }: Props) => {
  return (
    <Root className="opened">
      <Timer size={2} />
      <BodyS bold color="var(--c-neutral-1000)">
        <TimeLeft time={endDate} />
      </BodyS>
    </Root>
  );
};
