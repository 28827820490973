/* eslint-disable sorare/use-horizontal-vertical-layout */
import styled from 'styled-components';

import { unitMapping } from 'lib/style';

export const Grid = styled.div<{
  gap?: keyof typeof unitMapping;
  min?: string;
  max?: string;
}>`
  display: grid;
  width: 100%;
  max-width: 100%;
  align-items: start;
  gap: ${({ gap = 1 }) => unitMapping[gap]};
  justify-content: center;
  grid-template-columns: ${({ min = '150px', max = '1fr' }) =>
    `repeat(auto-fit, minmax(${min}, ${max}))`};
`;

export const GridOverflowX = styled(Grid)`
  overflow-x: scroll;

  /* Work around Safari diagonal scrolling*/
  grid-auto-rows: minmax(min-content, max-content);
  grid-auto-rows: auto;
`;
