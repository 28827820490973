import { TypedDocumentNode, gql } from '@apollo/client';
import qs from 'qs';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { keys } from 'components/PersistsQueryStringParameters/storage';
import useQuery from 'hooks/graphql/useQuery';
import { local as localStorage } from 'lib/storage';

import {
  OwnYourGameQuery,
  OwnYourGameQueryVariables,
} from './__generated__/useReferrer.graphql';

const OWN_YOUR_GAME_QUERY = gql`
  query OwnYourGameQuery($name: String!) {
    referrer(name: $name)
  }
` as TypedDocumentNode<OwnYourGameQuery, OwnYourGameQueryVariables>;

export default (paramReferrer?: string) => {
  const location = useLocation();
  const navigate = useNavigate();

  const removeReferrer = useCallback(() => {
    localStorage.removeItem(keys.referrer);
    const queryParams = qs.parse(location.search.slice(1));
    if (queryParams.referrer) {
      delete queryParams.referrer;
      navigate({ search: qs.stringify(queryParams) }, { replace: true });
    }
  }, [location.search, navigate]);

  const referrer = paramReferrer || localStorage.getItem(keys.referrer);

  const { data, loading } = useQuery(OWN_YOUR_GAME_QUERY, {
    variables: { name: referrer! },
    skip: !referrer,
  });

  useEffect(() => {
    if (!loading && data?.referrer)
      localStorage.setItem(keys.referrer, data.referrer);
  }, [loading, data?.referrer]);

  return useMemo(
    () => ({
      referrer,
      invalidReferrer: !loading && referrer && !data?.referrer,
      removeReferrer,
    }),
    [data?.referrer, loading, referrer, removeReferrer]
  );
};
