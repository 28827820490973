import { SVGProps } from 'react';

export const LineupCount = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="12"
    viewBox="0 0 13 12"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.09998 2.4002C3.60292 2.4002 3.19998 2.80314 3.19998 3.3002C3.19998 3.79725 3.60292 4.2002 4.09998 4.2002C4.59703 4.2002 4.99998 3.79725 4.99998 3.3002C4.99998 2.80314 4.59703 2.4002 4.09998 2.4002ZM1.99998 3.3002C1.99998 2.1404 2.94018 1.2002 4.09998 1.2002C5.25977 1.2002 6.19998 2.1404 6.19998 3.3002C6.19998 4.45999 5.25977 5.4002 4.09998 5.4002C2.94018 5.4002 1.99998 4.45999 1.99998 3.3002ZM8.89998 2.4002C8.40292 2.4002 7.99998 2.80314 7.99998 3.3002C7.99998 3.79725 8.40292 4.2002 8.89998 4.2002C9.39703 4.2002 9.79998 3.79725 9.79998 3.3002C9.79998 2.80314 9.39703 2.4002 8.89998 2.4002ZM6.79998 3.3002C6.79998 2.1404 7.74018 1.2002 8.89998 1.2002C10.0598 1.2002 11 2.1404 11 3.3002C11 4.45999 10.0598 5.4002 8.89998 5.4002C7.74018 5.4002 6.79998 4.45999 6.79998 3.3002ZM8.00082 6.84051C8.26539 6.68766 8.57247 6.6002 8.89998 6.6002C9.89409 6.6002 10.7 7.40608 10.7 8.4002V10.8002H11.9V8.4002C11.9 6.74334 10.5568 5.4002 8.89998 5.4002C8.35345 5.4002 7.84107 5.54634 7.39976 5.80167C7.64679 6.11492 7.85023 6.46426 8.00082 6.84051ZM7.09999 8.39374C7.09857 7.72102 6.87577 7.09992 6.50004 6.59981C6.50002 6.59984 6.5 6.59987 6.49998 6.59991C5.95264 5.8714 5.08134 5.4002 4.09998 5.4002C2.44312 5.4002 1.09998 6.74334 1.09998 8.4002V10.8002H2.29998V8.4002C2.29998 7.40608 3.10586 6.6002 4.09998 6.6002C5.09409 6.6002 5.89998 7.40608 5.89998 8.4002V10.8002H7.09998V8.4002C7.09998 8.39804 7.09998 8.39589 7.09999 8.39374Z"
      fill="currentColor"
    />
  </svg>
);
