import { TypedDocumentNode, gql } from '@apollo/client';
import { faCardsBlank } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { MarketplacePromotionalEvent } from '@sorare/core/src/__generated__/globalTypes';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import {
  LabelS,
  Text14,
  Text16,
  Title3,
  Title4,
} from '@sorare/core/src/atoms/typography';
import { FOOTBALL_BUNDLED_AUCTIONS_ID } from '@sorare/core/src/constants/__generated__/routes';
import idFromObject from '@sorare/core/src/gql/idFromObject';
import useAmountWithConversion, {
  Props as UseAmountWithConversionProps,
} from '@sorare/core/src/hooks/useAmountWithConversion';
import { glossary } from '@sorare/core/src/lib/glossary';
import {
  MonetaryAmountCurrency,
  monetaryAmountFragment,
} from '@sorare/core/src/lib/monetaryAmount';

import { ItemSpecialRewardBadge } from 'components/ItemPreview/ItemSpecialRewardBadge';
import { AuctionReminder } from 'components/auction/AuctionReminder';
import useBestBidBelongsToUser from 'hooks/auctions/useBestBidBelongsToUser';

import AuctionTimeLeft from '../AuctionTimeLeft';
import { BidInfos_auction } from './__generated__/index.graphql';

type Props = {
  auction: BidInfos_auction;
  promotionalEvent?: MarketplacePromotionalEvent;
  withReminder?: boolean;
  showBundleContext?: boolean;
};

const Wrapper = styled(Vertical).attrs({ gap: 2 })``;

const Row = styled.div`
  display: flex;
  gap: var(--quadruple-unit);
`;

const Line = styled.div`
  display: flex;
  flex-shrink: 0;
  gap: var(--unit);
  align-items: baseline;
`;

const StyledText14 = styled(Text14)`
  display: flex;
  gap: var(--half-unit);
  flex-wrap: wrap;
`;

const AmountWithConversion = ({
  bigger = false,
  ...props
}: UseAmountWithConversionProps & { bigger?: boolean }) => {
  const { main, exponent } = useAmountWithConversion(props);
  if (bigger) {
    return (
      <Line>
        <Title3 color="var(--c-neutral-1000)">{main}</Title3>
        <Text16 color="var(--c-neutral-600)">{exponent}</Text16>
      </Line>
    );
  }
  return (
    <Line>
      <Title4 color="var(--c-neutral-1000)">{main}</Title4>
      <Text14 color="var(--c-neutral-600)">{exponent}</Text14>
    </Line>
  );
};

export const BidInfosTitle = ({ auction }: Pick<Props, 'auction'>) => {
  const bestBidBelongsToUser = useBestBidBelongsToUser();

  const { open, bestBid, myLastBid, cancelled } = auction;

  if (!open || !bestBid || cancelled || !myLastBid) return null;

  if (bestBidBelongsToUser(bestBid))
    return (
      <Title4 color="var(--c-neutral-1000)">
        <FormattedMessage
          id="BestBid.highestBidder"
          defaultMessage="You are the highest bidder"
        />
      </Title4>
    );
  return (
    <Title4 color="var(--c-red-600)">
      <FormattedMessage
        id="BestBid.outbid"
        defaultMessage="You’ve been outbid"
      />
    </Title4>
  );
};

const TimelineRow = styled(Line)`
  justify-content: space-between;
  align-items: center;
`;

const AuctionTimelineInformation = ({ auction }: Props) => {
  const { bidsCount, cancelled, endDate } = auction;
  return (
    <StyledText14 as="div" color="var(--c-neutral-600)">
      {cancelled ? (
        <FormattedMessage {...glossary.canceled} />
      ) : (
        <FormattedMessage
          id="BidInfos.timeleft"
          defaultMessage="{bidsCount, plural, =0 {# bid • } one { # bid • } other {# bids • }}{timeleft}"
          values={{
            bidsCount,
            timeleft: <AuctionTimeLeft endDate={endDate} forceInlineLayout />,
          }}
        />
      )}
    </StyledText14>
  );
};

const UnderlineLink = styled(Link)`
  text-decoration: underline;
`;

const BidInfos = ({
  auction,
  promotionalEvent,
  withReminder,
  showBundleContext,
}: Props) => {
  const { autoBid, bestBid, currentPrice, currency, myBestBid, myLastBid } =
    auction;

  const bestBidRefCurrency = bestBid?.amounts.referenceCurrency;
  const bestBidAmount =
    bestBidRefCurrency &&
    bestBid?.amounts[
      bestBidRefCurrency.toLowerCase() as MonetaryAmountCurrency
    ];

  const myBestBidRefCurrency = myBestBid?.maximumAmounts.referenceCurrency;
  const myBestBidAmount =
    myBestBidRefCurrency &&
    myBestBid?.maximumAmounts[
      myBestBidRefCurrency.toLowerCase() as MonetaryAmountCurrency
    ];

  const bestBidIsMaxBid = myBestBidAmount === bestBidAmount;

  return (
    <Wrapper>
      {auction.cards.length > 1 && showBundleContext && (
        <Horizontal>
          <FontAwesomeIcon size="1x" icon={faCardsBlank} />
          <LabelS>
            <FormattedMessage
              id="BidInfos.soldInBundle"
              defaultMessage="Sold in a <link>bundle</link>"
              values={{
                link: children => (
                  <UnderlineLink
                    to={generatePath(FOOTBALL_BUNDLED_AUCTIONS_ID, {
                      id: idFromObject(auction.id),
                    })}
                  >
                    {children}
                  </UnderlineLink>
                ),
              }}
            />
          </LabelS>
        </Horizontal>
      )}
      <BidInfosTitle auction={auction} />
      <Row>
        <Vertical>
          {myLastBid && (
            <Text16 color="var(--c-neutral-600)">
              <FormattedMessage
                id="BidInfos.currentBid"
                defaultMessage="Current bid"
              />
            </Text16>
          )}
          {currentPrice && (
            <AmountWithConversion
              monetaryAmount={{
                referenceCurrency: currency,
                [currency.toLowerCase()]: currentPrice,
              }}
              bigger={!myLastBid}
            />
          )}
        </Vertical>
        {autoBid &&
          myBestBid?.maximumAmounts &&
          myBestBidRefCurrency &&
          !bestBidIsMaxBid && (
            <Vertical>
              <Text16 color="var(--c-neutral-600)">
                <FormattedMessage
                  id="BidInfos.maxBid"
                  defaultMessage="Your max bid"
                />
              </Text16>
              <AmountWithConversion
                monetaryAmount={{
                  referenceCurrency: myBestBidRefCurrency,
                  [myBestBidRefCurrency.toLowerCase()]: myBestBidAmount,
                }}
              />
            </Vertical>
          )}
      </Row>
      {promotionalEvent && <ItemSpecialRewardBadge event={promotionalEvent} />}
      <TimelineRow>
        <AuctionTimelineInformation auction={auction} />
        {withReminder && <AuctionReminder auction={auction} />}
      </TimelineRow>
    </Wrapper>
  );
};

BidInfos.fragments = {
  auction: gql`
    fragment BidInfos_auction on TokenAuction {
      id
      bidsCount
      open
      endDate
      cancelled
      autoBid
      currentPrice
      currency
      privateCurrentPrice
      cards: anyCards {
        slug
        sport
      }
      bestBid {
        id
        amounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
        ...UseBestBidBelongsToUser_bestBid
      }
      myBestBid {
        id
        maximumAmounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
      }
      myLastBid {
        id
      }
      ...AuctionReminder_auction
    }
    ${monetaryAmountFragment}
    ${useBestBidBelongsToUser.fragments.bestBid}
    ${AuctionReminder.fragments.auction}
  ` as TypedDocumentNode<BidInfos_auction>,
};

export default BidInfos;
