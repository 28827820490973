// eslint-disable @graphql-eslint/no-unused-fragments

import { TypedDocumentNode, gql } from '@apollo/client';

import {
  FixtureState,
  IRLPrize,
  IRLPrizeType,
  LeaderboardRarity,
  PaymentCurrency,
  Sport,
} from '@sorare/core/src/__generated__/globalTypes';
import { Avatar_user } from '@sorare/core/src/components/user/Avatar/__generated__/index.graphql';
import { partition, uniqueBy } from '@sorare/core/src/lib/arrays';
import { withFragments } from '@sorare/core/src/lib/gql';
import { RewardExperience, TotalRewards } from '@sorare/core/src/lib/rewards';
import { LobbyRarity, rarityName } from '@sorare/core/src/lib/scarcity';

import {
  findIRLPrizeForRank_LeaderboardPrizePool,
  formatCardRewards_LeaderboardPrizePool,
  formatFiatRewards_LeaderboardInterface,
  getExperienceRewards_LeaderboardPrizePool,
  getLeaderboardScarcity_LeaderboardInterface,
} from './__generated__/sports.graphql';

export const getLeaderboardScarcity = withFragments(
  (leaderboard: getLeaderboardScarcity_LeaderboardInterface): LobbyRarity => {
    const { leaderboardRarity } = leaderboard;

    switch (leaderboardRarity) {
      case LeaderboardRarity.LIMITED:
        return 'limited';
      case LeaderboardRarity.RARE:
        return 'rare';
      case LeaderboardRarity.SUPER_RARE:
        return 'super_rare';
      case LeaderboardRarity.UNIQUE:
        return 'unique';
      case LeaderboardRarity.MIXED:
        return 'mix';
      default:
        return 'common';
    }
  },
  {
    LeaderboardInterface: gql`
      fragment getLeaderboardScarcity_LeaderboardInterface on LeaderboardInterface {
        slug
        leaderboardRarity
      }
    ` as TypedDocumentNode<getLeaderboardScarcity_LeaderboardInterface>,
  }
);

export const getLeaderboardScarcityFromSlug = (
  slug?: string
): LobbyRarity | undefined => {
  if (!slug) return undefined;
  return (Object.keys(rarityName) as LobbyRarity[]).find(s => {
    let scarcity: string = s;
    if (s === 'super_rare') scarcity = 'super-rare';
    if (s === 'rare_pro') scarcity = 'rare-pro';
    return slug.includes(scarcity);
  });
};

export const RANKED_SCARCITY = {
  common: 1,
  training: 2,
  mix: 3,
  limited: 4,
  rare: 5,
  rare_pro: 6,
  super_rare: 7,
  unique: 8,
  custom_series: 9,
};

export const isFixtureStarted = (fixture: {
  fixtureState: FixtureState;
}): boolean =>
  [FixtureState.started, FixtureState.closed].includes(fixture.fixtureState);

export const isFixtureClosed = (fixture: {
  fixtureState: FixtureState;
}): boolean => fixture.fixtureState === FixtureState.closed;

export type UsSport = Exclude<Sport, typeof Sport.FOOTBALL>;

export const formatCardRewards = withFragments(
  (
    prizePool: formatCardRewards_LeaderboardPrizePool
  ): Record<string, number | string> => {
    const cardRewards: Record<string, number | string> = {};
    if (prizePool.uniqueCardsCount) {
      cardRewards.unique = prizePool.uniqueCardsCount;
    }
    if (prizePool.superRareCardsCount) {
      cardRewards.super_rare = prizePool.superRareCardsCount;
    }
    if (prizePool.rareCardsCount) {
      cardRewards.rare = prizePool.rareCardsCount;
    }
    if (prizePool.limitedCardsCount) {
      cardRewards.limited = prizePool.limitedCardsCount;
    }
    if (prizePool.commonCardsMaxThreshold) {
      cardRewards.common = `${Math.floor(prizePool.commonCardsMaxThreshold)}%`;
    } else if (prizePool.commonCardsCount) {
      cardRewards.common = prizePool.commonCardsCount;
    }
    return cardRewards;
  },
  {
    LeaderboardPrizePool: gql`
      fragment formatCardRewards_LeaderboardPrizePool on LeaderboardPrizePool {
        uniqueCardsCount
        superRareCardsCount
        rareCardsCount
        limitedCardsCount
        commonCardsMaxThreshold
        commonCardsCount
      }
    ` as TypedDocumentNode<formatCardRewards_LeaderboardPrizePool>,
  }
);

type GiftCard = {
  name: string;
  monetarySymbol?: string;
  minValue: number;
  maxValue: number;
  count: number;
};

const initializeGiftCard = (): Omit<GiftCard, 'name'> => ({
  minValue: Number.MAX_SAFE_INTEGER,
  maxValue: 0,
  count: 0,
});

const parseGiftCardExperiences = (irlPrizes: IRLPrize[]) => {
  const giftCardByName = irlPrizes.reduce<Record<string, GiftCard>>(
    (acc, { headlineText }) => {
      // Match the format `<currency-symbol><monetary-value> <gift-name>`
      const matches = headlineText.match(/^(.)(\d+) (.*)$/i);
      const [, monetarySymbol, value, name] = matches || [];
      if (name && monetarySymbol && value && !Number.isNaN(Number(value))) {
        // Sum the monetary values of all gift cards w/ the same name
        if (!acc[name]) {
          acc[name] = { ...initializeGiftCard(), name, monetarySymbol };
        }
        const val = Number(value);
        if (val < acc[name].minValue) acc[name].minValue = val;
        if (val > acc[name].maxValue) acc[name].maxValue = val;
        acc[name].count += 1;
      } else {
        // Template not matched; include as-is
        acc[headlineText] = { ...initializeGiftCard(), name: headlineText };
      }
      return acc;
    },
    {}
  );

  return Object.keys(giftCardByName).map((key): RewardExperience => {
    const { name, count, ...rest } = giftCardByName[key];

    let description = '';
    if (count === 0) {
      description = name;
    } else if (count === 1) {
      const { maxValue, monetarySymbol } = rest;
      description = `${monetarySymbol}${maxValue} ${name}`;
    } else {
      const { minValue, maxValue, monetarySymbol } = rest;
      description = `${count} ${name}s, ${monetarySymbol}${minValue}–${monetarySymbol}${maxValue}`;
    }
    return { type: IRLPrizeType.GIFT_CARD, count, description };
  });
};

export const getExperienceRewards = withFragments(
  ({
    podium,
    participation,
  }: getExperienceRewards_LeaderboardPrizePool): RewardExperience[] => {
    const irlPrizes = [...podium, ...participation]
      .map(({ irlPrize }) => irlPrize)
      .filter(Boolean);

    const [giftCards, otherIRLPrizes] = partition(
      irlPrizes,
      ({ type }) => type === IRLPrizeType.GIFT_CARD
    );

    const giftCardExperiences = parseGiftCardExperiences(giftCards);

    const otherExperiences = uniqueBy(
      ({ type, description }) => type + description,
      otherIRLPrizes.map(({ type, headlineText }) => ({
        type,
        description: headlineText,
        count: 1,
      }))
    );

    return [...giftCardExperiences, ...otherExperiences];
  },
  {
    LeaderboardPrizePool: gql`
      fragment getExperienceRewards_LeaderboardPrizePool on LeaderboardPrizePool {
        podium {
          irlPrize {
            type
            headlineText
          }
        }
        participation {
          irlPrize {
            type
            headlineText
          }
        }
      }
    ` as TypedDocumentNode<getExperienceRewards_LeaderboardPrizePool>,
  }
);

export const formatFiatRewards = withFragments(
  (
    leaderboard: Omit<
      formatFiatRewards_LeaderboardInterface,
      '__typename' | 'prizepool.__typename'
    >
  ): Pick<
    TotalRewards,
    'prizePool' | 'prizePoolCurrency' | 'conversionCreditReward' | 'experiences'
  > => {
    const { prizePool } = leaderboard;
    const { amountUSDCents, amountUSDCentsConversionCredit } = prizePool;
    return {
      prizePool: (amountUSDCents || 0) / 100,
      prizePoolCurrency: PaymentCurrency.USD,
      conversionCreditReward: (amountUSDCentsConversionCredit || 0) / 100,
      experiences: getExperienceRewards(prizePool),
    };
  },
  {
    LeaderboardInterface: gql`
      fragment formatFiatRewards_LeaderboardInterface on LeaderboardInterface {
        slug
        prizePool {
          amountUSDCents
          amountUSDCentsConversionCredit
          podium {
            irlPrize {
              type
              headlineText
            }
          }
          participation {
            irlPrize {
              type
              headlineText
            }
          }
          ...getExperienceRewards_LeaderboardPrizePool
        }
      }
      ${getExperienceRewards.fragments.LeaderboardPrizePool}
    ` as TypedDocumentNode<formatFiatRewards_LeaderboardInterface>,
  }
);

export const findIRLPrizeForRank = withFragments(
  (prizePool: findIRLPrizeForRank_LeaderboardPrizePool, rank: number) => {
    const rankIndex = rank - 1;
    const { podium, participation } = prizePool;
    if (podium[rankIndex]) {
      const { irlPrize } = podium[rankIndex];
      return irlPrize;
    }
    const level = participation
      .filter(({ from, to }) => !from.isPercent && !to.isPercent)
      .find(({ from, to }) => rank >= from.rank && rank <= to.rank);
    if (level) {
      const { irlPrize } = level;
      return irlPrize;
    }
    return null;
  },
  {
    LeaderboardPrizePool: gql`
      fragment findIRLPrizeForRank_LeaderboardPrizePool on LeaderboardPrizePool {
        podium {
          irlPrize {
            type
            headlineText
          }
        }
        participation {
          from {
            rank
            isPercent
          }
          to {
            rank
            isPercent
          }
          irlPrize {
            type
            headlineText
          }
        }
      }
    ` as TypedDocumentNode<findIRLPrizeForRank_LeaderboardPrizePool>,
  }
);

export const calculateScoreWithBonus = (score: number, bonus: number) => {
  return {
    score: score > 0 ? score * (1 + bonus) : score,
    bonus: score > 0 ? score * bonus : 0,
  };
};

export const convertToAvatarUser = (user: {
  slug: string;
  nickname: string;
  suspended?: boolean;
  avatarUrl?: string | null;
}) => {
  const { slug, nickname, suspended = false, avatarUrl = null } = user;
  return { slug, nickname, suspended, pictureUrl: avatarUrl } as Avatar_user;
};

export const LEADERBOARDS_WITH_SPECIAL_EDITION_CARD_REWARDS = [
  // staging
  'nba-2023-gameweek-49-common-xmas-day',
  'nba-2023-gameweek-49-limited-xmas-day',
  'nba-2023-gameweek-49-rare-xmas-day',
  'nba-2023-gameweek-49-super-rare-xmas-day',
  'nba-2023-gameweek-49-unique-xmas-day',
  // prod
  'nba-2023-gameweek-18-common-xmas-day',
  'nba-2023-gameweek-18-limited-xmas-day',
  'nba-2023-gameweek-18-rare-xmas-day',
  'nba-2023-gameweek-18-super-rare-xmas-day',
  'nba-2023-gameweek-18-unique-xmas-day',
];
