import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { Vertical } from 'atoms/layout/flex';
import { LabelM } from 'atoms/typography';
import Bold from 'atoms/typography/Bold';
import { DumbNotification } from 'components/activity/DumbNotification';
import { FOOTBALL_PLAY_INVENTORY_BOXES_TYPE } from 'constants/__generated__/routes';
import { isType } from 'gql';

import { commonNotificationInterfaceFragment } from '../fragments';
import { CommonNotificationProps } from '../types';
import { DecisivePurchaseNotification_decisivePurchaseNotification } from './__generated__/index.graphql';

type Props = CommonNotificationProps & {
  notification: DecisivePurchaseNotification_decisivePurchaseNotification;
};

export const DecisivePurchaseNotification = ({
  notification,
  ...rest
}: Props) => {
  const { createdAt, sport, read, decisiveCard, reward } = notification;
  const {
    anyPlayer: { displayName },
  } = decisiveCard;

  if (!isType(reward, 'ProbabilisticBundleReward')) return null;

  const {
    probabilisticBundle: {
      config: { name, rarity },
    },
  } = reward;
  return (
    <DumbNotification
      title={
        <Vertical gap={0.5}>
          <LabelM as="span" bold>
            <FormattedMessage
              id="DecisivePurchaseNotification.title"
              defaultMessage="Decisive reward 🎁"
            />
          </LabelM>
          <LabelM as="span">
            <FormattedMessage
              id="DecisivePurchaseNotification.subtitle"
              defaultMessage="{playerDisplayName} has come up big!{br}Claim your <b>{rewardName}</b>."
              values={{
                b: Bold,
                br: <br />,
                playerDisplayName: displayName,
                rewardName: name,
              }}
            />
          </LabelM>
        </Vertical>
      }
      link={generatePath(FOOTBALL_PLAY_INVENTORY_BOXES_TYPE, {
        type: rarity,
      })}
      cardPicture={decisiveCard}
      createdAt={createdAt}
      sport={sport}
      read={read}
      {...rest}
    />
  );
};

DecisivePurchaseNotification.fragments = {
  decisivePurchaseNotification: gql`
    fragment DecisivePurchaseNotification_decisivePurchaseNotification on DecisivePurchaseNotification {
      id
      createdAt
      decisiveCard: card {
        slug
        anyPlayer {
          slug
          displayName
        }
        ...DumbNotification_cardPicture
      }
      reward {
        id
        ... on ProbabilisticBundleReward {
          id
          probabilisticBundle {
            id
            config {
              id
              rarity
              name
            }
          }
        }
      }
      ...Notification_notificationInterface
    }
    ${commonNotificationInterfaceFragment}
    ${DumbNotification.fragments.cardPicture}
  ` as TypedDocumentNode<DecisivePurchaseNotification_decisivePurchaseNotification>,
};
