import { defineMessage, useIntl } from 'react-intl';

import { Sport } from '__generated__/globalTypes';
import { DumbContent } from 'components/landing/NewOtherSports/DumbContent';
import { sportsLabelsMessages } from 'lib/glossary';

import baseballBg from './assets/baseballBg.jpg';

const message = defineMessage({
  id: 'Landing.NewOtherSports.mlb',
  defaultMessage: 'Featuring all 30 MLB officially licensed clubs',
});

export const baseballCards = [
  'https://assets.sorare.com/card/367c4f32-bb1a-4e3e-b020-5aafe2bb82c4/picture/tinified-57a7de2979ff30b36c842a685f77cfa0.png',
  'https://assets.sorare.com/card/e5b7dbc4-e96b-4ba9-a1dc-18cf5beecc3e/picture/tinified-eeed47600c7d30318d0c3674b90d5fe2.png',
  'https://assets.sorare.com/card/668fd36d-0844-4743-9cc3-e35a8431bb24/picture/tinified-8f121de169acd7016c703a722c203556.png',
  'https://assets.sorare.com/card/2ff69019-baf2-4723-b00a-88560ff40a7d/picture/tinified-8b71018c165e56411f9dc8b9e3fe5cfe.png',
  'https://assets.sorare.com/card/3c5b3024-ee35-4572-a196-f5a56bfd492d/picture/tinified-1b86d359a50fc1abe3b125aa815477d1.png',
];

export const BaseballContent = () => {
  const { formatMessage } = useIntl();

  return (
    <DumbContent
      bgImage={baseballBg}
      sport={Sport.BASEBALL}
      cardsUrls={baseballCards}
      text={formatMessage(message)}
      title={formatMessage(sportsLabelsMessages[Sport.BASEBALL])}
    />
  );
};
