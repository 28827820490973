import { Navigate, generatePath } from 'react-router-dom';

import { useTypedParams } from '@sorare/routing';

import { So5LeaderboardSeasonality } from '@sorare/core/src/__generated__/globalTypes';
import { NBA_S3_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY } from '@sorare/core/src/constants/__generated__/routes';

import { PageParams } from './__generated__/routeParams';

const FixtureEnterPage = () => {
  const { fixture, eventType } = useTypedParams<PageParams>();

  return (
    <Navigate
      replace
      to={generatePath(NBA_S3_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY, {
        fixture,
        seasonality: So5LeaderboardSeasonality.IN_SEASON.toLowerCase(),
        eventType,
      })}
    />
  );
};
export default FixtureEnterPage;
