import fiat from 'assets/icons/fiat.png';
import { unitMapping } from 'lib/style';

export const Fiat = ({
  size = 2,
  className,
}: {
  size?: keyof typeof unitMapping;
  className?: string;
}) => (
  <img
    className={className}
    style={{ width: unitMapping[size], height: unitMapping[size] }}
    src={fiat}
    alt="Fiat"
  />
);
