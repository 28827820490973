import { generatePath } from 'react-router-dom';

import { Navigate, useTypedParams } from '@sorare/routing';

import { NBA_S3_PLAY_EVENTTYPE_FIXTURE } from '@sorare/core/src/constants/__generated__/routes';

import { PageParams } from './__generated__/routeParams';

const PlayProPage = () => {
  const { eventType } = useTypedParams<PageParams>();
  const fixture = 'ongoing';
  return (
    <Navigate
      to={generatePath(NBA_S3_PLAY_EVENTTYPE_FIXTURE, {
        fixture,
        eventType,
      })}
      replace
    />
  );
};

export default PlayProPage;
