import { TypedDocumentNode, gql } from '@apollo/client';
import classnames from 'classnames';
import styled from 'styled-components';

import ResponsiveImg, { ValidWidths } from 'atoms/ui/ResponsiveImg';
import { CARD_ASPECT_RATIO_SMALL } from 'lib/cards';

import { CardAvatar_anyCard } from './__generated__/index.graphql';

type Props = {
  card: CardAvatar_anyCard;
  className?: string;
  maxWidth?: ValidWidths;
};

const CardWrapper = styled.div`
  max-width: 250px;
  width: 100%;
`;

const CardAvatarImage = styled(ResponsiveImg)`
  width: 100%;
  aspect-ratio: ${CARD_ASPECT_RATIO_SMALL};
`;

export const smallCardAvatarWidth = 80;

export const CardAvatar = ({ card, className = '', maxWidth = 320 }: Props) => {
  return (
    <CardWrapper
      title={card.anyPlayer.displayName}
      className={classnames(className)}
    >
      <CardAvatarImage
        cropWidth={maxWidth}
        src={card.avatarUrl!}
        alt={card.anyPlayer.displayName}
      />
    </CardWrapper>
  );
};

CardAvatar.fragments = {
  anyCard: gql`
    fragment CardAvatar_anyCard on AnyCardInterface {
      slug
      avatarUrl: pictureUrl(derivative: "squared")
      anyPlayer {
        slug
        displayName
      }
    }
  ` as TypedDocumentNode<CardAvatar_anyCard>,
};
