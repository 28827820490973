import { type ComponentProps, ElementType, forwardRef } from 'react';
import {
  Link as RRDLink,
  NavLink as RRDNavLink,
  Navigate as RRDNavigate,
  To,
  generatePath,
  resolvePath,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { PrefetchLink, usePrefetch } from './prefetch';

export { getRoutesFromFiles, toRoutePath } from './autoroute';
export { DefaultPrefetchProvider } from './prefetch';

const concatPath = (to: string, searchParams: string) => {
  const containsSearchParams = to.includes('?');
  if (!searchParams) {
    return to;
  }
  return `${to}${containsSearchParams ? '&' : '?'}${searchParams}`;
};

type NavigateProps = ComponentProps<typeof RRDNavigate> & {
  keepParams?: boolean;
  keepSearchParams?: boolean;
};

export const Navigate = ({
  keepParams,
  keepSearchParams,
  to,
  ...otherProps
}: NavigateProps) => {
  const [searchParams] = useSearchParams();
  const params = useParams();

  const getTo = () => {
    if (typeof to !== 'string') {
      return to;
    }
    const toWithParams = keepParams ? generatePath(to, params) : to;

    const toWithSearchParams = keepSearchParams
      ? concatPath(toWithParams, searchParams.toString())
      : toWithParams;
    return toWithSearchParams;
  };

  return <RRDNavigate {...otherProps} to={getTo()} />;
};

type LinkProps<P extends ElementType> = Omit<
  ComponentProps<P>,
  'unstable_viewTransition'
> & {
  viewTransition?: boolean;
  keepSearchParams?: boolean;
  prefetch?: Parameters<typeof usePrefetch>[1];
};

const useResolvedTo = (to: To, keepSearchParams?: boolean) => {
  const [searchParams] = useSearchParams();
  if (typeof to !== 'string') {
    return to;
  }
  const toWithSearchParams = keepSearchParams
    ? `${to}?${searchParams.toString()}`
    : to;
  return toWithSearchParams;
};

// There is some repetition here between Link and NavLink, but remix hasn't found a way around that either.
export const Link = forwardRef<HTMLAnchorElement, LinkProps<typeof RRDLink>>(
  function Link(
    { to, keepSearchParams, viewTransition, prefetch, ...rest },
    ref
  ) {
    const resolvedTo = useResolvedTo(to, keepSearchParams);
    const [mergedRef, shouldPrefetch] = usePrefetch<HTMLAnchorElement>(
      ref,
      prefetch
    );

    return (
      <>
        <RRDLink
          ref={mergedRef}
          // WARNING to=".." behaves differently than to={{pathname: '..'}}
          to={resolvedTo}
          unstable_viewTransition={viewTransition}
          {...rest}
        />
        {shouldPrefetch && <PrefetchLink to={resolvedTo} />}
      </>
    );
  }
);

export const NavLink = forwardRef<
  HTMLAnchorElement,
  LinkProps<typeof RRDNavLink>
>(function NavLink(
  { to, keepSearchParams, viewTransition, prefetch, ...rest },
  ref
) {
  const resolvedTo = useResolvedTo(to, keepSearchParams);
  const [mergedRef, shouldPrefetch] = usePrefetch<HTMLAnchorElement>(
    ref,
    prefetch
  );

  return (
    <>
      <RRDNavLink
        ref={mergedRef}
        // WARNING to=".." behaves differently than to={{pathname: '..'}}
        to={resolvedTo}
        unstable_viewTransition={viewTransition}
        {...rest}
      />
      {shouldPrefetch && <PrefetchLink to={resolvedTo} />}
    </>
  );
});

export const NotFound = () => {
  const location = useLocation();

  return (
    <Navigate
      to={resolvePath('..', location.pathname).pathname}
      keepSearchParams
    />
  );
};

export const useTypedParams = <T extends Record<string, string>>() => {
  return useParams() as T;
};

type CatchAll<T extends string> = T extends `${string}/` ? `${T}*` : `${T}/*`;

export function catchAll<const T extends string>(path: T): CatchAll<T> {
  return `${path.replace(/\/$/, '')}/*` as any;
}
