import { TypedDocumentNode, gql } from '@apollo/client';
import { generatePath } from 'react-router-dom';

import { Vertical } from 'atoms/layout/flex';
import { LabelM } from 'atoms/typography';
import { DumbNotification } from 'components/activity/DumbNotification';
import { FOOTBALL_PLAY_RIVALS_MY_LINEUPS_SEASONSLUG_GAMESLUG_ARENA } from 'constants/__generated__/routes';

import { CommonNotificationProps } from '../types';
import { FootballRivalsGameNotification_footballRivalsGameNotification } from './__generated__/index.graphql';

const linkFromStatus = (
  notification: FootballRivalsGameNotification_footballRivalsGameNotification
) => {
  const { game } = notification;

  return generatePath(
    FOOTBALL_PLAY_RIVALS_MY_LINEUPS_SEASONSLUG_GAMESLUG_ARENA,
    {
      gameSlug: game.slug,
      seasonSlug: game.season?.slug || '',
    }
  );
};

type Props = CommonNotificationProps & {
  notification: FootballRivalsGameNotification_footballRivalsGameNotification;
};
export const FootballRivalsGameNotification = ({
  notification,
  ...rest
}: Props) => {
  const { createdAt, sport, read, title, body } = notification;

  const link = linkFromStatus(notification);

  return (
    <DumbNotification
      title={
        <Vertical gap={0}>
          <LabelM>{title}</LabelM>
          <LabelM>{body}</LabelM>
        </Vertical>
      }
      link={link}
      createdAt={createdAt}
      sport={sport}
      read={read}
      {...rest}
    />
  );
};

FootballRivalsGameNotification.fragments = {
  footballRivalsGameNotification: gql`
    fragment FootballRivalsGameNotification_footballRivalsGameNotification on FootballRivalsGameNotification {
      id
      name
      createdAt
      read
      sport
      title
      body
      game {
        slug
        season {
          id
          slug
        }
      }
      user {
        slug
        ...DumbNotification_userAvatar
      }
    }
  ` as TypedDocumentNode<FootballRivalsGameNotification_footballRivalsGameNotification>,
};
