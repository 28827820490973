import { defineMessage, useIntl } from 'react-intl';

import { Sport } from '__generated__/globalTypes';
import { DumbContent } from 'components/landing/NewOtherSports/DumbContent';
import { sportsLabelsMessages } from 'lib/glossary';

import mitchell from './assets/mitchell.jpg';

const message = defineMessage({
  id: 'Landing.NewOtherSports.nba',
  defaultMessage: 'Featuring all 30 NBA officially licensed teams',
});

export const nbaCards = [
  'https://assets.sorare.com/card/1bc682b5-5252-4ee4-97c9-4981c8f6a5bf/picture/tinified-a4325d37a7f3e22a4830991123a1cadf.png',
  'https://assets.sorare.com/card/e25c1354-f9f4-4eba-954f-48fca15074fb/picture/tinified-5cd2f604bcb7e10731c205f42fa5e541.png',
  'https://assets.sorare.com/card/e3cfbc82-1a60-4f46-b9d9-f765dcce20a7/picture/tinified-3957987519ae8196cd4e65497e098d1d.png',
  'https://assets.sorare.com/card/9a65c3b6-7310-4406-8e01-103aa6dc12c6/picture/tinified-d6bf20ff0ec7d961809109d2d7b9d255.png',
  'https://assets.sorare.com/card/afa918c4-c1e7-4312-8278-d9359a0ea8d7/picture/tinified-c92375575533548e7602811f75ddf802.png',
];

export const NBAContent = () => {
  const { formatMessage } = useIntl();

  return (
    <DumbContent
      bgImage={mitchell}
      sport={Sport.NBA}
      cardsUrls={nbaCards}
      text={formatMessage(message)}
      title={formatMessage(sportsLabelsMessages[Sport.NBA])}
    />
  );
};
