import classNames from 'classnames';
import {
  FC,
  FormEventHandler,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { useDebounce, useKey } from 'react-use';
import styled from 'styled-components';

import { Drawer } from 'atoms/layout/Drawer';
import { BodyM } from 'atoms/typography';
import { Popover } from 'atoms/ui/Popover';
import { useIsLaptop } from 'hooks/device/useIsLaptop';
import useTouchScreen from 'hooks/device/useTouchScreen';
import { theme } from 'style/theme';

const Content = styled.div`
  box-shadow: var(--shadow-200);
  border-radius: var(--intermediate-unit) var(--intermediate-unit) 0 0;
  max-height: calc(
    var(--radix-popover-content-available-height, 100vh) - var(--unit)
  );
  overflow: auto;
  &:not(.transparent) {
    background-color: var(--c-neutral-200);
  }
`;
const StyledForm = styled.form`
  margin: 0;
`;
const StyledPopoverContent = styled(Popover.Content)`
  z-index: ${theme.zIndex.tooltip};
  border-radius: var(--intermediate-unit);
  &.fullWidth {
    width: var(--radix-popover-trigger-width);
  }
`;

export const THRESHOLD = 10;

const useOpenState = ({
  open,
  onClose,
  onOpen,
}: Pick<Props, 'open' | 'onClose' | 'onOpen'>) => {
  const [openState, setOpenState] = useState(false);
  const isControlledMode = typeof open === 'boolean';
  if (isControlledMode) {
    return [
      open,
      (v: boolean) => {
        if (v) {
          onOpen?.();
        } else {
          onClose?.();
        }
      },
    ] as [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  }
  return [openState, setOpenState] as const;
};

export const DropdownOptionLabel = styled(BodyM).attrs({ as: 'label' })`
  display: flex;
  padding: var(--unit) var(--quadruple-unit) var(--unit) var(--double-unit);
  color: var(--c-neutral-600);
  cursor: pointer;
  &:focus,
  &:focus-within {
    background: var(--c-neutral-300);
  }
  &:hover,
  &.selected {
    background: var(--c-neutral-400);
  }
`;

export type Props = {
  children:
    | ReactNode
    | FC<React.PropsWithChildren<{ closeDropdown: () => void }>>;
  label: (props: {
    onMouseEnter: MouseEventHandler<HTMLElement> | undefined;
    onMouseLeave: MouseEventHandler<HTMLElement> | undefined;
    onClick: MouseEventHandler<HTMLElement> | undefined;
  }) => ReactNode;
  gap?: 0 | 4 | 8 | 16;
  horizontalGap?: -16 | -8 | -4 | 0 | 4 | 8 | 16;
  onChange?: FormEventHandler<HTMLFormElement>;
  triggerOnHover?: boolean;
  closeOnChange?: boolean;
  open?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  fullWidth?: boolean;
  transparent?: boolean;
  align?: 'left' | 'right';
  collisionPadding?: 0 | 4 | 8 | 16;
  forceDesktopBehavior?: boolean;
};

export const Dropdown = ({
  open: openProp,
  children,
  label,
  gap = 0,
  horizontalGap = 0,
  onChange,
  triggerOnHover = false,
  closeOnChange = false,
  onClose = () => {},
  onOpen = () => {},
  fullWidth,
  align = 'left',
  collisionPadding,
  transparent,
  forceDesktopBehavior,
}: Props) => {
  const [open, setOpen] = useOpenState({ open: openProp, onClose });
  const [mouseEntered, setMouseEntered] = useState(false);
  const isTouchScreen = useTouchScreen();
  const isLaptopAndAbove = useIsLaptop();

  const triggerOnHoverEnabled =
    !isTouchScreen && isLaptopAndAbove && triggerOnHover;

  const closeDropdown = useCallback(() => {
    onClose();
    setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  const openDropdown = useCallback(() => {
    onOpen();
    setOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOpen]);

  useKey('Escape', () => open && closeDropdown(), undefined, [open]);

  useDebounce(
    () => {
      if (triggerOnHoverEnabled && !mouseEntered) {
        closeDropdown();
      }
    },
    100,
    [mouseEntered]
  );

  const mouseLeave = () => {
    if (triggerOnHoverEnabled) {
      setMouseEntered(false);
    }
  };

  const mouseEnter = () => {
    if (triggerOnHoverEnabled) {
      setMouseEntered(true);
      openDropdown();
    }
  };

  const button = label({
    onClick: e => {
      if (triggerOnHoverEnabled) {
        e.preventDefault();
      } else if (open) {
        closeDropdown();
      } else {
        openDropdown();
      }
    },
    onMouseEnter: mouseEnter,
    onMouseLeave: mouseLeave,
  });

  const content = (
    <Content className={classNames({ transparent })}>
      <StyledForm
        onChange={e => {
          if (onChange) {
            onChange(e);
          }
          if (closeOnChange) {
            closeDropdown();
          }
        }}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
      >
        {typeof children === 'function'
          ? children({ closeDropdown })
          : children}
      </StyledForm>
    </Content>
  );

  if (forceDesktopBehavior || isLaptopAndAbove) {
    return (
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger asChild>{button}</Popover.Trigger>
        <Popover.Portal>
          <StyledPopoverContent
            sideOffset={gap}
            align={
              align
                ? ({ left: 'start', right: 'end' } as const)[align]
                : 'start'
            }
            alignOffset={horizontalGap}
            className={classNames({ fullWidth })}
            asChild
            collisionPadding={collisionPadding}
          >
            {content}
          </StyledPopoverContent>
        </Popover.Portal>
      </Popover.Root>
    );
  }

  return (
    <>
      {button}
      <Drawer open={open} onBackdropClick={closeDropdown} side="bottom">
        {content}
      </Drawer>
    </>
  );
};
