import classnames from 'classnames';
import {
  ButtonHTMLAttributes,
  ComponentType,
  ReactNode,
  forwardRef,
} from 'react';
import { To } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Link } from '@sorare/routing';

import useClickHandler from 'hooks/useClickHandler';

export const colors = [
  'primary',
  'secondary',
  'tertiary',
  'quaternary',
  'gray',
  'green',
  'red',
  'opa',
  'transparent',
] as const;

export type Color = (typeof colors)[number];

export interface Props
  extends Omit<
    ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>,
    'type'
  > {
  size: 'compact' | 'small' | 'medium' | 'large';
  stroke?: boolean;
  color?: Color;
  to?: To | number;
  href?: string;
  replace?: boolean;
  fullWidth?: boolean;
  disableDebounce?: boolean;
  externalLink?: boolean;
  state?: { [key: string]: any };
  className?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  type?: 'submit';
  asSpan?: boolean;
}

const Label = styled.span``;

const style = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--unit);
  height: 48px;
  padding: 0px 20px;
  box-shadow: none;
  color: var(--c-neutral-100);
  border: none;
  background-color: var(--variantColor);
  border-radius: 2em !important;
  & span,
  & {
    letter-spacing: 0;
    white-space: nowrap;
    font: var(--t-label-l);
    font-weight: var(--t-bold);
  }
  transition: background-color 0.2s ease-in-out;

  &.fullWidth {
    width: 100%;
  }
  &:hover {
    text-decoration: none;
    box-shadow: none;
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))
      var(--variantColor);
  }
  &:focus {
    color: var(--c-neutral-100);
  }
  &:disabled {
    color: var(--c-neutral-100);
    opacity: 0.5;
    &:hover {
      background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))
        var(--variantColor);
    }
  }
  &.stroke {
    color: var(--variantColor);
    background: none;
    border: 1px solid var(--variantColor);
    &:hover {
      background: var(--variantColor);
    }
  }
  &.compact {
    padding: 0 var(--unit);
    height: var(--triple-unit);
    font: var(--t-label-m);
    font-weight: var(--t-bold);
  }
  &.small {
    padding: 0 var(--double-unit);
    height: var(--quadruple-unit);
  }
  &.medium {
    height: 40px;
    min-width: 40px;
    /* max padding before going higher than 40 if there is only 1char */
    padding: 0px 12px;
  }
  &.primary {
    --variantColor: var(--c-brand-600);

    &:not(.stroke) {
      color: var(--c-static-neutral-100);
    }
    &:hover {
      color: var(--c-neutral-1000);
    }
  }
  &.gray {
    --variantColor: var(--c-neutral-200);
    color: var(--c-neutral-800);
  }
  &.quaternary {
    --variantColor: var(--c-neutral-400);
    color: var(--c-neutral-800);
  }
  &.secondary {
    --variantColor: var(--c-neutral-1000);
    &:hover {
      background: rgba(var(--c-rgb-neutral-1000), 0.85);
      color: var(--c-neutral-100);
    }
    &.stroke {
      &:hover {
        color: var(--c-neutral-100);
      }
    }
  }
  &.green {
    --variantColor: var(--c-green-600);
    &:hover {
      color: var(--c-neutral-1000);
    }
  }
  &.tertiary {
    --variantColor: var(--c-neutral-100);
    color: var(--c-neutral-1000);
    background: var(--c-neutral-200);
    border: 2px solid var(--c-neutral-400);

    &:disabled {
      background: var(--c-neutral-300);
      color: var(--c-neutral-600);
      border: unset;
    }
    &.active,
    &:hover,
    &:focus {
      background: var(--c-neutral-300);
    }
  }
  &.red {
    --variantColor: var(--c-red-600);
    &:not(.stroke) {
      color: var(--c-static-neutral-100);
    }
    &:hover {
      color: var(--c-neutral-1000);
    }
  }
  &.opa {
    --variantColor: var(--c-neutral-150);
    color: var(--c-neutral-1000);
    background: rgba(var(--c-rgb-neutral-1000), 0);
    &:hover {
      background: rgba(var(--c-rgb-neutral-1000), 0.07);
    }
  }
  &.transparent {
    color: inherit;
    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }
`;

type Wrapper = ComponentType<
  Omit<ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>, 'type'>
>;

const StyledButton: Wrapper = styled.button`
  ${style}
`;

const StyledLink = styled(Link)`
  ${style}
`;

const StyledA: Wrapper = styled.a`
  ${style}
`;

const StyledSpan: Wrapper = styled.span`
  ${style}
`;

const getWrapper = (to?: To | number, href?: string, asSpan?: boolean) => {
  if (to) {
    return StyledLink;
  }
  if (href) {
    return StyledA;
  }
  if (asSpan) {
    return StyledSpan;
  }
  return StyledButton;
};

const wrappedChildren = (
  children: ReactNode,
  startIcon?: ReactNode,
  endIcon?: ReactNode
) => {
  if (typeof children === 'string' && (startIcon || endIcon)) {
    return <Label>{children}</Label>;
  }
  return children;
};

export const Button = forwardRef<HTMLElement, Props>(
  (
    {
      children,
      size,
      color = 'primary',
      onClick,
      to,
      href,
      disableDebounce = false,
      stroke = false,
      externalLink = false,
      className,
      startIcon,
      endIcon,
      type = 'button',
      fullWidth,
      asSpan,
      role = href ? 'link' : 'button',
      ...rest
    },
    ref
  ) => {
    const clickHandler = useClickHandler(onClick, disableDebounce);

    const Wrapper = getWrapper(to, href);

    return (
      <Wrapper
        ref={ref}
        onClick={clickHandler}
        {...(externalLink
          ? ({ target: '_blank', rel: 'noopener noreferrer' } as any)
          : {})}
        to={to}
        href={href}
        type={type}
        role={role}
        {...{ as: asSpan ? 'span' : undefined }}
        {...rest}
        className={classnames(color, className, size, {
          stroke,
          fullWidth,
        })}
      >
        {startIcon}
        {wrappedChildren(children, startIcon, endIcon)}
        {endIcon}
      </Wrapper>
    );
  }
);

Button.displayName = 'Button';

export default Button;
