import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import { Link } from '@sorare/routing';

import { SportOrShared } from '__generated__/globalTypes';
import { FontAwesomeIcon, IconDefinition } from 'atoms/icons';
import { SorareLogo } from 'atoms/icons/SorareLogo';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { Caption, LabelM } from 'atoms/typography';
import Card from 'components/card/Card';
import Avatar from 'components/user/Avatar';
import { useIntlContext } from 'contexts/intl';
import { sportsLabelsMessages } from 'lib/glossary';

import {
  DumbNotification_cardPicture,
  DumbNotification_userAvatar,
} from './__generated__/index.graphql';

type BaseProps = {
  createdAt: Date;
  sport: SportOrShared | null;
  title: string | ReactNode;
  cardPicture?: DumbNotification_cardPicture | null;
  link?: string;
  read?: boolean;
  onClick?: () => void;
  inModale?: boolean;
};

type DefaultIconProps = {
  userAvatar?: never;
  avatarUrl?: never;
  icon?: never;
};

type UserAvatarProps = {
  userAvatar: DumbNotification_userAvatar | null | undefined;
  avatarUrl?: never;
  icon?: never;
};

type AvatarUrlProps = {
  userAvatar?: never;
  avatarUrl: string;
  icon?: never;
};

type FontawesomeIconProps = {
  userAvatar?: never;
  avatarUrl?: never;
  icon: IconDefinition;
};

type IconProps =
  | UserAvatarProps
  | AvatarUrlProps
  | FontawesomeIconProps
  | DefaultIconProps;

type Props = BaseProps & IconProps;

const Root = styled.span`
  width: 100%;
  display: flex;
  text-align: left;
  gap: var(--double-unit);
  padding: var(--double-unit) var(--double-unit);
  border-bottom: 1px solid var(--c-neutral-200);
  color: var(--c-neutral-1000);
  cursor: pointer;

  &.read {
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }

  &.inModale {
    border-color: var(--c-neutral-300);
    &:hover {
      background-color: rgba(var(--c-rgb-neutral-400), 0.6);
    }
  }

  &:hover {
    background-color: var(--c-neutral-300);
    color: var(--c-neutral-1000);
    opacity: 1;
  }
`;

const AvatarContainer = styled(Horizontal).attrs({ gap: 0, center: true })`
  width: calc(5 * var(--unit));
  height: calc(5 * var(--unit));
  background-color: var(--c-brand-800);
  border-radius: var(--unit);
`;

const avatarStyle = css`
  width: calc(4 * var(--unit));
`;
const StyledAvatar = styled.img`
  ${avatarStyle}
`;
const StyledSorareLogo = styled(SorareLogo)`
  ${avatarStyle}
`;
const Infos = styled(Vertical).attrs({ gap: 2 })`
  flex: 1;
`;
const ImgContainer = styled.div`
  width: calc(5 * var(--unit));
`;

const NotificationAvatar = ({ userAvatar, avatarUrl, icon }: IconProps) => {
  if (userAvatar) {
    return (
      <div>
        <Avatar variant="medium" user={userAvatar} />
      </div>
    );
  }
  if (avatarUrl) {
    return <StyledAvatar src={avatarUrl} alt="" />;
  }
  if (icon) {
    return (
      <AvatarContainer>
        <FontAwesomeIcon icon={icon} />
      </AvatarContainer>
    );
  }
  return (
    <AvatarContainer>
      <StyledSorareLogo />
    </AvatarContainer>
  );
};

export const DumbNotification = ({
  createdAt,
  title,
  cardPicture,
  link,
  sport,
  read,
  onClick,
  inModale,
  ...avatarProps
}: Props) => {
  const { formatDistanceToNow } = useIntlContext();
  return (
    <Root
      as={link ? Link : 'div'}
      role={link ? 'link' : undefined}
      {...(link ? { to: link } : {})}
      onClick={onClick}
      className={classNames({ inModale, read })}
    >
      {cardPicture ? (
        <ImgContainer>
          <Card card={cardPicture} />
        </ImgContainer>
      ) : (
        <NotificationAvatar {...avatarProps} />
      )}
      <Infos>
        <div>
          <LabelM as="div">{title}</LabelM>
          <Caption color="var(--c-neutral-600)">
            {sport ? (
              <FormattedMessage {...sportsLabelsMessages[sport]} />
            ) : (
              <FormattedMessage
                id="Notification.global"
                defaultMessage="Global"
              />
            )}
            {' • '}
            {formatDistanceToNow(createdAt)}
          </Caption>
        </div>
      </Infos>
    </Root>
  );
};

DumbNotification.fragments = {
  avatarUser: gql`
    fragment DumbNotification_userAvatar on PublicUserInfoInterface {
      slug
      ...Avatar_publicUserInfoInterface
    }
    ${Avatar.fragments.publicUserInfoInterface}
  ` as TypedDocumentNode<DumbNotification_userAvatar>,
  cardPicture: gql`
    fragment DumbNotification_cardPicture on AnyCardInterface {
      slug
      ...Card_anyCard
    }
    ${Card.fragments.anyCard}
  ` as TypedDocumentNode<DumbNotification_cardPicture>,
};
