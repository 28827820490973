import { Outlet } from 'react-router-dom';

import { NotFound, getRoutesFromFiles } from '@sorare/routing';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import SportProvider from '@sorare/core/src/contexts/sport/Provider';

import CurrentBaseballUserProvider from 'contexts/currentBaseballUser/Provider';
import { MLBMarketSubscriptions } from 'routing/MLBMarketSubscriptions';

export const routeObjects = [
  {
    element: (
      <SportProvider sport={Sport.BASEBALL}>
        <CurrentBaseballUserProvider>
          <MLBMarketSubscriptions />
          <Outlet />
        </CurrentBaseballUserProvider>
      </SportProvider>
    ),
    children: [
      ...getRoutesFromFiles(
        import.meta.glob('./**/(page|layout).tsx'),
        '../../packages/baseball/src/pages'
      ),
      { path: '*', element: <NotFound /> },
    ],
  },
];
