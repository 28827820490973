import { DialogProps, Dialog as MUIDialog } from '@material-ui/core';
import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Vertical } from 'atoms/layout/flex';
import useScreenSize from 'hooks/device/useScreenSize';
import { setCurrentBackgroundColor } from 'lib/currentBackgroundColor';
import { breakpoints, laptopAndAbove } from 'style/mediaQuery';
import { OverrideClasses } from 'style/utils';

import CloseButton from './CloseButton';
import Header from './Header';
import useIsScrolling from './useIsScrolling';

const Root = styled(Vertical).attrs({ gap: 0 })`
  height: 100%;
  min-height: 0;
  flex: 1;
`;
const BodyWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;
const Footer = styled.footer`
  margin-top: auto;
`;

const [StyledDialog, classes] = OverrideClasses(MUIDialog, null, {
  root: css`
    margin-top: 0;
  `,
  paper: css`
    ${setCurrentBackgroundColor('var(--c-dialog-background)')}
  `,
  fullHeight: css`
    overflow-y: auto;
    @media ${laptopAndAbove} {
      min-height: calc(100vh - 80px);
    }
  `,
  fullScreen: css`
    ${setCurrentBackgroundColor('var(--c-neutral-100)')}
  `,
  transparent: css`
    ${setCurrentBackgroundColor('transparent')}
    box-shadow: none;
  `,
  paperWidthXl: css`
    width: ${breakpoints.desktop}px;
    @media ${laptopAndAbove} {
      display: inline-flex;
      flex-direction: column;
      border-radius: 20px;
      margin: 20px auto;
    }
  `,
});

export type Props = Pick<
  DialogProps,
  | 'maxWidth'
  | 'keepMounted'
  | 'disablePortal'
  | 'disableEnforceFocus'
  | 'disableEscapeKeyDown'
  | 'transitionDuration'
  | 'style'
> & {
  className?: string;
  children?:
    | FC<React.PropsWithChildren<{ CloseButton: typeof CloseButton }>>
    | ReactNode;
  title?: ReactNode;
  hideHeader?: boolean;
  footer?: ReactNode;
  open?: boolean;
  onBack?: () => void;
  onClose?: () => void;
  scroll?: 'body' | 'paper';
  fullWidth?: boolean;
  fullHeight?: boolean;
  transparent?: boolean;
  fullScreen?: boolean;
  disableBackdropClick?: boolean;
};

type CloseParams = Parameters<NonNullable<DialogProps['onClose']>>;
export const Dialog = ({
  children,
  title,
  hideHeader,
  footer,
  open,
  onBack,
  onClose,
  scroll = 'body',
  fullWidth,
  fullHeight,
  transparent,
  maxWidth = 'xl',
  fullScreen,
  disableBackdropClick,
  className,
  disableEnforceFocus = true,
  ...rest
}: Props) => {
  const { isScrolling, clickDown, clickUp } = useIsScrolling();
  const { up: isLaptop } = useScreenSize('laptop');

  const closeDialog = (_?: CloseParams[0], reason?: CloseParams[1]) => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return;
    }
    if (!isScrolling) {
      onClose?.();
    } else {
      clickUp();
    }
  };

  const showHeader = !hideHeader && (onBack || title || onClose);
  const content = (
    <Root className={className}>
      {showHeader && (
        <Header
          onBack={onBack}
          title={title}
          onClose={onClose ? closeDialog : undefined}
        />
      )}
      <BodyWrapper>
        {typeof children === 'function'
          ? children?.({ CloseButton })
          : children}
      </BodyWrapper>
      <Footer>{footer}</Footer>
    </Root>
  );

  return (
    <StyledDialog
      {...rest}
      classes={{
        root: classes.root,
        paperWidthXl: classes.paperWidthXl,
        paper: classNames(classes.paper, {
          [classes.fullHeight]: fullHeight || maxWidth === 'xl',
          [classes.fullScreen]: fullScreen,
          [classes.transparent]: transparent,
        }),
      }}
      scroll={scroll}
      fullScreen={fullScreen ?? !isLaptop}
      maxWidth={!fullScreen && maxWidth}
      fullWidth={fullWidth}
      open={!!open}
      onClose={closeDialog}
      disableEnforceFocus={disableEnforceFocus}
      // Since React Portal maintain event propagation, we need to manually stop them in the DOM in case this Dialog is used inside an Element with mouse events
      // There is no scenario where we want to keep the event propagation in a Dialog
      // https://github.com/facebook/react/issues/11387
      // https://react.dev/reference/react-dom/createPortal#:~:text=more%20examples%20below.-,A%20portal%20only%20changes%20the%20physical%20placement%20of%20the%20DOM%20node,-.%20In%20every%20other
      onMouseDown={e => {
        e.stopPropagation();
        clickDown(e);
      }}
      onMouseUp={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
    >
      {content}
    </StyledDialog>
  );
};

export default Dialog;
