import qs from 'qs';
import { useCallback, useEffect, useRef } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { catchAll } from '@sorare/routing';

import { SEARCH_PARAMS } from 'components/search/InstantSearch/types';
import {
  FOOTBALL_BUNDLED_AUCTIONS_ID,
  FOOTBALL_CARDS_SLUG,
  FOOTBALL_COMPOSE_TEAM_LEADERBOARDSLUG,
  FOOTBALL_MARKET,
  FOOTBALL_MARKET_MANAGER_SALES,
  FOOTBALL_MARKET_MANAGER_SALES_PLAYERSLUG_RARITY,
  FOOTBALL_MARKET_NEW_SIGNINGS,
  FOOTBALL_MY_CLUB_SLUG,
  FOOTBALL_MY_CLUB_SLUG_CARDS,
  FOOTBALL_MY_CLUB_SLUG_COLLECTIONS_COLLECTION,
  FOOTBALL_MY_CLUB_SLUG_HISTORY,
  FOOTBALL_PLAY_PRO,
  LANDING,
  MLB,
  MLB_CARDS_SLUG,
  MLB_COMPOSE_TEAM_LEADERBOARDSLUG,
  MLB_DRAFT,
  MLB_MARKET,
  MLB_MARKET_PRIMARY,
  MLB_MARKET_SECONDARY,
  MLB_MY_CLUB,
  MLB_MY_CLUB_SLUG_CARDS,
  MLB_MY_CLUB_SLUG_HISTORY,
  MLB_PLAYERS_SLUG,
  MLB_PLAY_PRO,
  MLB_PLAY_PRO_FIXTURE_COMPETITIONS_LEADERBOARD,
  NBA_MY_CLUB_SLUG,
  NBA_MY_CLUB_SLUG_CARDS,
  NBA_MY_CLUB_SLUG_HISTORY,
  NBA_PLAYERS_SLUG,
} from 'constants/__generated__/routes';

import {
  FOOTBALL_CLUB_SHOW_WILDCARD,
  FOOTBALL_COUNTRY_SHOW,
  FOOTBALL_HOME,
  FOOTBALL_LEAGUE_SHOW_WILDCARD,
  FOOTBALL_PLAYER_SHOW_WILDCARD,
  MY_SORARE_NEW,
  MY_SORARE_OFFERS_RECEIVED,
  MY_SORARE_WILDCARD,
  NBA_CARD_SHOW,
  NBA_COMPOSE_TEAM_WILDCARD,
  NBA_HOME,
  NBA_LOBBY_WILDCARD,
  NBA_MARKET,
  NBA_ONBOARDING,
  NBA_PRIMARY_MARKET,
  NBA_SECONDARY_MARKET,
  TOKEN_AUCTION,
} from '../constants/routes';

export type InteractionContext =
  | 'competition_details'
  | 'landing'
  | 'landing_nba'
  | 'landing_epl'
  | 'homepage'
  | 'my_sorare'
  | 'my_sorare_new'
  | 'my_sorare_offers_received'
  | 'my_club'
  | 'my_club_cards'
  | 'my_club_history'
  | 'user_gallery_collections'
  | 'primary_market'
  | 'secondary_market'
  | 'featured_market'
  | 'player_page'
  | 'card_page'
  | 'bundled_auction_page'
  | 'club_page'
  | 'country_page'
  | 'league_page'
  | 'onboarding'
  | 'compose_team'
  | 'lobby'
  | 'private_league_details'
  | 'club_honors'
  | 'club_shop'
  | string;

const getPathname = ({
  pathname,
  search,
}: {
  pathname: string;
  search: string;
}): InteractionContext => {
  if (matchPath(LANDING, pathname)) {
    return 'landing';
  }
  if (
    matchPath(FOOTBALL_HOME, pathname) ||
    matchPath(NBA_HOME, pathname) ||
    matchPath(MLB, pathname)
  ) {
    return 'homepage';
  }
  if (matchPath(MY_SORARE_NEW, pathname)) {
    return 'my_sorare_new';
  }
  if (matchPath(MY_SORARE_OFFERS_RECEIVED, pathname)) {
    return 'my_sorare_offers_received';
  }
  if (matchPath(MY_SORARE_WILDCARD, pathname)) {
    return 'my_sorare';
  }
  if (
    matchPath(catchAll(FOOTBALL_MY_CLUB_SLUG_COLLECTIONS_COLLECTION), pathname)
  ) {
    return 'user_gallery_collections';
  }
  if (
    matchPath(FOOTBALL_MARKET, pathname) ||
    matchPath(NBA_MARKET, pathname) ||
    matchPath(MLB_MARKET, pathname)
  ) {
    return 'market_home';
  }
  if (
    matchPath(FOOTBALL_MARKET_NEW_SIGNINGS, pathname) ||
    matchPath(MLB_MARKET_PRIMARY, pathname) ||
    matchPath(NBA_PRIMARY_MARKET, pathname)
  ) {
    return 'primary_market';
  }
  if (
    matchPath(FOOTBALL_MARKET_MANAGER_SALES, pathname) ||
    matchPath(MLB_MARKET_SECONDARY, pathname) ||
    matchPath(NBA_SECONDARY_MARKET, pathname)
  ) {
    const queryString = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    return queryString[SEARCH_PARAMS.UNSTACKED]
      ? 'secondary_market_unstacked'
      : 'secondary_market';
  }
  if (matchPath(FOOTBALL_MARKET_MANAGER_SALES_PLAYERSLUG_RARITY, pathname)) {
    return 'secondary_market_stack';
  }
  if (
    matchPath(FOOTBALL_PLAYER_SHOW_WILDCARD, pathname) ||
    matchPath(catchAll(MLB_PLAYERS_SLUG), pathname) ||
    matchPath(catchAll(NBA_PLAYERS_SLUG), pathname)
  ) {
    return 'player_page';
  }
  if (
    matchPath(FOOTBALL_CARDS_SLUG, pathname) ||
    matchPath(MLB_CARDS_SLUG, pathname) ||
    matchPath(NBA_CARD_SHOW, pathname)
  ) {
    return 'card_page';
  }
  if (matchPath(FOOTBALL_CLUB_SHOW_WILDCARD, pathname)) {
    return 'club_page';
  }
  if (matchPath(FOOTBALL_COUNTRY_SHOW, pathname)) {
    return 'country_page';
  }
  if (matchPath(FOOTBALL_LEAGUE_SHOW_WILDCARD, pathname)) {
    return 'league_page';
  }
  if (matchPath(MLB_DRAFT, pathname) || matchPath(NBA_ONBOARDING, pathname)) {
    return 'onboarding';
  }
  if (
    matchPath(FOOTBALL_BUNDLED_AUCTIONS_ID, pathname) ||
    matchPath(TOKEN_AUCTION, pathname)
  ) {
    return 'bundled_auction_page';
  }

  if (
    matchPath(FOOTBALL_PLAY_PRO, pathname) ||
    matchPath(MLB_PLAY_PRO, pathname) ||
    matchPath(NBA_LOBBY_WILDCARD, pathname)
  ) {
    return 'lobby';
  }
  if (
    matchPath(catchAll(FOOTBALL_COMPOSE_TEAM_LEADERBOARDSLUG), pathname) ||
    matchPath(catchAll(MLB_COMPOSE_TEAM_LEADERBOARDSLUG), pathname) ||
    matchPath(NBA_COMPOSE_TEAM_WILDCARD, pathname)
  ) {
    return 'compose_team';
  }
  if (matchPath(MLB_PLAY_PRO_FIXTURE_COMPETITIONS_LEADERBOARD, pathname)) {
    return 'competition_details';
  }
  if (matchPath(FOOTBALL_CLUB_SHOW_WILDCARD, pathname)) {
    return 'club_shop';
  }
  if (
    matchPath(catchAll(FOOTBALL_MY_CLUB_SLUG_CARDS), pathname) ||
    matchPath(catchAll(NBA_MY_CLUB_SLUG_CARDS), pathname) ||
    matchPath(catchAll(MLB_MY_CLUB_SLUG_CARDS), pathname)
  ) {
    return 'my_club_cards';
  }
  if (
    matchPath(catchAll(FOOTBALL_MY_CLUB_SLUG_HISTORY), pathname) ||
    matchPath(catchAll(NBA_MY_CLUB_SLUG_HISTORY), pathname) ||
    matchPath(catchAll(MLB_MY_CLUB_SLUG_HISTORY), pathname)
  ) {
    return 'my_club_history';
  }
  if (
    matchPath(catchAll(FOOTBALL_MY_CLUB_SLUG), pathname) ||
    matchPath(catchAll(NBA_MY_CLUB_SLUG), pathname) ||
    matchPath(catchAll(MLB_MY_CLUB), pathname)
  ) {
    return 'my_club';
  }

  return pathname;
};

export const useGetInteractionContext = () => {
  const { pathname, search } = useLocation();
  const loc = useRef({ pathname, search });

  useEffect(() => {
    loc.current = { pathname, search };
  }, [pathname, search]);

  return useCallback((subpath?: string) => {
    return `${getPathname(loc.current)}${subpath ? `_${subpath}` : ''}`;
  }, []);
};
