import { TypedDocumentNode, gql } from '@apollo/client';
import styled from 'styled-components';

import { ValidWidths } from '@sorare/core/src/atoms/ui/ResponsiveImg';
import Card from '@sorare/core/src/components/card/Card';
import OpenItemDialogLink from '@sorare/core/src/components/link/OpenItemDialogLink';

import { useClickCardEvent } from 'hooks/events/useClickCardEvent';

import { FlexCard_anyCard } from './__generated__/index.graphql';

export interface FlexCardProps {
  card: FlexCard_anyCard;
  withLink?: boolean;
  width?: ValidWidths;
  onClick?: () => void;
}

const Root = styled.div`
  width: 100%;
  display: block;
  background-color: transparent;
  position: relative;
`;

export const FlexCard = (props: FlexCardProps) => {
  const { card, withLink, width, onClick } = props;
  const trackClickCard = useClickCardEvent();

  if (withLink)
    return (
      <Root
        as={OpenItemDialogLink}
        item={card}
        onClick={onClick || (() => trackClickCard(card, Boolean(card.user)))}
        sport={card.sport}
      >
        <Card card={card} width={width} />
      </Root>
    );
  return (
    <Root>
      <Card card={card} width={width} />
    </Root>
  );
};

FlexCard.displayName = 'FlexCard';

FlexCard.fragments = {
  anyCard: gql`
    fragment FlexCard_anyCard on AnyCardInterface {
      slug
      sport
      user {
        slug
      }
      rarityTyped
      ...Card_anyCard
      ...useClickCardEvent_anyCard
    }
    ${Card.fragments.anyCard}
    ${useClickCardEvent.fragments.anyCard}
  ` as TypedDocumentNode<FlexCard_anyCard>,
};

export default FlexCard;
