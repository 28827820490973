// eslint-disable-next-line no-restricted-imports
import { format } from 'date-fns';
import qs from 'qs';

import {
  MobileAppBuildVersion,
  MobileAppVersion,
  SESSION_STORAGE,
} from 'hooks/useSessionStorage';
import { session as sessionStorage } from 'lib/storage';
import { randomUUID } from 'lib/uuid';

import config from './config.json';

type Environment = 'staging' | 'development' | 'production' | 'mockprod';

// eslint-disable-next-line no-restricted-properties
const { origin, protocol, host, hostname, search } = window?.location ?? {
  hostname: '',
  search: '',
};

export const ORIGIN = origin;
export const HOST = host;
export const HOSTNAME = hostname;

const q = qs.parse(search, {
  ignoreQueryPrefix: true,
  decoder(value) {
    if (/^(\d+|\d*\.\d+)$/.test(value)) {
      return parseFloat(value);
    }
    const keywords: Record<string, boolean | null | undefined> = {
      true: true,
      false: false,
      null: null,
      undefined,
    };
    if (value in keywords) {
      return keywords[value];
    }
    return value;
  },
});

const forcedEnv: Environment | undefined = q.force_env as Environment;
if (forcedEnv) {
  sessionStorage.setItem(SESSION_STORAGE.forcedEnv, forcedEnv);
}

const mobileApp: MobileAppVersion | undefined = q.mobileApp as MobileAppVersion;
if (mobileApp) {
  sessionStorage.setItem(SESSION_STORAGE.mobileApp, mobileApp as any);
}

const { deeplink } = q;
if (deeplink) {
  sessionStorage.setItem(SESSION_STORAGE.deeplink, JSON.stringify(deeplink));
}

const { debugI18n } = q;
if (debugI18n !== undefined) {
  sessionStorage.setItem(
    SESSION_STORAGE.debugI18n,
    debugI18n ? 'true' : 'false'
  );
}

const appBuildVersionNumber: MobileAppBuildVersion | undefined =
  q.appBuildVersionNumber as MobileAppBuildVersion;
if (appBuildVersionNumber) {
  sessionStorage.setItem(
    SESSION_STORAGE.appBuildVersionNumber,
    appBuildVersionNumber as any
  );
}

const googlePlayStoreCountryCode = q.googlePlayStoreCountryCode as
  | string
  | undefined;
if (googlePlayStoreCountryCode) {
  sessionStorage.setItem(
    SESSION_STORAGE.googlePlayStoreCountryCode,
    JSON.stringify(googlePlayStoreCountryCode)
  );
}

const forcedFlags: Environment | undefined = q.force_flags as Environment;
if (forcedFlags) {
  sessionStorage.setItem(SESSION_STORAGE.forcedFlags, forcedFlags);
}

const forcedFlag = q.force_flag;
if (forcedFlag) {
  sessionStorage.setItem('forcedFlag', JSON.stringify(forcedFlag));
}

const getEnvConfig = () => {
  if (import.meta.env.STORYBOOK === 'true') {
    // Always use local env in storybook
    return {
      ...config.development,
      // Force HTTPS to fix HTTPS enabled storybook in Safari
      sofeHost: `https://${new URL(config.development.sofeHost).host}`,
    };
  }
  if (hostname.match(/sorare.dev/)) {
    return config.staging;
  }
  if (hostname.match(/sorare.tech/)) {
    return config.mockprod;
  }
  if (hostname.match(/sorare.co$/)) {
    return {
      ...config.production,
      backendHost: config.production.backendHost.replace(
        'sorare.com',
        'sorare.co'
      ),
      walletUrl: config.production.walletUrl.replace('sorare.com', 'sorare.co'),
      wsUrl: config.production.wsUrl.replace('sorare.com', 'sorare.co'),
    };
  }
  if (hostname === 'frontend') {
    // this case is used when taking card snapshot in the dev environment
    return {
      ...config.development,
      backendHost: 'http://backend:3000',
    };
  }
  if (hostname.match(/.*\.local/)) {
    return {
      ...config.development,
      backendHost: `${protocol}//${hostname}:3000`,
      walletUrl: `${protocol}//${hostname}:3002`,
    };
  }

  if (hostname.match(/localhost|(192\.168\..*\..*)/)) {
    return {
      ...config.development,
      walletUrl: config.development.walletUrl.replace('http:', protocol),
    };
  }

  return config.production;
};

let envConfig = getEnvConfig();
const sessionForcedEnv: Environment | null = sessionStorage.getItem(
  SESSION_STORAGE.forcedEnv
) as Environment;
const sessionForcedFlags: Environment | null = sessionStorage.getItem(
  SESSION_STORAGE.forcedFlags
) as Environment;
export const ACTUAL_ENV = envConfig.env;

if (sessionForcedEnv && config[sessionForcedEnv]) {
  envConfig = {
    ...config[sessionForcedEnv],
    launchDarklyClientSideID: envConfig.launchDarklyClientSideID,
    walletUrl: envConfig.walletUrl,
  };
}

if (
  ACTUAL_ENV !== 'production' &&
  sessionForcedFlags &&
  config[sessionForcedFlags]
) {
  envConfig = {
    ...envConfig,
    launchDarklyClientSideID:
      config[sessionForcedFlags].launchDarklyClientSideID,
  };
}

export const isForcedEnv = () => {
  return !!sessionStorage.getItem(SESSION_STORAGE.forcedEnv);
};

export const ENV = envConfig.env;
export const isStaging = ENV === 'staging';
export const isMockprod = ENV === 'mockprod';
export const isProduction = ENV === 'production';

export const API_ROOT = envConfig.backendHost;
export const BACKEND_API_PATH = '/backend/graphql';
export const US_SPORTS_API_ROOT =
  envConfig.usSportsBackendHost || envConfig.backendHost;
export const US_SPORTS_API_PATH = '/sports/graphql';
export const SOFE_API_ROOT = envConfig.sofeHost || envConfig.backendHost;
export const SOFE_API_PATH =
  import.meta.env.MODE === 'development' && !isForcedEnv()
    ? '/graphql'
    : '/federation/graphql';
export const WS_ROOT = `${envConfig.wsUrl}/cable`;
export const STRIPE_PUBLIC_KEY = envConfig.stripePublicKey;
export const WALLET_URL = envConfig.walletUrl;
export const ETHERSCAN_URL =
  envConfig.env === 'production'
    ? 'https://etherscan.io'
    : 'https://sepolia.etherscan.io';
export const RISK_SCRIPT_URL = envConfig.riskJsScriptUrl;
export const PORTIS_ID = envConfig.portisId;
export const RAMP_API_KEY = envConfig.rampApiKey;
export const RAMP_URL = envConfig.rampUrl;
export const MOONPAY_URL = envConfig.moonpayUrl;
export const LAUNCH_DARKLY_CLIENT_SIDE_ID = envConfig.launchDarklyClientSideID;
export const SEGMENT_API_KEY = envConfig.segmentApiKey;
export const IMPACT_PAGE_LOAD_API_URL = envConfig.impactPageLoadAPIUrl;
export const WALLET_CONNECT_PROJECT_ID = 'b1e8df3bb981e9917bfeeb0ad4110bb7';
export const MANGOPAY_BASE_URL = envConfig.mangopayBaseUrl;
export const MANGOPAY_CLIENT_ID = envConfig.mangopayClientId;
export const CKO_PUBLIC_KEY = envConfig.ckoPublicKey;
export const NETHONE_PROFILER_URL = envConfig.nethoneProviderUrl;
export const ASSETS_HOST = envConfig.assetsHost;
export const APPLEPAY_MERCHANT_ID = envConfig.applepayMerchantId;

export const CLIENT_TYPE = 'Web';
const { sorareRevision } = document.documentElement.dataset;
export const REVISION = sorareRevision!;

export const IS_PLAYGROUND = process.env.PLAYGROUND === 'true';
// https://docs.prerender.io/docs/33-overview-of-prerender-crawlers
export const IS_PRERENDER =
  navigator.userAgent.includes(
    'Prerender (+https://github.com/prerender/prerender)'
  ) ||
  //https://gitlab.com/sorare/infra/-/merge_requests/2327
  navigator.userAgent === 'Sorare Headless Chromium';
export const IS_TEST_RUNNER =
  import.meta.env.NODE_ENV === 'test' || import.meta.env.STORYBOOK === 'true';

function makeTabVersion() {
  // Generate random characters in case multiple tabs are opened at the exact same time
  const rand = randomUUID().substring(0, 4);
  // data-sorare-fetched-at is injected by the Cloudflare worker
  const fetchedAt = document.documentElement.dataset.sorareFetchedAt;
  const isDateValid = (date: Date) => !Number.isNaN(date.getTime());
  const isFetchedAtValid = fetchedAt && isDateValid(new Date(fetchedAt));
  const date = format(
    isFetchedAtValid ? new Date(fetchedAt) : new Date(),
    'yyyyMMddHHmmss'
  );
  // Append 'z' to differentiate whether the version comes from fetched at
  return `${rand}${date}${isFetchedAtValid ? 'z' : ''}`;
}
export const TAB_VERSION = makeTabVersion();
export const VERSION =
  REVISION === 'development' || import.meta.env.MODE === 'development'
    ? TAB_VERSION
    : Number(document.documentElement.dataset.sorareVersion);

export const SO5_SENTRY_DSN =
  'https://6aa84363323647f78159a04b60f11ffe@o1067581.ingest.sentry.io/6105154';

export const SHARING_ORIGIN = envConfig.origin || ORIGIN;

export const GOOGLE_OAUTH_CLIENT_ID =
  '140311826455-9ov80ptmkpa210h04on3uig3kg9st156.apps.googleusercontent.com';

export const SIGNUP_AGE_LIMIT = '16';
export const DEBUG_I18N =
  sessionStorage.getItem(SESSION_STORAGE.debugI18n) === 'true';
