import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import Button from '@sorare/core/src/atoms/buttons/Button';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { BuyConfirmation } from '@sorare/core/src/components/buyActions/BuyConfirmation';
import { ANY_SPORT_MY_CLUB_CARDS } from '@sorare/core/src/constants/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { glossary } from '@sorare/core/src/lib/glossary';

import { BuyTokenConfirmation_anyCard } from './__generated__/index.graphql';

export type Props = {
  card: BuyTokenConfirmation_anyCard;
  onClose: () => void;
};

export const BuyTokenConfirmation = ({ card, onClose }: Props) => {
  const { generateSportContextPath } = useSportContext();
  const { currentUser } = useCurrentUserContext();
  return (
    <BuyConfirmation
      actions={
        <Vertical>
          <Button onClick={onClose} color="secondary" size="medium">
            <FormattedMessage {...glossary.continue} />
          </Button>
          <Button
            onClick={onClose}
            color="transparent"
            size="medium"
            to={generateSportContextPath(ANY_SPORT_MY_CLUB_CARDS, {
              params: {
                slug: currentUser?.slug,
              },
            })}
          >
            <FormattedMessage {...glossary.viewGallery} />
          </Button>
        </Vertical>
      }
      cards={[card]}
    />
  );
};

BuyTokenConfirmation.fragments = {
  anyCard: gql`
    fragment BuyTokenConfirmation_anyCard on AnyCardInterface {
      slug
      ...BuyConfirmation_anyCard
    }
    ${BuyConfirmation.fragments.anyCard}
  ` as TypedDocumentNode<BuyTokenConfirmation_anyCard>,
};

export default BuyTokenConfirmation;
