import {
  faAddressCard,
  faCreditCardBlank,
} from '@fortawesome/pro-regular-svg-icons';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import { KycDocumentRequirement } from '__generated__/globalTypes';
import { FormControlLabel } from 'atoms/inputs/FormControlLabel';
import { Text16, Title3 } from 'atoms/typography';
import UploadInput from 'components/fiatWallet/CreateFiatWallet/DocumentCheck/UploadInput';
import { StepWrapperFromProps } from 'components/fiatWallet/CreateFiatWallet/StepWrapper';
import { GraphQLResult, GraphqlForm } from 'components/form/Form';
import { FileWithDataURL } from 'components/form/UploadFile/useUploadFile';

import { kycDocumentTypeLabelsWithPossessivePronoun } from '../ChooseDocument/messages';

const messages = defineMessages({
  front: {
    id: 'UploadID.front',
    defaultMessage: 'front',
  },
  back: {
    id: 'UploadID.back',
    defaultMessage: 'back',
  },
});

const StyledGraphqlForm = styled(GraphqlForm)`
  margin-bottom: 0;
  height: 100%;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  > * {
    width: 100%;
  }
`;

export type Props = {
  documentRequirement: KycDocumentRequirement;
  frontPage: FileWithDataURL | undefined;
  backPage: FileWithDataURL | undefined;
  setFrontPage: (file: FileWithDataURL) => void;
  setBackPage: (file: FileWithDataURL) => void;
  onBack: () => void;
  onSuccess: () => void;
  onSubmit: (onResult: (result: GraphQLResult) => void) => void;
};

const UploadFileWrapper = styled.div`
  display: flex;
  gap: var(--double-unit);
  align-items: flex-start;

  > * {
    flex-grow: 1;
    width: 50%;
  }
`;

export const Upload = ({
  documentRequirement,
  frontPage,
  backPage,
  setFrontPage,
  setBackPage,
  onSuccess,
  onSubmit,
  onBack,
}: Props) => {
  const { formatMessage } = useIntl();

  const filesCount = documentRequirement.nbPages;
  const filesUploadedCount = [frontPage, backPage].filter(Boolean).length;

  const formattedDocumentName = formatMessage(
    kycDocumentTypeLabelsWithPossessivePronoun[documentRequirement.documentType]
  );

  return (
    <StyledGraphqlForm
      onSuccess={onSuccess}
      onSubmit={(_, onResult) => {
        onSubmit(onResult);
      }}
      render={(Error, SubmitButton) => (
        <StepWrapperFromProps
          onBack={onBack}
          submitButton={
            <SubmitButton
              size="medium"
              disabled={filesUploadedCount !== filesCount}
            >
              <FormattedMessage
                id="createFiatWallet.uploadID.submit"
                defaultMessage="Submit ID"
              />
            </SubmitButton>
          }
        >
          <Title3>
            <FormattedMessage
              id="createFiatWallet.uploadID.title"
              defaultMessage="Upload {filesCount, plural, one {an image} other {images}} of {documentType}"
              values={{
                documentType: formattedDocumentName,
                filesCount,
              }}
            />
          </Title3>
          <Text16>
            <FormattedMessage
              id="createFiatWallet.uploadID.description"
              defaultMessage="Make sure your photos aren’t blurry and the front of {documentType} clearly shows your face."
              values={{
                documentType: formattedDocumentName,
              }}
            />
          </Text16>
          <Error />
          <UploadFileWrapper>
            <StyledFormControlLabel
              label={null}
              control={
                <UploadInput
                  name="frontPage"
                  documentName={
                    filesCount === 1
                      ? formattedDocumentName
                      : formatMessage(messages.front)
                  }
                  icon={faAddressCard}
                  file={frontPage}
                  setFile={setFrontPage}
                />
              }
              labelPlacement="top"
            />
            {filesCount === 2 && (
              <StyledFormControlLabel
                label={null}
                control={
                  <UploadInput
                    name="backPage"
                    documentName={formatMessage(messages.back)}
                    icon={faCreditCardBlank}
                    file={backPage}
                    setFile={setBackPage}
                  />
                }
                labelPlacement="top"
              />
            )}
          </UploadFileWrapper>
        </StepWrapperFromProps>
      )}
    />
  );
};

export default Upload;
