import {
  ApolloQueryResult,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
} from '@apollo/client';
import { FetchMoreFunction } from '@apollo/client/react/hooks/useSuspenseQuery';
import { useCallback, useRef } from 'react';
import { useDeepCompareEffect } from 'react-use';

import useQuery from 'hooks/graphql/useQuery';

export type LoadMore<D, V> = (
  reload: boolean,
  variables: V,
  forceConnection?: string
) => Promise<D>;

export type PaginatedQueryOptions = (
  | { connection: string; connections?: never }
  | { connections: string[]; connection?: never }
) & {
  prepend?: boolean;
};

type UsePaginatedQueryOptions<
  TData,
  TVariables extends OperationVariables,
> = QueryHookOptions<TData, TVariables> & PaginatedQueryOptions;

function mergeEdges<T extends { edges: any[]; pageInfo: any }>({
  previous,
  current,
  reload,
  prepend,
}: {
  previous: T;
  current: T;
  reload: boolean;
  prepend: boolean;
}) {
  const newEdges = current.edges;
  const { pageInfo } = current;
  let edges = [];

  if (reload) {
    edges = [...newEdges];
  } else if (previous) {
    edges = prepend
      ? [...newEdges, ...previous.edges]
      : [...previous.edges, ...newEdges];
  } else {
    edges = newEdges;
  }
  return { ...previous, ...current, edges, pageInfo };
}

function mergeNodes<T extends { nodes: any[]; pageInfo: any }>({
  previous,
  current,
  reload,
  prepend,
}: {
  previous: T;
  current: T;
  reload: boolean;
  prepend: boolean;
}) {
  const newNodes = current.nodes;
  const { pageInfo } = current;
  let nodes = [];

  if (reload) {
    nodes = [...newNodes];
  } else if (previous) {
    nodes = prepend
      ? [...newNodes, ...previous.nodes]
      : [...previous.nodes, ...newNodes];
  } else {
    nodes = newNodes;
  }
  return { ...previous, ...current, nodes, pageInfo };
}

function mergeResults({
  previous,
  current,
  reload,
  connections,
  prepend,
}: {
  previous: any;
  current: any | undefined;
  reload: boolean;
  connections: string[];
  prepend: boolean;
}): any {
  const result: { [key: string]: any } = {};

  if (previous === null || current === null) {
    return current;
  }
  if (Array.isArray(current) || Array.isArray(previous)) {
    return current;
  }
  if (typeof current === 'object') {
    if ((current as any).edges) {
      if (connections.includes((current as any).__typename)) {
        return mergeEdges({ previous, current, reload, prepend });
      }
      return current;
    }
    if (current.nodes) {
      if (connections.includes(current.__typename)) {
        return mergeNodes({ previous, current, reload, prepend });
      }
      return previous;
    }

    Object.keys(current).forEach(key => {
      result[key] = mergeResults({
        previous: (previous as any)?.[key],
        current: (current as any)[key],
        reload,
        connections,
        prepend,
      });
    });

    return { ...previous, ...result };
  }
  return current;
}

export interface WithRelayPagination<D, V> {
  loadMore: LoadMore<ApolloQueryResult<D>, Partial<V>>;
}

export const useLoadMore = <TData, TVariables extends OperationVariables>({
  fetchMore,
  options,
}: {
  fetchMore: FetchMoreFunction<TData, TVariables>;
  options: Nullable<UsePaginatedQueryOptions<TData, TVariables>>;
}) => {
  const { connection, connections, prepend, ...rest } = options || {};

  // Add a ref to keep track of the latest version
  const versionRef = useRef(0);

  useDeepCompareEffect(() => {
    versionRef.current += 1;
  }, [rest]);

  const loadMore = useCallback(
    async (
      reload: boolean,
      loadMoreVariables: Partial<TVariables>,
      forceConnection?: string
    ) => {
      const currentVersion = versionRef.current;

      return fetchMore({
        variables: loadMoreVariables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          // Check if this is the latest version of the update
          if (currentVersion !== versionRef.current) {
            // Skipping outdated update
            return null;
          }

          return mergeResults({
            previous: previousResult as TData,
            current: fetchMoreResult as TData,
            reload,
            connections: forceConnection
              ? [forceConnection]
              : connections || [connection].filter(Boolean),
            prepend: Boolean(prepend),
          })!;
        },
      });
    },
    [fetchMore, connections, connection, prepend]
  );
  return loadMore;
};

export default function usePaginatedQuery<
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: TypedDocumentNode<TData, TVariables>,
  options: UsePaginatedQueryOptions<TData, TVariables>
): QueryResult<TData, TVariables> & WithRelayPagination<TData, TVariables> {
  const { connection, connections, prepend, ...rest } = options;
  const data = useQuery(query, rest);
  const { fetchMore } = data;

  const loadMore = useLoadMore({
    fetchMore,
    options,
  });

  return {
    ...data,
    loadMore,
  };
}
