import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Title2 } from 'atoms/typography';
import { ContentContainer } from 'components/landing/LandingMultiSport/ui';
import { glossary } from 'lib/glossary';
import {
  desktopAndAbove,
  laptopAndAbove,
  tabletAndAbove,
} from 'style/mediaQuery';

import { BaseballContent } from './BaseballContent';
import { FootballContent } from './FootballContent';
import { NBAContent } from './NBAContent';

const Wrapper = styled(ContentContainer)`
  margin-bottom: calc(var(--unit) * 10);
`;

const Title = styled(Title2)`
  font-family: 'Druk Wide';
  text-transform: uppercase;
  margin-bottom: var(--triple-unit);

  @media ${tabletAndAbove} {
    font-size: 24px;
    margin-bottom: calc(var(--unit) * 7);
  }
  @media ${laptopAndAbove} {
    font-size: 28px;
    margin-bottom: calc(var(--unit) * 5);
  }
`;

const Grid = styled.div`
  display: grid;
  gap: var(--double-unit);
  grid-auto-flow: column;
  overflow: auto;
  grid-template-columns: repeat(3, minmax(320px, 1fr));

  @media ${tabletAndAbove} {
    grid-template-columns: repeat(3, minmax(440px, 1fr));
  }

  @media ${desktopAndAbove} {
    align-content: center;
    grid-template-columns: unset;
  }
`;

interface Props {
  title?: ReactNode;
  football?: boolean;
  nba?: boolean;
  mlb?: boolean;
}

export const NewOtherSports = ({
  title,
  football = true,
  nba = true,
  mlb = true,
}: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <Title>{title || formatMessage(glossary.chooseYourSport)}</Title>
      <Grid>
        {football && <FootballContent />}
        {nba && <NBAContent />}
        {mlb && <BaseballContent />}
      </Grid>
    </Wrapper>
  );
};
