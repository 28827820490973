import { defineMessages } from 'react-intl';

import { useTypedParams } from '@sorare/routing';

import Form from 'pages/no-card-route/Form';

import { PageParams } from './__generated__/routeParams';
import useRequestSo5NoCardLineupEligibleLeaderboards from './useRequestSo5NoCardLineupEligibleLeaderboards';

const messages = defineMessages({
  title: {
    id: 'NoCardEntry.RequestLeaderboards.title',
    defaultMessage: 'Other competitions will be proposed to you by email.',
  },
});

const RequestLeaderboardsPage = () => {
  const { so5LineupId } = useTypedParams<PageParams>();
  const requestLineup = useRequestSo5NoCardLineupEligibleLeaderboards();

  const mutate = async () => {
    const { errors } = await requestLineup({
      so5NoCardLineupId: `So5NoCardLineup:${so5LineupId}`,
    });
    return errors.length === 0;
  };

  return <Form mutate={mutate} title={messages.title} postAction="email" />;
};

export default RequestLeaderboardsPage;
