import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { createGlobalStyle, css } from 'styled-components';

import { Sport } from '__generated__/globalTypes';
import NavLink from 'atoms/buttons/NavLink';
import { AddFriends } from 'atoms/icons/AddFriends';
import StarBall from 'atoms/icons/StarBall';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { MUIBadge } from 'atoms/ui/MUIBadge';
import { useDefaultSportPages } from 'constants/routes';
import {
  MarketplaceOnboardingStep,
  useManagerTaskContext,
} from 'contexts/managerTask';
import useIsLoggedIn from 'hooks/auth/useIsLoggedIn';
import { useIsMobile } from 'hooks/device/useIsMobile';
import { useHasUnclaimedRefereeRewardsAsReferee } from 'hooks/referral/useHasUnclaimedRefereeRewardsAsReferee';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { useInviteLink } from 'hooks/useInviteLink';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import { navLabels } from 'lib/glossary';
import { useAppBarContext } from 'routing/MultiSportAppBar/context';
import useShowBottomBarNav from 'routing/MultiSportBottomNavBar/useShowBottomBarNav';

import { NavItem } from '../NavItem';
import { NavItems } from '../NavItems';
import { SportsNavItems } from '../SportsNavItems';

export const APP_BAR_MOBILE_HEIGHT = 57;
const MIN_BOTTOM_PADDING_MOBILE_APP = 16;

const GlobalStyle = createGlobalStyle<{
  noHeight: boolean;
  minSafeAreaInsetBottom?: number;
}>`
  :root {
    --navbar-width-desktop: 80px;
    ${({ minSafeAreaInsetBottom = 0 }) => css`
      --safe-area-inset-bottom: max(
        ${minSafeAreaInsetBottom}px,
        env(safe-area-inset-bottom, 0px)
      );
    `}
    --bottom-bar-height-mobile: calc(${APP_BAR_MOBILE_HEIGHT}px + var(--safe-area-inset-bottom));
    ${({ noHeight }) =>
      noHeight &&
      css`
        --bottom-bar-height-mobile: 0px;
      `}
  }
`;

const Root = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  transition: 0.3s ease transform;
  z-index: 2;
  &.hide {
    /* account for 2px badge overflow */
    transform: translateY(calc(var(--nav-height, 100%) + 2px));
  }
  &.sideNavBar {
    position: sticky;
    top: 0;
    bottom: unset;
    height: 100vh;
    width: var(--navbar-width-desktop);
    overflow: hidden;
    &.hide {
      transform: unset;
    }
  }
`;

const Nav = styled.nav`
  background: var(--c-neutral-100);
  border-top: 1px solid var(--c-neutral-300);
  /* let some space for the bottom home swipe bar */
  padding-bottom: var(--safe-area-inset-bottom);
  .sideNavBar & {
    display: flex;
    flex-direction: column;
    border-top: unset;
    border-right: 1px solid var(--c-neutral-300);
    height: 100vh;
    padding-bottom: 0;
  }
`;

const List = styled.div`
  display: flex;
  .sideNavBar & {
    flex-direction: column;
    height: inherit;
  }
`;

const PortalReceiver = styled(Vertical).attrs({ gap: 0, center: true })`
  padding-bottom: var(--double-unit);
  width: 100%;
  justify-content: stretch;
`;

const SorareNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: var(--quadruple-unit);
    height: var(--quadruple-unit);
  }
  .sideNavBar & {
    padding: var(--double-unit) 0 var(--quadruple-unit);
  }
`;

const ReferralNavItem = styled(NavItem)`
  & > svg {
    width: 20px;
  }
`;

export const MobileAppBottomPortalReceiver = styled(Horizontal).attrs({
  gap: 0,
})`
  position: sticky;
  bottom: 0px;
  width: 100%;
  justify-content: center;
  padding-bottom: var(--double-unit);
`;

export const AppNavigation = () => {
  const {
    flags: { useSportsNavItems = false },
  } = useFeatureFlags();
  const ref = useRef<HTMLDivElement>(null);
  const scrollRef = useRef({ oldScroll: 0 });
  const [hide, setHide] = useState(false);
  const showBottomBarNav = useShowBottomBarNav();
  const isLoggedIn = useIsLoggedIn();
  const { sport = Sport.FOOTBALL } = useAppBarContext();
  const { formatMessage } = useIntl();
  const defaultSportPages = useDefaultSportPages();
  const { isMobileApp, hideBottomNavBar } = useIsMobileApp();
  const { step } = useManagerTaskContext();
  const isMobile = useIsMobile();
  const hasUnclaimedReferralReward = useHasUnclaimedRefereeRewardsAsReferee();

  const inviteLink = useInviteLink();

  useEffect(() => {
    if (!showBottomBarNav) {
      return () => {};
    }
    const onScroll = () => {
      const { scrollingElement } = document;
      if (
        !scrollingElement ||
        !ref.current ||
        isMobileApp ||
        step === MarketplaceOnboardingStep.menu
      ) {
        return;
      }

      const { scrollTop } = scrollingElement;
      const isScrollingDown = scrollRef.current.oldScroll < scrollTop;
      const hasReachedTop = scrollTop <= 0;
      const hasReachedBottom =
        scrollTop + window.innerHeight >= document.body.offsetHeight;

      scrollRef.current.oldScroll = scrollTop;
      setHide((isScrollingDown || hasReachedBottom) && !hasReachedTop);
    };

    window.document.addEventListener('scroll', onScroll);
    return () => {
      window.document.removeEventListener('scroll', onScroll);
    };
  }, [showBottomBarNav, isMobileApp, step]);

  if (!isLoggedIn) {
    return null;
  }

  if (isMobileApp && hideBottomNavBar) {
    return (
      <>
        <GlobalStyle noHeight />
        <Root className={classnames({ hide: false, sideNavBar: false })}>
          <MobileAppBottomPortalReceiver id="above-bottom-bar-portal" />
        </Root>
      </>
    );
  }

  return (
    <>
      <GlobalStyle
        noHeight={!showBottomBarNav || hide}
        minSafeAreaInsetBottom={isMobileApp ? MIN_BOTTOM_PADDING_MOBILE_APP : 0}
      />
      <Root
        className={classnames({ hide, sideNavBar: !showBottomBarNav })}
        style={
          {
            '--nav-height': `${ref.current?.offsetHeight || 0}px`,
          } as React.CSSProperties
        }
      >
        {showBottomBarNav && <PortalReceiver id="above-bottom-bar-portal" />}
        <Nav ref={ref}>
          <List>
            {!showBottomBarNav && (
              <SorareNavLink
                title={formatMessage(navLabels.home)}
                to={defaultSportPages[sport]}
              >
                <StarBall color="var(--c-neutral-1000)" />
              </SorareNavLink>
            )}
            <NavItems sport={sport} />
            {!isMobile && (
              <ReferralNavItem to={inviteLink}>
                <MUIBadge badgeContent={hasUnclaimedReferralReward ? 1 : null}>
                  <AddFriends />
                </MUIBadge>
                <FormattedMessage {...navLabels.inviteFriends} />
              </ReferralNavItem>
            )}
            {useSportsNavItems && !showBottomBarNav && (
              <SportsNavItems current={sport} />
            )}
          </List>
        </Nav>
      </Root>
    </>
  );
};
