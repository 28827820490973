import { defineMessage, useIntl } from 'react-intl';

import { Sport } from '__generated__/globalTypes';
import { DumbContent } from 'components/landing/NewOtherSports/DumbContent';
import { sportsLabelsMessages } from 'lib/glossary';

import footballBg from './assets/footballBg.jpg';

const message = defineMessage({
  id: 'Landing.NewOtherSports.football',
  defaultMessage: 'Featuring over 300 officially licensed football clubs',
});

export const footballCards = [
  'https://assets.sorare.com/card/51c7d561-9252-4c0d-aad7-dba862a9890e/picture/tinified-a86584466b9d875355f2d78492dd5294.png',
  'https://assets.sorare.com/card/75e974f6-2035-4711-aeb3-551da9e1f010/picture/tinified-fe789532f2f57791f2c514b484b598ca.png',
  'https://assets.sorare.com/card/d7cdc1eb-6f98-4158-acea-fc4a834a74db/picture/tinified-289ba0a00c7b7edf1a4b6e562577b558.png',
  'https://assets.sorare.com/card/9ef41bbe-1922-4507-8ad1-4ecb1a9b45b4/picture/tinified-073321d77ad40b5b403a1120d8614a06.png',
  'https://assets.sorare.com/card/d5b60d79-8ec0-46bf-a45f-3e488d2d6c64/picture/tinified-5258c8142cdf15ac8dec337787320674.png',
];

export const FootballContent = () => {
  const { formatMessage } = useIntl();

  return (
    <DumbContent
      bgImage={footballBg}
      sport={Sport.FOOTBALL}
      cardsUrls={footballCards}
      text={formatMessage(message)}
      title={formatMessage(sportsLabelsMessages[Sport.FOOTBALL])}
    />
  );
};
