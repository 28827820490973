import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { skeletonStyle } from 'style/utils';

const DEFAULT_BORDER_RADIUS = 'var(--half-unit)';

const Wrapper = styled.span.attrs({
  tabIndex: 0,
  role: 'progressbar',
  'aria-busy': true,
  'aria-valuemin': 0,
  'aria-valuemax': 100,
})`
  background-color: rgba(var(--c-rgb-neutral-1000), 0.05);
  border-radius: var(--half-unit);
  ${skeletonStyle}
`;

const ChildWrapper = styled.span`
  position: relative;
  display: inline-block;
  width: 100%;
  visibility: hidden;
  pointer-events: none;
  &:empty:before {
    content: '\\00a0';
    display: inline-block;
    width: 10ch;
  }
`;

type Props = {
  children?: ReactNode;
  className?: string;
  loading?: boolean;
  circle?: boolean;
};

export const Skeleton = ({ children, className, loading, circle }: Props) => {
  const ref = useRef<HTMLDivElement>(null!);
  const [style, setStyles] = useState<CSSProperties>({});

  useEffect(() => {
    const childElement = ref.current?.children?.[0] as HTMLElement | undefined;
    const hasChildText = childElement?.nodeType === Node.TEXT_NODE;

    const childStyle: Partial<CSSStyleDeclaration> =
      !childElement || hasChildText
        ? { display: 'inline', borderRadius: DEFAULT_BORDER_RADIUS }
        : getComputedStyle(childElement);

    let { borderRadius } = childStyle;
    if (circle) {
      borderRadius = '50%';
    } else if (borderRadius === '0px') {
      borderRadius = DEFAULT_BORDER_RADIUS;
    }

    // Handle child text nodes or elemenets with display: inline
    if (childStyle.display === 'inline') {
      setStyles({
        display: 'inline-block',
        width: 'fit-content',
        height: 'auto',
        borderRadius,
      });
    } else {
      setStyles({
        display: childStyle.display,
        borderRadius,
        width: childElement?.offsetWidth,
        height: childElement?.offsetHeight,
      });
    }
  }, [circle]);

  if (!loading) return <>{children}</>;

  return (
    <Wrapper style={style} className={className}>
      <ChildWrapper ref={ref}>{children}</ChildWrapper>
    </Wrapper>
  );
};
