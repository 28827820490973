import { FormattedNumber } from 'react-intl';

import { Rarity, Sport } from '__generated__/globalTypes';
import Button from 'atoms/buttons/Button';
import { Box } from 'atoms/icons/Box';
import { Cards } from 'atoms/icons/Cards';
import { Shards } from 'atoms/icons/Shards';
import { LabelL } from 'atoms/typography';
import { MUIBadge } from 'atoms/ui/MUIBadge';
import {
  ANY_SPORT_PLAY_INVENTORY_BOXES_TYPE,
  ANY_SPORT_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE,
  ANY_SPORT_PLAY_INVENTORY_SHARDS_TYPE,
} from 'constants/routes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useSportContext } from 'contexts/sport';
import useFeatureFlags from 'hooks/useFeatureFlags';

type Props = {
  onClick?: () => void;
};

export const InventoryBalance = ({ onClick }: Props) => {
  const {
    flags: { useRailsToPowerNba = false },
  } = useFeatureFlags();

  const { currentUser } = useCurrentUserContext();
  const { sport = Sport.FOOTBALL, generateSportContextPath } =
    useSportContext();

  if (
    ![Sport.FOOTBALL, ...(useRailsToPowerNba ? [Sport.NBA] : [])].includes(
      sport
    ) ||
    !currentUser
  ) {
    return null;
  }
  const {
    blueprintFootballCards,
    blueprintNBACards,
    blueprintBaseballCards,
    shardsChestsOfFootball,
    shardsChestsOfNBA,
    shardsChestsOfBaseball,
    unopenedBoxesOfFootball,
    unopenedBoxesOfNBA,
    unopenedBoxesOfBaseball,
  } = currentUser;

  const chests = {
    [Sport.BASEBALL]: shardsChestsOfBaseball,
    [Sport.FOOTBALL]: shardsChestsOfFootball,
    [Sport.NBA]: shardsChestsOfNBA,
  }[sport];

  const totalShards = chests.reduce(
    (acc, chest) => acc + chest.cardShardsCount,
    0
  );

  const chestWithThresholdUnlocked = chests.find(
    chest => chest.newThresholdUnlocked
  );

  const totalBlueprints = {
    [Sport.FOOTBALL]: blueprintFootballCards.totalCount,
    [Sport.NBA]: blueprintNBACards.totalCount,
    [Sport.BASEBALL]: blueprintBaseballCards.totalCount,
  }[sport];

  const totalRewardBoxes = {
    [Sport.FOOTBALL]: unopenedBoxesOfFootball.totalCount,
    [Sport.NBA]: unopenedBoxesOfNBA.totalCount,
    [Sport.BASEBALL]: unopenedBoxesOfBaseball.totalCount,
  }[sport];

  return (
    <>
      {totalBlueprints > 0 && (
        <Button
          size="medium"
          color="opa"
          to={generateSportContextPath(
            ANY_SPORT_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE,
            {
              params: {
                type: Rarity.limited,
              },
            }
          )}
          onClick={onClick}
        >
          <Cards />
          <LabelL as="span" bold>
            <FormattedNumber value={totalBlueprints} />
          </LabelL>
        </Button>
      )}
      <Button
        size="medium"
        color="opa"
        to={generateSportContextPath(ANY_SPORT_PLAY_INVENTORY_BOXES_TYPE, {
          params: {
            type: Rarity.limited,
          },
        })}
        onClick={onClick}
      >
        <Box />
        <LabelL as="span" bold>
          <FormattedNumber value={totalRewardBoxes} />
        </LabelL>
      </Button>
      <Button
        size="medium"
        color="opa"
        to={generateSportContextPath(ANY_SPORT_PLAY_INVENTORY_SHARDS_TYPE, {
          params: {
            type: chestWithThresholdUnlocked?.rarity || Rarity.limited,
          },
        })}
        onClick={onClick}
      >
        <MUIBadge variant="dot" invisible={!chestWithThresholdUnlocked}>
          <Shards />
        </MUIBadge>
        <LabelL as="span" bold>
          <FormattedNumber value={totalShards} />
        </LabelL>
      </Button>
    </>
  );
};
