import { Navigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { NBA_PLAY } from '@sorare/core/src/constants/routes';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';

const Wrapper = styled.div``;

const S3HomeLayout = () => {
  const {
    flags: { useRailsToPowerNba = false },
  } = useFeatureFlags();

  if (!useRailsToPowerNba) {
    return <Navigate to={NBA_PLAY} replace />;
  }

  return (
    <Wrapper>
      <Outlet />
    </Wrapper>
  );
};
export default S3HomeLayout;
