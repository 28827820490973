import { useCallback } from 'react';

import { NotificationCategoryInput } from '__generated__/globalTypes';
import {
  NotificationGroup,
  notificationCategoryTypes,
  notificationGroups,
} from 'components/activity/constants';

export const useGetNotificationCategories = () => {
  return useCallback(
    (groups: NotificationGroup[]): NotificationCategoryInput[] =>
      groups.flatMap(group =>
        notificationGroups[group].map(category => ({
          name: category,
          type: notificationCategoryTypes[category],
        }))
      ),
    []
  );
};
