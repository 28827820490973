import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useMatch } from 'react-router-dom';
import styled from 'styled-components';

import { catchAll } from '@sorare/routing';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import Button from '@sorare/core/src/atoms/buttons/Button';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { BodyM, DisplayL } from '@sorare/core/src/atoms/typography';
import Dialog from '@sorare/core/src/components/dialog';
import {
  FOOTBALL_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE,
  FOOTBALL_PLAY_RIVALS,
} from '@sorare/core/src/constants/__generated__/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useOneTimeDialogContext } from '@sorare/core/src/contexts/oneTimeDialog';
import { OneTimeDialog } from '@sorare/core/src/contexts/oneTimeDialog/Provider';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import { LIFECYCLE } from '@sorare/core/src/hooks/useLifecycle';
import { glossary } from '@sorare/core/src/lib/glossary';

import bg from './assets/bg.png';
import card from './assets/card.jpg';
import { useHasCardsToReveal } from './useHasCardsToReveal';

const Wrapper = styled.div`
  --c-dialog-background: transparent;
`;

const Actions = styled(Vertical)`
  padding: var(--unit);
`;

const Content = styled(Vertical)`
  text-align: center;
  background-position: center;
  background-size: cover;
`;

export const RevealAnnouncement = () => {
  const {
    flags: { useRevealAnnouncement = false },
  } = useFeatureFlags();

  const { hasCardsToReveal, rarity } = useHasCardsToReveal();
  const { onboarded } = useCurrentUserContext();
  const { isIosApp } = useIsMobileApp();

  // Use a ref as we dont want update it so it wont show during the whole first session
  const isOnboarded = useRef(onboarded[Sport.FOOTBALL]);

  const { sawDialog } = useOneTimeDialogContext();
  const alreadySeen = sawDialog(LIFECYCLE.sawRevealAnnouncementDialog);

  const matchRivals = useMatch(catchAll(FOOTBALL_PLAY_RIVALS));

  if (
    !isOnboarded.current ||
    !useRevealAnnouncement ||
    !hasCardsToReveal ||
    alreadySeen ||
    isIosApp ||
    matchRivals
  ) {
    return null;
  }

  return (
    <Wrapper>
      <OneTimeDialog
        dialogId={LIFECYCLE.sawRevealAnnouncementDialog}
        show={!alreadySeen}
      >
        {({ onClose, open }) => (
          <Dialog
            maxWidth="xs"
            fullWidth
            hideHeader
            transparent
            open={open}
            onClose={onClose}
            fullScreen={false}
          >
            <Content center style={{ backgroundImage: `url(${card})` }} gap={2}>
              <img width="222px" src={bg} alt="" />
              <DisplayL>
                <FormattedMessage
                  defaultMessage="Reveal{br}Your Cards"
                  id="RevealAnnouncement.title"
                  values={{ br: <br /> }}
                />
              </DisplayL>
              <BodyM>
                <FormattedMessage
                  id="RevealAnnouncement.timeToUnveil"
                  defaultMessage="The time has come to unveil your Early Access Cards"
                />
              </BodyM>
              <Actions center gap={2}>
                <Button
                  fullWidth
                  color="secondary"
                  size="medium"
                  onClick={onClose}
                  to={generatePath(
                    FOOTBALL_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE,
                    { type: rarity }
                  )}
                >
                  <FormattedMessage
                    id="forYou.earlyAccess.goToReveal"
                    defaultMessage="Go to reveal"
                  />
                </Button>
                <Button size="medium" color="transparent" onClick={onClose}>
                  <FormattedMessage {...glossary.doItLater} />
                </Button>
              </Actions>
            </Content>
          </Dialog>
        )}
      </OneTimeDialog>
    </Wrapper>
  );
};
