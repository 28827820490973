/* eslint-disable jsx-a11y/media-has-caption */
import { faVolumeMute, faVolumeUp } from '@fortawesome/pro-regular-svg-icons';
import {
  PropsWithChildren,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import styled from 'styled-components';

import IconButton from 'atoms/buttons/IconButton';
import useIsVisibleInViewport from 'hooks/ui/useIsVisibleInViewport';
import { useVideoAutoPlay } from 'hooks/useVideoAutoPlay';

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  & > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: bottom;
  }
`;
const MutedIcon = styled(IconButton)`
  position: absolute;
  z-index: 2;
  bottom: var(--double-unit);
  right: var(--double-unit);
`;

export enum VIDEO_FORMAT {
  MP4 = 'video/mp4',
  WEBM = 'video/webm',
}

type Props = {
  hideMutedIcon?: boolean;
  sources: React.JSX.IntrinsicElements['source'][];
  className?: string;
  onMuteClick?: (muted: boolean) => void;
} & React.JSX.IntrinsicElements['video'];
export const Video = forwardRef<HTMLVideoElement, PropsWithChildren<Props>>(
  (
    {
      sources,
      className,
      autoPlay,
      hideMutedIcon,
      onError,
      onMuteClick,
      ...rest
    }: Props,
    forwardedRef
  ) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    useImperativeHandle(forwardedRef, () => videoRef.current!);
    const { muted, toggleMuted } = useVideoAutoPlay(
      videoRef,
      !!autoPlay,
      onError
    );
    const { handleRef, isVisible } = useIsVisibleInViewport();

    useEffect(() => {
      if (isVisible) {
        videoRef.current?.play();
      } else {
        videoRef.current?.pause();
      }
    });

    return (
      <Root ref={handleRef} className={className}>
        <video
          ref={videoRef}
          // 1x1 transparent GIF to avoid ugly default poster in Android webview
          poster="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
          {...rest}
        >
          {sources.map(
            ({ src, type = VIDEO_FORMAT.MP4, media }, i) =>
              src && (
                <source
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${i}-${src}`}
                  src={src}
                  type={type}
                  media={media}
                />
              )
          )}
          {rest.children}
        </video>
        {!hideMutedIcon && (
          <MutedIcon
            icon={muted ? faVolumeMute : faVolumeUp}
            onClick={() => {
              onMuteClick?.(!!muted);
              toggleMuted();
            }}
            color="transparent"
          />
        )}
      </Root>
    );
  }
);
Video.displayName = 'Video';
