import { faHourglassStart } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import { CSSProperties, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css, keyframes } from 'styled-components';

import { AnimatedNumber } from 'atoms/animations/AnimatedNumber';
import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal } from 'atoms/layout/flex';
import { BodyS, LabelM } from 'atoms/typography';
import { Cost } from 'components/player/Cost';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import { fantasy, playerGameStatusLabels } from 'lib/glossary';
import { absCenter } from 'lib/style';
import { Color } from 'style/types';

const ASPECT_RATIO = 37 / 24;
const getScale = (scale: number) =>
  `${scale}, ${(scale - 1) * ASPECT_RATIO + 1}`;

const base = keyframes`
0%,
9% {
  transform: scale(1);
}
10%,
42% {
  background: var(--color);
  transform: scale(${getScale(1.2)});
}
43% {
  background: radial-gradient(transparent 0%, var(--color) 90%, var(--color) 100%);
}
66% {
  transform: scale(${getScale(1.5)});
  background: radial-gradient(transparent 0%, var(--color) 90%, var(--color) 100%);
}
70%,
82% {
  transform: scale(1);
  background: var(--color);
}
92% {
  transform: scale(${getScale(1.2)});
}
100% {
  transform: scale(1);
}
`;

const base2 = keyframes`
0%,
9% {
  transform: scale(1);
}
10%,
42% {
  background: var(--color);
  transform: scale(1.2);
}
43% {
  background: radial-gradient(transparent 0%, var(--color) 90%, var(--color) 100%);
}
66% {
  transform: scale(1.5);
  background: radial-gradient(transparent 0%, var(--color) 90%, var(--color) 100%);
}
70%,
82% {
  transform: scale(1);
  background: var(--color);
}
92% {
  transform: scale(1.2);
}
100% {
  transform: scale(1);
}
`;

const pulse = keyframes`
  from {
    transform: scale(${getScale(1.4)});
  }
  to {
    transform: scale(${getScale(1.6)});
    opacity: 0;
  }
`;
const bounce = keyframes`
  34% {
    transform: translateY(0);
  }
  53% {
    transform: translateY(-20%);
  }
  82% {
    transform: translateY(10%);
  }
  90% {
    transform: translateY(0);
  }
`;

const runAnimationCss = css`
  &.runAnimation {
    animation: 1.3s cubic-bezier(0.91, 0, 0.31, 0.62) ${bounce} forwards;
    &::before {
      position: absolute;
      z-index: -1;
      inset: 0;
      background: var(--color);
      animation: 1.3s linear ${base} forwards;
      border-radius: inherit;
      content: '';
    }
    i {
      position: absolute;
      inset: 0;
      z-index: -1;

      &,
      &::before,
      &::after {
        border-radius: inherit;
      }
      &::after,
      &::before {
        position: absolute;
        inset: 0;
        background: radial-gradient(
          transparent 0%,
          var(--color) 90%,
          var(--color) 100%
        );
        content: '';
        border-radius: inherit;
        animation: 0.34s ease ${pulse} forwards 0.19s;
      }
      &::after {
        animation-delay: 0.41s;
      }
    }
  }
`;

const Root = styled(LabelM)`
  --color: #f7aa38;
  isolation: isolate;
  position: relative;
  border-radius: 2em;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: var(--half-unit);
  height: var(--triple-unit);
  padding: 0 var(--unit);
  background: var(--color);
  color: var(--c-neutral-100);
  transform: translateY(0);
  letter-spacing: -1px; /** Counter the tabular-nums spacing while keeping the same monospace aspect */
  font-variant: tabular-nums;
  > span {
    min-width: 2ch;
  }
  ${runAnimationCss}
`;
const StyledCost = styled(Cost)`
  display: inline-flex;
  &.withColor {
    color: var(--c-neutral-100);
  }
  ${runAnimationCss}
  &.runAnimation {
    &::before {
      inset: 0 2px 0 2px;
      clip-path: path(
        'M2.015 6.848A4 4 0 0 0 0 10.321V21.68a4 4 0 0 0 2.015 3.473l10 5.714a4 4 0 0 0 3.97 0l10-5.714A4 4 0 0 0 28 21.679V10.32a4 4 0 0 0-2.015-3.473l-10-5.714a4 4 0 0 0-3.97 0l-10 5.714Z'
      );
      animation: 1.3s linear ${base2} forwards;
    }
  }
`;
const Arrows = styled.svg`
  ${absCenter}
  z-index: -1;
  path {
    fill: #ff5600;
  }
  &.up {
    transform: translate(-50%, -50%) rotate(180deg);
    path {
      fill: #00ff46;
    }
  }
`;

const ArrowsWrapper = ({ up }: { up: boolean }) => {
  return (
    <Arrows
      className={classnames({ up })}
      height="50"
      viewBox="0 0 45 35"
      fill="none"
    >
      {[0, 1, 2].map(id => (
        <path key={id}>
          <animate
            attributeName="d"
            begin={id * 0.15}
            dur=".46"
            values="M16 4V1.33333L22.5 0L29 1.33333V4L22.5 2.66667L16 4Z;M16 4.00004V1.33337L22.5 1.33333L29 1.33337V4.00004L22.5 4L16 4.00004Z;M8 24.5106V10L22.5 16.7872L37 10V24.5106L22.5 32L8 24.5106Z;M16 33.3217V30.655L22.5 32.3217L29 30.655V33.3217L22.5 34.9884L16 33.3217Z"
            keyTimes="0; 0.2; 0.5; 1"
          />
        </path>
      ))}
    </Arrows>
  );
};

type PillProps = {
  shape?: 'PILL';
  color: Color;
};
type HexagonProps = {
  shape?: 'HEXAGON';
  color?: Color;
};
type TextProps = {
  shape?: 'TEXT';
  color?: Color;
};

export type Props = {
  score: number;
  reviewing?: boolean;
  ariaLabel?: string;
  delayMs?: number;
  animated?: boolean;
  withSuffix?: boolean;
} & (PillProps | HexagonProps | TextProps);

const DEFAULT_ANIMATION_DURATION = 3000;

export const Scoring = ({
  color,
  score,
  reviewing,
  ariaLabel,
  shape = 'PILL',
  delayMs,
  animated = true,
  withSuffix,
}: Props) => {
  const [runAnimation, setRunAnimation] = useState<null | 'up' | 'down'>(null);
  const [displayedScore, setDisplayedScore] = useState(score);
  const { isAndroidApp, postMessage } = useIsMobileApp();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (score === displayedScore) {
      return () => {};
    }

    const animationTimeout =
      typeof delayMs === 'number'
        ? delayMs
        : Math.random() * (animated ? DEFAULT_ANIMATION_DURATION : 0);

    const clear = setTimeout(() => {
      if (isAndroidApp) {
        postMessage('animateScore', { score, previousScore: displayedScore });
      }
      setRunAnimation(score > displayedScore ? 'up' : 'down');
      setDisplayedScore(score);
    }, animationTimeout);

    return () => {
      clearTimeout(clear);
    };
  }, [displayedScore, score, isAndroidApp, postMessage, delayMs, animated]);

  useEffect(() => {
    if (!runAnimation) {
      return () => {};
    }

    const clear = setTimeout(() => {
      setRunAnimation(null);
    }, 1300);

    return () => {
      clearTimeout(clear);
    };
  }, [runAnimation]);

  const numberComponent = (
    <AnimatedNumber value={displayedScore} disableAnimationOnFirstRender />
  );
  const content = (
    <>
      {reviewing && (
        <FontAwesomeIcon
          icon={faHourglassStart}
          size="xs"
          aria-label={formatMessage(playerGameStatusLabels.reviewing)}
        />
      )}

      {withSuffix ? (
        <Horizontal gap={0}>
          <FormattedMessage
            {...fantasy.pointsWithoutFormat}
            values={{ score: numberComponent }}
          />
        </Horizontal>
      ) : (
        numberComponent
      )}
      {animated && runAnimation && (
        <>
          <i />
          <ArrowsWrapper up={runAnimation === 'up'} />
        </>
      )}
    </>
  );

  if (shape === 'TEXT') {
    return (
      <BodyS bold color={color} className="text-center">
        {content}
      </BodyS>
    );
  }

  if (shape === 'PILL') {
    return (
      <Root
        bold
        style={{ '--color': color } as CSSProperties}
        className={classnames({ runAnimation: animated && runAnimation })}
        as="div"
        aria-label={ariaLabel}
      >
        {content}
      </Root>
    );
  }

  return (
    <StyledCost
      className={classnames({
        runAnimation: animated && runAnimation,
        withColor: !!color,
      })}
      score={content}
      color={color}
      style={{ '--color': color } as CSSProperties}
    />
  );
};
