import { TypedDocumentNode, gql, useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

import { KycDocumentRequirement } from '__generated__/globalTypes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useIntlCountries } from 'hooks/useIntlCountries';

import ChooseDocument from './ChooseDocument';
import Upload, { Props as UploadProps } from './Upload';
import {
  KYCDocumentRequirementsQuery,
  KYCDocumentRequirementsQueryVariables,
} from './__generated__/index.graphql';

const KYC_DOCUMENT_REQUIREMENTS_QUERY = gql`
  query KYCDocumentRequirementsQuery($countryCode: String!) {
    mangopay {
      id
      kycDocumentRequirements(countryCode: $countryCode) {
        documentType
        nbPages
      }
    }
  }
` as TypedDocumentNode<
  KYCDocumentRequirementsQuery,
  KYCDocumentRequirementsQueryVariables
>;

export enum ProofOfIdStep {
  CHOOSE_DOCUMENT = 'CHOOSE_DOCUMENT',
  UPLOAD = 'UPLOAD',
}

type ProofOfIdentityFormProps = Omit<
  UploadProps,
  'onBack' | 'documentRequirement'
> & {
  onBack: () => void;
  onStepChange?: (newStep: ProofOfIdStep) => void;
  cotrolledUploadProofOfIdStep?: ProofOfIdStep;
};

export const ProofOfIdentityForm = ({
  onBack,
  onStepChange,
  cotrolledUploadProofOfIdStep,
  ...uploadProps
}: ProofOfIdentityFormProps) => {
  const [uploadProofOfIdStep, setUploadProofOfIdStep] = useState<ProofOfIdStep>(
    ProofOfIdStep.CHOOSE_DOCUMENT
  );
  const { fiatWalletAccountable } = useCurrentUserContext();
  const countries = useIntlCountries(true);

  const currentUserCountryOfResidence = countries.find(
    c => c.value === fiatWalletAccountable?.countryOfResidenceCode
  );

  const [issuingCountry, setIssuingCountry] = useState<
    { value: string; label: string } | undefined
  >(currentUserCountryOfResidence);
  const [kycDocumentRequirements, setKycDocumentRequirements] = useState<
    KycDocumentRequirement[]
  >([]);
  const [documentRequirement, setDocumentRequirement] =
    useState<KycDocumentRequirement>();

  const [query, { loading }] = useLazyQuery(KYC_DOCUMENT_REQUIREMENTS_QUERY);

  useEffect(() => {
    if (
      cotrolledUploadProofOfIdStep &&
      cotrolledUploadProofOfIdStep !== uploadProofOfIdStep
    ) {
      setUploadProofOfIdStep(cotrolledUploadProofOfIdStep);
    }
  }, [cotrolledUploadProofOfIdStep, uploadProofOfIdStep]);

  const fetchKycRequirements = useCallback(async () => {
    if (issuingCountry) {
      const result = await query({
        variables: { countryCode: issuingCountry.value },
      });

      if (result.data) {
        const { kycDocumentRequirements: requirements } = result.data.mangopay;
        setKycDocumentRequirements(requirements);
        setDocumentRequirement(requirements[0]);
      }
    }
  }, [issuingCountry, query]);

  useEffect(() => {
    fetchKycRequirements();
  }, [fetchKycRequirements]);

  const uploadProofOfIdStepCb = (newStep: ProofOfIdStep) => {
    setUploadProofOfIdStep(newStep);
    onStepChange?.(newStep);
  };

  if (uploadProofOfIdStep === ProofOfIdStep.UPLOAD && documentRequirement)
    return (
      <Upload
        documentRequirement={documentRequirement}
        onBack={() => {
          uploadProofOfIdStepCb(ProofOfIdStep.CHOOSE_DOCUMENT);
        }}
        {...uploadProps}
      />
    );

  return (
    <ChooseDocument
      issuingCountry={issuingCountry}
      setIssuingCountry={setIssuingCountry}
      kycDocumentRequirements={kycDocumentRequirements}
      kycDocumentRequirementsLoading={loading}
      documentRequirement={documentRequirement}
      setDocumentRequirement={setDocumentRequirement}
      onBack={onBack}
      onNext={() => {
        uploadProofOfIdStepCb(ProofOfIdStep.UPLOAD);
      }}
    />
  );
};
