import { ReactElement } from 'react';

import LoadingIndicator from '@sorare/core/src/atoms/loader/LoadingIndicator';

import { useCurrentUserContext } from '.';

export const RequireCurrentBaseballUser: React.FC<
  React.PropsWithChildren<{ children: ReactElement }>
> = ({ children }) => {
  const { currentBaseballUser } = useCurrentUserContext();

  if (!currentBaseballUser) {
    return <LoadingIndicator fullScreen />;
  }
  return children;
};
