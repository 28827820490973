import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import {
  FOOTBALL_BUNDLED_AUCTIONS_ID,
  FOOTBALL_CARDS_SLUG,
  FOOTBALL_MARKET,
  FOOTBALL_MARKET_INSTANT_BUY,
  FOOTBALL_MARKET_MANAGER_SALES,
  FOOTBALL_MARKET_MANAGER_SALES_PLAYERSLUG_RARITY,
  FOOTBALL_MARKET_MOBILE_INSTANT_BUY,
  FOOTBALL_MARKET_MOBILE_NEW_SIGNINGS,
  FOOTBALL_MARKET_NEW_SIGNINGS,
  FOOTBALL_MY_CLUB_SLUG_CARDS,
  FOOTBALL_PLAY_RIVALS_INSTANT_BUY_SLUG,
} from '@sorare/core/src/constants/__generated__/routes';
import {
  FOOTBALL_CLUB_SHOW_CARDS,
  FOOTBALL_COUNTRY_SHOW_CARDS,
  FOOTBALL_LEAGUE_SHOW_CARDS,
  FOOTBALL_PLAYER_SHOW_CARDS,
} from '@sorare/core/src/constants/routes';

import { MarketSubscriptions } from '@sorare/marketplace/src/components/MarketSubscriptions';

const AUCTION_SUBSCRIPTION_ROUTES = [
  FOOTBALL_MARKET,
  FOOTBALL_MARKET_NEW_SIGNINGS,
  FOOTBALL_BUNDLED_AUCTIONS_ID,
  FOOTBALL_CARDS_SLUG,
  FOOTBALL_PLAYER_SHOW_CARDS,
  FOOTBALL_COUNTRY_SHOW_CARDS,
  FOOTBALL_LEAGUE_SHOW_CARDS,
  FOOTBALL_CLUB_SHOW_CARDS,
  FOOTBALL_MARKET_MOBILE_NEW_SIGNINGS,
];

const OFFERS_SUBSCRIPTION_ROUTES = [
  FOOTBALL_MARKET,
  FOOTBALL_MARKET_MANAGER_SALES,
  FOOTBALL_MARKET_MANAGER_SALES_PLAYERSLUG_RARITY,
  FOOTBALL_CARDS_SLUG,
  FOOTBALL_PLAYER_SHOW_CARDS,
  FOOTBALL_COUNTRY_SHOW_CARDS,
  FOOTBALL_LEAGUE_SHOW_CARDS,
  FOOTBALL_CLUB_SHOW_CARDS,
  FOOTBALL_MY_CLUB_SLUG_CARDS,
];

const PRIMARY_OFFERS_SUBSCRIPTION_ROUTES = [
  FOOTBALL_MARKET,
  FOOTBALL_MARKET_INSTANT_BUY,
  FOOTBALL_MARKET_MOBILE_INSTANT_BUY,
  FOOTBALL_PLAY_RIVALS_INSTANT_BUY_SLUG,
  FOOTBALL_CARDS_SLUG,
  FOOTBALL_PLAYER_SHOW_CARDS,
];

export const FootballMarketSubscriptions = () => {
  return (
    <MarketSubscriptions
      sports={[Sport.FOOTBALL]}
      auctionsRoutes={AUCTION_SUBSCRIPTION_ROUTES}
      offersRoutes={OFFERS_SUBSCRIPTION_ROUTES}
      primaryOffersRoutes={PRIMARY_OFFERS_SUBSCRIPTION_ROUTES}
    />
  );
};
