import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import * as Toast from '@radix-ui/react-toast';
import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { keyframes } from 'styled-components';

import CloseButton from 'atoms/buttons/CloseButton';
import IconButton from 'atoms/buttons/IconButton';
import { FontAwesomeIcon } from 'atoms/icons';
import { Portal } from 'atoms/layout/Portal';
import { laptopAndAbove } from 'style/mediaQuery';
import { theme } from 'style/theme';

type Props = {
  onClose: () => void;
  message: ReactNode;
  action?: string;
  open: boolean;
  autoHideDuration?: number | null;
};

const slideDown = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(var(--radix-toast-swipe-end-y));
    opacity: 1;
  }
`;
const slideUp = keyframes`
  from {
    transform: translateY(var(--radix-toast-swipe-end-y));
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

const Root = styled(Toast.Root)`
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  margin: auto;
  background-color: var(--c-dialog-background);
  backdrop-filter: blur(2px);
  color: var(--c-neutral-1000);
  padding: var(--double-unit);
  width: max-content;
  word-break: break-word;
  border: 1px solid var(--c-neutral-150);
  gap: var(--unit);
  border-radius: var(--unit);
  pointer-events: auto;
  a {
    text-decoration: underline;
  }
  &[data-state='open'] {
    animation: ${slideDown} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  &[data-swipe='move'] {
    transform: translateY(var(--radix-toast-swipe-move-y));
  }
  &[data-swipe='cancel'] {
    transform: translateY(0);
    transition: transform 200ms ease-out;
  }
  &[data-state='closed'],
  &[data-swipe='end'] {
    animation: ${slideUp} 100ms ease-out;
  }
  &:focus {
    outline: none;
  }
`;

const Viewport = styled(Toast.Viewport)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${theme.zIndex.snackbar};
  display: flex;
  flex-direction: column;
  padding: var(--double-unit);
  margin: 0; /** Viewport is an ol element that adds margin */
  pointer-events: none;
`;

const Close = styled(Toast.Close)`
  display: none;
  @media ${laptopAndAbove} {
    display: block;
  }
`;
const Description = styled(Toast.Description)`
  align-self: center;
  text-align: center;
  @media ${laptopAndAbove} {
    text-align: left;
  }
`;

export const Snackbar = ({
  onClose,
  open: defaultOpen,
  action,
  message,
  autoHideDuration = 5000,
}: Props) => {
  const [open, setOpen] = useState<boolean>();
  const { formatMessage } = useIntl();
  const onOpenChange = (o: boolean) => {
    setOpen(o);
    if (!o) {
      /** timeout to prevent the message from disappearing too early and trigger a glitch during the slideIn animation */
      setTimeout(onClose, 100);
    }
  };

  if (typeof open === 'undefined' && defaultOpen) {
    setOpen(defaultOpen);
  } else if (typeof open === 'boolean' && !defaultOpen) {
    /** reset to undefined to be able to open multiple time, otherwise only 1 notification could be opened per session */
    setOpen(undefined);
  }

  return (
    <Toast.Provider swipeDirection="up" swipeThreshold={24}>
      <Root
        duration={autoHideDuration || Infinity}
        open={Boolean(open)}
        onOpenChange={onOpenChange}
      >
        <Description>{message}</Description>
        {action && (
          <Toast.Action
            altText={formatMessage({
              id: 'SnackBar.Action.altText',
              defaultMessage: 'Open external link',
            })}
            asChild
          >
            <IconButton
              href={action}
              rel="noopener noreferrer"
              color="tertiary"
            >
              <FontAwesomeIcon icon={faSearch} />
            </IconButton>
          </Toast.Action>
        )}
        <Close asChild>
          <CloseButton onClose={() => onOpenChange(false)} />
        </Close>
      </Root>
      <Portal id="snackbar">
        <Viewport />
      </Portal>
    </Toast.Provider>
  );
};
