import { FC } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import styled from 'styled-components';

import Button from 'atoms/buttons/Button';
import Checkbox from 'atoms/inputs/Checkbox';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { BodyM, HeadlineXL, LabelM } from 'atoms/typography';
import { Box } from 'atoms/ui/Box';
import Dialog from 'components/dialog';
import { useStoreUrl } from 'hooks/useStoreUrl';
import useToggle from 'hooks/useToggle';
import { glossary } from 'lib/glossary';

import illustration from './assets/illustration.jpg';

const Root = styled(Vertical)`
  padding: 0 var(--double-unit);
  height: 100%;
`;
const StyledImg = styled.img`
  height: 280px;
  object-fit: cover;
  object-position: left top;
`;
const StyledBox = styled(Box)`
  background-color: var(--c-neutral-300);
`;
const StyledList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;
const StyledListItem = styled.li`
  padding: var(--unit);

  &:not(:first-child) {
    margin-top: var(--double-unit);
  }
`;
const LabelWrapper = styled.span`
  color: var(--c-neutral-600);
`;
const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--unit);
`;
const FooterRoot = styled(Vertical)`
  padding: var(--double-and-a-half-unit) var(--double-unit) var(--double-unit);
`;
const ImgWrapper = styled(Vertical)`
  flex: 1;
  margin-left: calc(-1 * var(--double-unit));
  margin-right: calc(-1 * var(--double-unit));
  justify-content: center;
`;

export type Benefit = {
  id: string;
  Component: FC<{ width: number; height: number }>;
  label: MessageDescriptor;
};

type Props = {
  open: boolean;
  benefits: readonly Readonly<Benefit>[];
  onClose: (doNotShowAgain: boolean) => void;
};

export const DownloadApp = ({ open, onClose, benefits }: Props) => {
  const [doNotShowAgain, toggleDoNotShowAgain] = useToggle(false);
  const storeUrl = useStoreUrl();

  return (
    <Dialog
      open={open}
      onClose={() => onClose(doNotShowAgain)}
      fullWidth
      maxWidth="xs"
      footer={
        <FooterRoot gap={2}>
          <Checkbox
            checked={doNotShowAgain}
            onChange={toggleDoNotShowAgain}
            label={
              <LabelWrapper>
                <FormattedMessage
                  id="SetReminderDownloadApp.doNotShowAgain"
                  defaultMessage="Don't show this message again"
                />
              </LabelWrapper>
            }
          />
          <ButtonsWrapper>
            <Button
              color="tertiary"
              size="medium"
              onClick={() => onClose(doNotShowAgain)}
            >
              <FormattedMessage {...glossary.close} />
            </Button>
            <Button href={storeUrl} externalLink color="primary" size="medium">
              <FormattedMessage
                id="SetReminderDownloadApp.downloadCta"
                defaultMessage="Download the App"
              />
            </Button>
          </ButtonsWrapper>
        </FooterRoot>
      }
    >
      <Root gap={2.5}>
        <Vertical gap={1}>
          <HeadlineXL as="h2">
            <FormattedMessage
              id="SetReminderDownloadApp.title"
              defaultMessage="Never miss a beat"
            />
          </HeadlineXL>
          <BodyM as="p">
            <FormattedMessage
              id="SetReminderDownloadApp.subtitle"
              defaultMessage="Download the app to get exclusive notifications"
            />
          </BodyM>
        </Vertical>
        <StyledBox>
          <StyledList>
            {benefits.map(({ id, Component, label }) => (
              <StyledListItem key={id}>
                <Horizontal gap={2}>
                  <Component width={16} height={16} />
                  <LabelM as="span">
                    <FormattedMessage {...label} />
                  </LabelM>
                </Horizontal>
              </StyledListItem>
            ))}
          </StyledList>
        </StyledBox>
        <ImgWrapper>
          <StyledImg src={illustration} alt="" />
        </ImgWrapper>
      </Root>
    </Dialog>
  );
};
