import {
  FetchResult,
  TypedDocumentNode,
  gql,
  useMutation,
} from '@apollo/client';
import type { ResultOf } from '@graphql-typed-document-node/core';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import {
  SignupPlatform,
  SorarePrivateKeyAttributes,
} from '__generated__/globalTypes';
import { getValue } from 'components/PersistsQueryStringParameters/storage';
import { useConfigContext } from 'contexts/config';
import { useConnectionContext } from 'contexts/connection';
import { currentUser } from 'contexts/currentUser/queries';
import { useSnackNotificationContext } from 'contexts/snackNotification';
import useAfterLoggedInTarget from 'hooks/useAfterLoggedInTarget';
import { useGetInteractionContext } from 'hooks/useGetInteractionContext';
import usePrevious from 'hooks/usePrevious';
import { useRedirectUrl } from 'hooks/useRedirectUrl';
import { SESSION_STORAGE, useSessionStorage } from 'hooks/useSessionStorage';
import useUtmParams from 'hooks/useUtmParams';
import useEvents from 'lib/events/useEvents';
import { getClientId } from 'lib/ga';
import { formatGqlErrors } from 'lib/gql';
import { SubmitSignUpForm } from 'protos/events/platform/web/events';

import {
  SignUpMutationWithUser,
  SignUpMutationWithUserVariables,
} from './__generated__/useSignUp.graphql';

const SIGN_UP_MUTATION_WITH_USER = gql`
  mutation SignUpMutationWithUser($input: signUpInput!) {
    signUp(input: $input) {
      currentUser {
        slug
        nickname
        ...CurrentUserProvider_currentUser
      }
      errors {
        path
        message
        code
      }
    }
  }
  ${currentUser}
` as TypedDocumentNode<SignUpMutationWithUser, SignUpMutationWithUserVariables>;

function useSignUp() {
  const { updateQuery } = useConfigContext();
  const location = useLocation();
  const lastLocation = usePrevious(location?.pathname);
  const redirectUrl = useRedirectUrl();

  const { getParams } = useUtmParams();
  const [signupPromo, setSignupPromo] = useSessionStorage(
    SESSION_STORAGE.signupPromo
  );
  const { showNotification } = useSnackNotificationContext();
  const track = useEvents();
  const [mutate] = useMutation(SIGN_UP_MUTATION_WITH_USER);
  const afterLoggedInTarget = useAfterLoggedInTarget();
  const { recaptchaRef } = useConnectionContext();
  const getInteractionContext = useGetInteractionContext();

  const submit = useCallback(
    async (attributes: {
      nickname: string;
      email: string;
      passwordHash: string;
      certified: string;
      sorareAddress?: EthereumAddress;
      sorarePrivateKey?: SorarePrivateKeyAttributes;
      sorarePrivateKeyBackup?: string;
      agreedToReceiveOffersFromPartners: boolean;
      acceptTerms: boolean;
      acceptAgeLimit: boolean;
      acceptPrivacyPolicy: boolean;
      acceptGameRules: boolean;
      signupPlatform: SignupPlatform;
      wallet: SignUpMutationWithUserVariables['input']['wallet'];
    }): Promise<FetchResult<SignUpMutationWithUser>> => {
      const { passwordHash, ...rest } = attributes;

      recaptchaRef.current?.reset();
      const recaptchaTokenV2 = await recaptchaRef.current?.executeAsync();
      if (!recaptchaTokenV2) {
        track('Invalid Signup reCAPTCHA V2');
        return {};
      }

      const result = await mutate({
        errorPolicy: 'all', // do not raise but rather forward errors through `result.errors`
        variables: {
          input: {
            ...rest,
            password: passwordHash,
            referrer: getValue('referrer'),
            impactClickId: getValue('irclickid'),
            googleClickId: getValue('gclid'),
            facebookClickId: getValue('fbclid'),
            twitterClickId: getValue('twclid'),
            tikTokClickId: getValue('ttclid'),
            utmParams: getParams(),
            fromPath: redirectUrl || afterLoggedInTarget || lastLocation,
            gaClientId: getClientId(),
            recaptchaTokenV2,
            promoCode: signupPromo?.campaignCode,
          },
        },
      });

      const errors = result.data?.signUp?.errors || [];
      if (errors.length) {
        if (errors[0].path) {
          // new signup flow display the errors in the form, skip the snack notification
          // if the errors have a path (aka: they are form errors)
          return result;
        }
        showNotification('errors', { errors: formatGqlErrors(errors) });
        return result;
      }

      if (result.errors?.find(e => e.message === 'Invalid recaptcha token')) {
        track('Submit invalid signup reCAPTCHA');
      }

      if (!result.errors && result.data?.signUp?.currentUser) {
        track(
          'Submit Sign Up Form',
          SubmitSignUpForm.toJSON({
            interactionContext: getInteractionContext(),
          }) as any
        );
        updateQuery(
          (result.data as ResultOf<typeof SIGN_UP_MUTATION_WITH_USER>).signUp!
            .currentUser!
        );
        showNotification('signUp', {
          nickname: result.data!.signUp?.currentUser!.nickname,
        });
        setSignupPromo(null);
      }

      return result;
    },
    [
      recaptchaRef,
      mutate,
      getParams,
      redirectUrl,
      afterLoggedInTarget,
      lastLocation,
      signupPromo?.campaignCode,
      track,
      showNotification,
      getInteractionContext,
      updateQuery,
      setSignupPromo,
    ]
  );

  return submit;
}

export default useSignUp;
