import { ReactElement } from 'react';

import { MLB } from '@sorare/core/src/constants/__generated__/routes';
import CoreRequireAuth from '@sorare/core/src/routing/RequireAuth';

import { RequireCurrentBaseballUser } from 'contexts/currentBaseballUser/requireUser';

export const RequireAuth: React.FC<
  React.PropsWithChildren<{ children: ReactElement }>
> = ({ children }) => (
  <CoreRequireAuth to={MLB}>
    <RequireCurrentBaseballUser>{children}</RequireCurrentBaseballUser>
  </CoreRequireAuth>
);
