import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { Text16 } from 'atoms/typography';
import {
  AFFILIATE_PROGRAM,
  BLOG,
  CAREERS,
  COOKIE_POLICY,
  COVERAGE,
  FOOTBALL_MARKET,
  GAME_RULES,
  LEGAL_NOTICE,
  LICENSED_PARTNERS,
  MARKETPLACE_TERMS,
  MLB,
  MLB_MARKET,
  PRESS,
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS,
} from 'constants/__generated__/routes';
import {
  FOOTBALL_HOME,
  HELP,
  HELP_NEW_REQUEST,
  NBA_HOME,
  NBA_MARKET,
} from 'constants/routes';
import { useInviteLink } from 'hooks/useInviteLink';
import useEvents from 'lib/events/useEvents';
import { navLabels } from 'lib/glossary';
import { mobileAndAbove, tabletAndAbove } from 'style/mediaQuery';

const FooterLink = styled(Text16)`
  display: block;
  padding: var(--half-unit) 0;
  color: var(--c-neutral-500);
  &:hover,
  &:focus {
    color: var(--c-neutral-1000);
    text-decoration: underline;
  }
  @media ${tabletAndAbove} {
    color: var(--c-neutral-1000);
  }
`;

const MenuItems = styled.div`
  display: grid;
  gap: var(--double-unit);
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  padding: var(--triple-unit) 0;
  @media ${mobileAndAbove} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${tabletAndAbove} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const FooterLinks = () => {
  const track = useEvents();
  const trackLink = (source: string) => {
    track('Click Footer link', {
      source,
    });
  };
  const { formatMessage } = useIntl();
  const inviteLink = useInviteLink();

  return (
    <MenuItems>
      <div>
        <FooterLink
          as={Link}
          to={GAME_RULES}
          onClick={() => trackLink(GAME_RULES)}
        >
          {formatMessage(navLabels.gameRules)}
        </FooterLink>
        <FooterLink
          as={Link}
          to={TERMS_AND_CONDITIONS}
          onClick={() => trackLink(TERMS_AND_CONDITIONS)}
        >
          {formatMessage(navLabels.terms)}
        </FooterLink>
        <FooterLink
          as={Link}
          to={MARKETPLACE_TERMS}
          onClick={() => trackLink(MARKETPLACE_TERMS)}
        >
          {formatMessage(navLabels.marketplaceTerms)}
        </FooterLink>
        <FooterLink
          as={Link}
          to={PRIVACY_POLICY}
          onClick={() => trackLink(PRIVACY_POLICY)}
        >
          {formatMessage(navLabels.privacyPolicy)}
        </FooterLink>
        <FooterLink
          as={Link}
          to={COOKIE_POLICY}
          onClick={() => trackLink(COOKIE_POLICY)}
        >
          {formatMessage(navLabels.cookiePolicy)}
        </FooterLink>
        <FooterLink
          as={Link}
          to={LEGAL_NOTICE}
          onClick={() => trackLink(LEGAL_NOTICE)}
        >
          {formatMessage(navLabels.legalNotice)}
        </FooterLink>
        <FooterLink
          as={Link}
          to={FOOTBALL_HOME}
          onClick={() => trackLink(FOOTBALL_HOME)}
        >
          {formatMessage(navLabels.footballFantasy)}
        </FooterLink>
        <FooterLink as={Link} to={MLB} onClick={() => trackLink(MLB)}>
          {formatMessage(navLabels.mlbFantasy)}
        </FooterLink>
        <FooterLink as={Link} to={NBA_HOME} onClick={() => trackLink(NBA_HOME)}>
          {formatMessage(navLabels.nbaFantasy)}
        </FooterLink>
      </div>
      <div>
        <FooterLink as={Link} to={CAREERS} onClick={() => trackLink(CAREERS)}>
          {formatMessage(navLabels.careers)}
        </FooterLink>
        <FooterLink as={Link} to={PRESS} onClick={() => trackLink(PRESS)}>
          {formatMessage(navLabels.press)}
        </FooterLink>
        <FooterLink as={Link} to={BLOG} onClick={() => trackLink(BLOG)}>
          {formatMessage(navLabels.blog)}
        </FooterLink>
        <FooterLink
          as={Link}
          to={HELP_NEW_REQUEST}
          onClick={() => trackLink(HELP_NEW_REQUEST)}
        >
          {formatMessage(navLabels.contact)}
        </FooterLink>
      </div>
      <div>
        <FooterLink
          as={Link}
          to={HELP}
          onClick={() => {
            track('Click Help', {});
            trackLink(HELP);
          }}
        >
          {formatMessage(navLabels.help)}
        </FooterLink>
        <FooterLink as={Link} to={LICENSED_PARTNERS}>
          {formatMessage(navLabels.licensedPartners)}
        </FooterLink>
        <FooterLink as={Link} to={COVERAGE}>
          {formatMessage(navLabels.coverage)}
        </FooterLink>
        <FooterLink
          as={Link}
          to={FOOTBALL_MARKET}
          onClick={() => trackLink(FOOTBALL_MARKET)}
        >
          {formatMessage(navLabels.footballMarket)}
        </FooterLink>
        <FooterLink
          as={Link}
          to={MLB_MARKET}
          onClick={() => trackLink(MLB_MARKET)}
        >
          {formatMessage(navLabels.mlbMarket)}
        </FooterLink>
        <FooterLink
          as={Link}
          to={NBA_MARKET}
          onClick={() => trackLink(NBA_MARKET)}
        >
          {formatMessage(navLabels.nbaMarket)}
        </FooterLink>
      </div>
      <div>
        <FooterLink
          as={Link}
          to={AFFILIATE_PROGRAM}
          onClick={() => trackLink(AFFILIATE_PROGRAM)}
        >
          {formatMessage(navLabels.affiliateProgram)}
        </FooterLink>
        <FooterLink
          as={Link}
          to={inviteLink}
          onClick={() => trackLink(inviteLink)}
        >
          {formatMessage(navLabels.inviteFriends)}
        </FooterLink>
      </div>
    </MenuItems>
  );
};

export default FooterLinks;
