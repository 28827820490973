import styled from 'styled-components';

import { Rarity } from '__generated__/globalTypes';
import ScarcityIcon, {
  Props as ScarcityIconProps,
} from 'atoms/icons/ScarcityIcon';
import { User } from 'atoms/icons/User';
import { OverlapItems } from 'atoms/ui/OverlapItems';

const StyledOverlapItems = styled(OverlapItems)`
  align-items: initial;
`;

type Props = {
  rarities: Rarity[];
  size?: ScarcityIconProps['size'];
};

export const Scarcities = ({ rarities, size = 'md' }: Props) => {
  return (
    <StyledOverlapItems reverse offset={9}>
      {rarities.map((rarity, index) => (
        <ScarcityIcon key={rarity} scarcity={rarity} size={size}>
          {!index && <User />}
        </ScarcityIcon>
      ))}
    </StyledOverlapItems>
  );
};
