import { faAddressCard } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Button from 'atoms/buttons/Button';
import { FormControlLabel } from 'atoms/inputs/FormControlLabel';
import { LabelM } from 'atoms/typography';
import UploadInput from 'components/fiatWallet/CreateFiatWallet/DocumentCheck/UploadInput';
import { StepWrapperFromProps } from 'components/fiatWallet/CreateFiatWallet/StepWrapper';
import { FileWithDataURL } from 'components/form/UploadFile/useUploadFile';
import { glossary } from 'lib/glossary';

export type Props = {
  children: ReactNode;
  file: FileWithDataURL | undefined;
  name: string;
  setFile: (file: FileWithDataURL) => void;
  onBack: () => void;
  onNext: () => void;
};

const StyledFormControlLabel = styled(FormControlLabel)`
  > * {
    width: 100%;
  }
`;

const MIN_FILE_SIZE = 32000;
const MAX_FILE_SIZE = 7000000;

export const FileUploadStep = ({
  children,
  name,
  file,
  setFile,
  onBack,
  onNext,
}: Props) => {
  const fileSizeIsValid =
    file?.file &&
    file.file.size > MIN_FILE_SIZE &&
    file.file.size < MAX_FILE_SIZE;

  return (
    <StepWrapperFromProps
      submitButton={
        <Button size="medium" onClick={onNext} disabled={!fileSizeIsValid}>
          <FormattedMessage {...glossary.continue} />
        </Button>
      }
      onBack={onBack}
    >
      {children}
      {file?.file && !fileSizeIsValid && (
        <LabelM color="var(--c-red-600)">
          <FormattedMessage
            id="FileUploadStep.fileSize"
            defaultMessage="Your uploaded file must be more than {min} and less than {max}"
            values={{
              min: '32 KB',
              max: '7 MB',
            }}
          />
        </LabelM>
      )}
      <StyledFormControlLabel
        label={null}
        control={
          <UploadInput
            name={name}
            documentName=""
            icon={faAddressCard}
            file={file}
            setFile={setFile}
          />
        }
        labelPlacement="top"
      />
    </StepWrapperFromProps>
  );
};
