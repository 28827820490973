import { Navigate, generatePath } from 'react-router-dom';

import { useTypedParams } from '@sorare/routing';

import { NBA_MY_CLUB_SLUG_HISTORY_FIXTURE } from '@sorare/core/src/constants/__generated__/routes';

import { PageParams } from './__generated__/routeParams';

const HistoryPage = () => {
  const { slug } = useTypedParams<PageParams>();

  return (
    <Navigate
      to={generatePath(NBA_MY_CLUB_SLUG_HISTORY_FIXTURE, {
        fixture: 'live',
        slug,
      })}
      replace
    />
  );
};

export default HistoryPage;
