import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import { MonetaryAmount } from '@sorare/core/src/__generated__/globalTypes';
import Block from '@sorare/core/src/atoms/layout/Block';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import ButtonWithConfirmDialog from '@sorare/core/src/components/form/ButtonWithConfirmDialog';
import { useIsTabletAndAbove } from '@sorare/core/src/hooks/device/useIsTabletAndAbove';
import useAmountWithConversion from '@sorare/core/src/hooks/useAmountWithConversion';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';
import { breakpoints, tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import BidInfos from 'components/auction/BidInfos';
import BidField from 'components/buyActions/BidField';
import { BuyPromoBanner } from 'components/market/BuyPromoBanner';
import useBestBidBelongsToUser from 'hooks/auctions/useBestBidBelongsToUser';
import useStopAutoBid from 'hooks/auctions/useStopAutoBid';
import useTokenTakesPartPromotionalEvent from 'hooks/offers/useTokenTakesPartPromotionalEvent';

import { AuctionReminder } from '../AuctionReminder';
import { OpenAuction_auction } from './__generated__/index.graphql';

const Root = styled(Block)`
  display: flex;
  flex-direction: column;
  gap: var(--double-unit);

  @media ${tabletAndAbove} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  gap: var(--unit);

  @media (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
    justify-content: space-between;
  }
`;
const StopButton = styled(ButtonWithConfirmDialog)`
  @media (max-width: ${breakpoints.tablet}px) {
    order: 1;
  }
`;

const Column = styled(Vertical).attrs({ gap: 0.5 })``;

const messages = defineMessages({
  stopAutoBidConfirm: {
    id: 'OpenAuction.stopAutoBidConfirm',
    defaultMessage:
      'Are you sure you want to stop bidding on the auction? Your current bid of {amount} will remain but you will not auto-bid further.',
  },
});

interface Props {
  auction: OpenAuction_auction;
  showBundleContext?: boolean;
}

const Price = ({ monetaryAmount }: { monetaryAmount: MonetaryAmount }) => {
  const { main } = useAmountWithConversion({
    monetaryAmount,
  });

  return <b>{main}</b>;
};

const ConfirmMessage = ({ amounts }: { amounts: MonetaryAmount }) => {
  if (!amounts) {
    return null;
  }
  return (
    <div>
      <FormattedMessage
        {...messages.stopAutoBidConfirm}
        values={{
          amount: <Price monetaryAmount={amounts} />,
        }}
      />
    </div>
  );
};

export const OpenAuction = ({ auction, showBundleContext }: Props) => {
  const isTablet = useIsTabletAndAbove();
  const takesPartInEvent = useTokenTakesPartPromotionalEvent();
  const bestBidBelongsToUser = useBestBidBelongsToUser();
  const stopAutoBid = useStopAutoBid(auction.bestBid!);
  const {
    flags: { useEnableGoalRushFlow },
  } = useFeatureFlags();

  if (!auction) return null;

  const { autoBid, bestBid, myBestBid } = auction;

  const willAutoBid =
    autoBid &&
    bestBid &&
    myBestBid &&
    bestBidBelongsToUser(bestBid) &&
    (myBestBid.maximumAmounts.wei || 0n) > (bestBid.amounts.wei || 0n);

  const promotionalEvent = takesPartInEvent(auction.cards);

  return (
    <Root>
      <Column>
        <BidInfos
          auction={auction}
          promotionalEvent={promotionalEvent}
          withReminder={!isTablet}
          showBundleContext={showBundleContext}
        />
      </Column>
      <ButtonsContainer>
        {willAutoBid && (
          <StopButton
            color="red"
            size="medium"
            message={<ConfirmMessage amounts={bestBid?.amounts} />}
            onConfirm={() => {
              stopAutoBid();
            }}
          >
            <FormattedMessage
              id="OpenAuction.stopAutoBid"
              defaultMessage="Stop"
            />
          </StopButton>
        )}
        <BidField variant="medium" auction={auction} cards={auction.cards} />
        {isTablet && <AuctionReminder auction={auction} />}
      </ButtonsContainer>
      {auction.eligibleForBlueprintRewards && useEnableGoalRushFlow && (
        <BuyPromoBanner rounded withBgImage />
      )}
    </Root>
  );
};

OpenAuction.fragments = {
  auction: gql`
    fragment OpenAuction_auction on TokenAuction {
      id
      cancelled
      autoBid
      bestBid {
        id
        amounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
        ...useStopAutoBid_bid
        ...UseBestBidBelongsToUser_bestBid
      }
      myBestBid {
        id
        maximumAmounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
      }
      eligibleForBlueprintRewards
      ...BidField_auction
      ...BidInfos_auction
      cards: anyCards {
        slug
        ...useTokenTakesPartPromotionalEvent_anyCard
        ...BidField_anyCard
      }
      ...AuctionReminder_auction
    }
    ${monetaryAmountFragment}
    ${BidField.fragments.auction}
    ${BidInfos.fragments.auction}
    ${BidField.fragments.anyCard}
    ${useStopAutoBid.fragments.bid}
    ${useBestBidBelongsToUser.fragments.bestBid}
    ${useTokenTakesPartPromotionalEvent.fragments.anyCard}
    ${AuctionReminder.fragments.auction}
  ` as TypedDocumentNode<OpenAuction_auction>,
};

export default OpenAuction;
