import { faEthereum } from '@fortawesome/free-brands-svg-icons';
import {
  faExclamationCircle,
  faLock,
  faWallet,
} from '@fortawesome/pro-solid-svg-icons';
import { ReactNode, useCallback } from 'react';
import styled from 'styled-components';

import Button from 'atoms/buttons/Button';
import IconButton from 'atoms/buttons/IconButton';
import { FontAwesomeIcon } from 'atoms/icons';
import { ConditionalWrapper } from 'atoms/layout/ConditionalWrapper';
import { Text16 } from 'atoms/typography';
import { MUIBadge } from 'atoms/ui/MUIBadge';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useIntlContext } from 'contexts/intl';
import { useWalletDrawerContext } from 'contexts/walletDrawer';
import useWalletNeedsRecover from 'hooks/recovery/useWalletNeedsRecover';
import { useFiatBalance } from 'hooks/wallets/useFiatBalance';
import useOAuthEthWallet from 'hooks/wallets/useOAuthEthWallet';
import { minAmountToDeposit } from 'lib/ethereum';
import { RoundingMode } from 'lib/wei';

type Props = {
  compact?: boolean;
  onClick?: () => void;
};

type WalletButtonProps = {
  currentUser: NonNullable<
    ReturnType<typeof useCurrentUserContext>['currentUser']
  >;
} & Props;

const StyledBadge = styled(MUIBadge)`
  > span {
    /** overlap="circular" only work for icon buttons */
    transform: translate(25%, -25%);
  }
`;

const WalletButtons = ({
  currentUser,
  compact,
  onClick,
}: WalletButtonProps) => {
  const { formatNumber, formatWei } = useIntlContext();
  const { availableBalance, sorareAddressBalance } = currentUser;
  const {
    walletPreferences: { showEthWallet, showFiatWallet },
  } = useCurrentUserContext();

  const {
    canListAndTrade,
    availableBalance: availableFiatBalance,
    fiatCurrency,
  } = useFiatBalance();
  const { toggleDrawer } = useWalletDrawerContext();

  const openWallet = useCallback(() => {
    toggleDrawer();
    onClick?.();
  }, [onClick, toggleDrawer]);

  const emptyEthWallet = availableBalance === 0n;
  const emptyFiatWallet = !canListAndTrade || availableFiatBalance === 0;

  const showBalances =
    (showEthWallet && !emptyEthWallet) || (showFiatWallet && !emptyFiatWallet);

  if (!showBalances || currentUser.userSettings?.hideBalance) {
    return <IconButton icon={faWallet} onClick={openWallet} color="opa" />;
  }

  const renderWithLockedWalletBagde = (children: ReactNode) => (
    <ConditionalWrapper
      wrap={!!currentUser?.mangopayUser?.blocked}
      Wrapper={StyledBadge}
      props={{ badgeContent: <FontAwesomeIcon icon={faLock} /> }}
    >
      {children}
    </ConditionalWrapper>
  );

  const renderWithBadge = (children: ReactNode) => (
    <ConditionalWrapper
      wrap={sorareAddressBalance > minAmountToDeposit}
      Wrapper={MUIBadge}
      props={{ variant: 'dot' }}
    >
      {children}
    </ConditionalWrapper>
  );

  if (compact) {
    return renderWithLockedWalletBagde(
      <Button onClick={openWallet} color="opa" size="medium">
        {renderWithBadge(<FontAwesomeIcon icon={faWallet} />)}
      </Button>
    );
  }
  return (
    <>
      {showFiatWallet &&
        renderWithLockedWalletBagde(
          <Button onClick={openWallet} color="opa" size="medium">
            <Text16 bold>
              {formatNumber(availableFiatBalance, {
                style: 'currency',
                currency: fiatCurrency,
              })}
            </Text16>
          </Button>
        )}
      {showEthWallet &&
        renderWithBadge(
          <Button onClick={openWallet} color="opa" size="medium">
            <Text16 bold>
              <FontAwesomeIcon icon={faEthereum} />{' '}
              {formatWei(
                availableBalance,
                RoundingMode.ROUND_DOWN,
                { minimumFractionDigits: 3 },
                { hideSymbol: showEthWallet && showFiatWallet }
              )}
            </Text16>
          </Button>
        )}
    </>
  );
};

export const Balances = ({ compact, onClick }: Props) => {
  const { currentUser } = useCurrentUserContext();
  const walletNeedsRecover = useWalletNeedsRecover();
  const { needsCreateEthWallet } = useOAuthEthWallet();

  if (!currentUser) {
    return null;
  }

  if (
    needsCreateEthWallet ||
    walletNeedsRecover ||
    !currentUser.confirmedDevice
  )
    return (
      <StyledBadge
        badgeContent={<FontAwesomeIcon icon={faExclamationCircle} />}
      >
        <WalletButtons
          currentUser={currentUser}
          compact={compact}
          onClick={onClick}
        />
      </StyledBadge>
    );

  return (
    <WalletButtons
      currentUser={currentUser}
      compact={compact}
      onClick={onClick}
    />
  );
};
