import { useCallback, useMemo } from 'react';

import { SupportedCurrency } from '__generated__/globalTypes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { AcceptedCurrenciesValue } from 'hooks/useAcceptedCurrencies';
import { useCurrentUserAcceptedCurrencies } from 'hooks/useCurrentUserAcceptedCurrencies';
import useLifecycle, { LIFECYCLE, Lifecycle } from 'hooks/useLifecycle';

export const useReferenceCurrencyForSale = () => {
  const {
    fiatCurrency: { code },
    currentUser,
    walletPreferences: { onlyShowFiatCurrency, enabledWallets },
  } = useCurrentUserContext();

  const { acceptedCurrencies } = useCurrentUserAcceptedCurrencies();
  const { update: storeReferenceCurrencyForSale, loading } = useLifecycle();
  const lifecycle = currentUser?.userSettings?.lifecycle as Lifecycle;

  const update = useCallback(
    (value: SupportedCurrency) => {
      storeReferenceCurrencyForSale(LIFECYCLE.referenceCurrencyForSale, value);
    },
    [storeReferenceCurrencyForSale]
  );
  const referenceCurrencyForSale = useMemo(() => {
    if (!enabledWallets) return SupportedCurrency.WEI;
    if (onlyShowFiatCurrency) return code as SupportedCurrency;
    switch (acceptedCurrencies) {
      case AcceptedCurrenciesValue.FIAT:
        return code as SupportedCurrency;
      case AcceptedCurrenciesValue.ETH:
        return SupportedCurrency.WEI;
      case AcceptedCurrenciesValue.BOTH:
        return (
          lifecycle?.referenceCurrencyForSale || (code as SupportedCurrency)
        );
      default:
    }
    return code as SupportedCurrency;
  }, [
    enabledWallets,
    onlyShowFiatCurrency,
    code,
    acceptedCurrencies,
    lifecycle?.referenceCurrencyForSale,
  ]);

  return {
    update,
    loading,
    referenceCurrencyForSale,
  };
};
