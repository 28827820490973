import { TypedDocumentNode, gql } from '@apollo/client';
import { ComponentProps, useState } from 'react';
import styled from 'styled-components';

import Dialog from 'components/dialog';
import UpdateProfile from 'components/settings/UpdateProfile';
import ActivityIndicator from 'components/user/ActivityIndicator';
import Avatar from 'components/user/Avatar';
import { useCurrentUserContext } from 'contexts/currentUser';

import { ActiveUserAvatar_user } from './__generated__/index.graphql';

type Props = {
  user: ActiveUserAvatar_user;
  variant: 'extraSmall' | 'small' | 'medium' | 'large';
  editable?: boolean;
} & Pick<
  ComponentProps<typeof Avatar>,
  'rounded' | 'largePictureUrl' | 'placeholderUrl'
>;

const Wrapper = styled.div`
  padding: 0 2px 2px 0;
`;
const UpdateProfileWrapper = styled.div`
  padding: var(--double-unit);
`;
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: var(--double-unit);
  right: var(--double-unit);
`;

const extraSmallActivityIndicatorStyle = {
  '--radius': '3px',
  // harcoded value to work both on mobile where font-size is 12 and desktop where it's 14
  '--offset-right': 'calc(1em - 12px)',
  '--offset-bottom': 'calc(1em - 9px)',
  '--gap': '1px',
} as React.CSSProperties;

const roundedCSStyle = {
  '--offset-right': '6px',
  '--offset-bottom': '6px',
} as React.CSSProperties;

const ActiveUserAvatar = ({
  user,
  variant,
  editable = true,
  rounded,
  ...avatarProps
}: Props) => {
  const { currentUser } = useCurrentUserContext() || {};
  const [openUpdatingPicture, setOpenUpdatingPicture] = useState(false);

  const otherUser = currentUser?.slug !== user.slug;

  // disable edit mode on those extraSmall avatars
  const withoutEdit = !editable || variant === 'extraSmall';

  // only support mobile click when we're not opening the edit modal
  const disableClick = !(withoutEdit || otherUser);

  const roundedStyle = rounded ? roundedCSStyle : undefined;

  const avatar = (
    <Wrapper>
      <ActivityIndicator
        user={user}
        disableClick={disableClick}
        style={
          variant === 'extraSmall'
            ? extraSmallActivityIndicatorStyle
            : roundedStyle
        }
      >
        <Avatar
          user={user}
          variant={variant}
          rounded={rounded}
          {...avatarProps}
        />
      </ActivityIndicator>
    </Wrapper>
  );

  if (otherUser || withoutEdit) {
    return avatar;
  }

  return (
    <>
      <button type="button" onClick={() => setOpenUpdatingPicture(true)}>
        {avatar}
      </button>
      <Dialog
        maxWidth="sm"
        open={openUpdatingPicture}
        onClose={() => setOpenUpdatingPicture(false)}
        hideHeader
      >
        {({ CloseButton }) => (
          <UpdateProfileWrapper>
            <CloseButtonWrapper>
              <CloseButton onClose={() => setOpenUpdatingPicture(false)} />
            </CloseButtonWrapper>
            <UpdateProfile onSuccess={() => setOpenUpdatingPicture(false)} />
          </UpdateProfileWrapper>
        )}
      </Dialog>
    </>
  );
};

ActiveUserAvatar.fragments = {
  user: gql`
    fragment ActiveUserAvatar_user on PublicUserInfoInterface {
      slug
      ...ActivityIndicator_user
      ...Avatar_publicUserInfoInterface
    }
    ${ActivityIndicator.fragments.user}
    ${Avatar.fragments.publicUserInfoInterface}
  ` as TypedDocumentNode<ActiveUserAvatar_user>,
};

export default ActiveUserAvatar;
