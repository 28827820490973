import { faExchange } from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowDownLeft,
  faArrowUpRight,
  faBug,
  faCartShopping,
  faCheck,
  faGavel,
  faGear,
  faQuestionCircle,
  faSignOut,
  faUserPlus,
  faVideo,
} from '@fortawesome/pro-solid-svg-icons';
import { Drawer as MuiDrawer } from '@material-ui/core';
import { forwardRef, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import { Sport } from '__generated__/globalTypes';
import mlbUrl from 'assets/logos/mlb/mlb.svg';
import nbaUrl from 'assets/logos/nba/nba.svg';
import { Badge } from 'atoms/badges/Badge';
import ButtonBase, { ButtonProps } from 'atoms/buttons/ButtonBase';
import CloseButton from 'atoms/buttons/CloseButton';
import NavLink from 'atoms/buttons/NavLink';
import { Dropdown } from 'atoms/dropdowns/Dropdown';
import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { Caption, HeadlineXS, LabelM, Title3 } from 'atoms/typography';
import { CoinsAndBalances } from 'components/navigation/AppHeader/CoinsAndBalances';
import ActivityIndicator from 'components/user/ActivityIndicator';
import Avatar from 'components/user/Avatar';
import { ClubShield } from 'components/user/ClubShield';
import {
  CONTENT_CREATORS,
  MLB,
  SETTINGS,
} from 'constants/__generated__/routes';
import {
  FOOTBALL_HOME,
  HELP,
  MY_SORARE_AUCTIONS,
  MY_SORARE_HOME,
  MY_SORARE_OFFERS_RECEIVED,
  MY_SORARE_OFFERS_SENT,
  MY_SORARE_SALES,
  NBA_HOME,
} from 'constants/routes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useWalletContext } from 'contexts/wallet';
import { useIsDesktopAndAbove } from 'hooks/device/useIsDesktopAndAbove';
import useScreenSize from 'hooks/device/useScreenSize';
import { useHasUnclaimedRefereeRewardsAsReferee } from 'hooks/referral/useHasUnclaimedRefereeRewardsAsReferee';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { useInviteLink } from 'hooks/useInviteLink';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import { useLocationChanged } from 'hooks/useLocationChanged';
import { glossary, navLabels } from 'lib/glossary';
import { FootballLogo } from 'routing/MultiSportAppBar/Sport/Football';
import { useAppBarContext } from 'routing/MultiSportAppBar/context';
import { tabletAndAbove } from 'style/mediaQuery';
import { OverrideClasses } from 'style/utils';

const Switch = styled(ButtonBase).attrs({ type: 'button' })`
  display: flex;
  align-items: center;
  gap: var(--unit);
  position: relative;

  height: calc(5 * var(--unit));
  ${HeadlineXS} {
    display: none;
  }

  --sport-image-size: 5;

  @media ${tabletAndAbove} {
    border: 1px solid var(--c-neutral-150);
    border-radius: var(--double-and-a-half-unit);
    padding: var(--half-unit) var(--half-unit) var(--half-unit)
      var(--double-unit);
    --sport-image-size: 4;
    ${HeadlineXS} {
      display: block;
    }
  }

  &:hover,
  &.open {
    background: var(--c-neutral-200);
  }
`;
const Logo = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
`;

const logos = {
  [Sport.FOOTBALL]: <FootballLogo />,
  [Sport.NBA]: <Logo src={nbaUrl} />,
  [Sport.BASEBALL]: <Logo src={mlbUrl} />,
};

const sportsName = {
  [Sport.FOOTBALL]: 'Football',
  [Sport.NBA]: 'NBA',
  [Sport.BASEBALL]: 'MLB',
};

const SportImage = styled(Horizontal).attrs({ gap: 0, center: true })`
  position: relative;
  background: var(--c-brand-600);
  border-radius: 50%;
  width: calc(var(--sport-image-size) * var(--unit));
  height: calc(var(--sport-image-size) * var(--unit));
  > *:first-child {
    width: calc((var(--sport-image-size) - 1) * var(--unit));
    height: calc((var(--sport-image-size) - 1) * var(--unit));
  }
  > * + * {
    position: absolute;
    right: 0;
    bottom: 0;
    width: var(--double-unit);
    height: var(--double-unit);
  }
`;

const MenuSection = styled(Vertical)`
  padding: var(--unit);
  & + & {
    border-top: 1px solid var(--c-neutral-500);
  }
  &:first-child {
    margin: var(--double-unit) 0;
  }
`;
const Content = styled.div`
  background: var(--c-neutral-200);
  height: 100%;
  @media ${tabletAndAbove} {
    min-width: 375px;
    width: min-content;
  }
`;

const MenuItem = styled(Horizontal).attrs({ gap: 2 })`
  color: var(--c-neutral-1000);
  white-space: nowrap;
  padding: var(--intermediate-unit) var(--intermediate-unit);
  border-radius: var(--intermediate-unit);
  &:hover,
  &.active {
    color: inherit;
    background: var(--c-neutral-300);
  }
  & > svg {
    width: calc(5 * var(--unit));
    height: var(--double-and-a-half-unit);
  }
`;
const CheckIcon = styled(FontAwesomeIcon).attrs({
  size: 'xl',
  color: 'var(--c-green-600)',
})`
  margin-left: auto;
  opacity: 0;
`;

const SportButton = styled(NavLink)`
  display: flex;
  width: 100%;
  gap: var(--double-unit);
  align-items: center;
  border-radius: var(--intermediate-unit);
  padding: var(--intermediate-unit);
  &.active {
    background: var(--c-neutral-300);
    ${CheckIcon} {
      opacity: 1;
    }
  }
  &:hover {
    color: inherit;
    background: var(--c-neutral-300);
  }
`;

const LogoutButton = styled(ButtonBase)`
  justify-content: flex-start;
`;

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  right: var(--unit);
  top: var(--unit);
`;

const Title = styled(Title3)`
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
`;

const ClubName = ({
  clubName,
  shieldUrl,
}: {
  clubName?: string | null;
  shieldUrl?: string | null;
}) => {
  return (
    <Horizontal gap={0.5}>
      {shieldUrl && (
        <ClubShield size={2} clubName={clubName} shieldUrl={shieldUrl} />
      )}
      <Caption color="var(--c-neutral-700)">{clubName}</Caption>
    </Horizontal>
  );
};

const MenuContent = ({ onClose }: { onClose: () => void }) => {
  const { up: isTabletOrDesktop } = useScreenSize('tablet');
  const isDesktopAndAbove = useIsDesktopAndAbove();
  const { currentUser } = useCurrentUserContext();
  const walletContext = useWalletContext();
  const { isAndroidApp, postMessage } = useIsMobileApp();
  const hasUnclaimedReferralReward = useHasUnclaimedRefereeRewardsAsReferee();
  const {
    flags: { isSorareInternal = false, useContentCreatorTool = false },
  } = useFeatureFlags();

  const inviteLink = useInviteLink();

  if (!currentUser) {
    return null;
  }

  return (
    <Content>
      {!isTabletOrDesktop && <StyledCloseButton onClose={onClose} />}
      <Vertical gap={0}>
        <MenuSection center>
          <ActivityIndicator
            user={currentUser}
            disableClick
            style={
              {
                '--offset': '4px',
              } as React.CSSProperties
            }
          >
            <Avatar user={currentUser} rounded size={6} />
          </ActivityIndicator>
          <Title>{currentUser.nickname}</Title>
          {!isDesktopAndAbove && (
            <Horizontal gap={0}>
              <CoinsAndBalances compact={false} onClick={onClose} />
            </Horizontal>
          )}
        </MenuSection>

        <MenuSection gap={0.5}>
          <SportButton to={FOOTBALL_HOME} onClick={onClose}>
            <FootballLogo width={40} height={40} />
            <div>
              <HeadlineXS as="div">Football</HeadlineXS>
              {currentUser.footballUserProfile?.clubName && (
                <ClubName
                  clubName={currentUser.footballUserProfile?.clubName}
                  shieldUrl={
                    currentUser.footballUserProfile?.clubBadge?.pictureUrl
                  }
                />
              )}
            </div>
            <CheckIcon icon={faCheck} />
          </SportButton>
          <SportButton to={NBA_HOME} onClick={onClose}>
            <Logo src={nbaUrl} width={40} height={40} />
            <div>
              <HeadlineXS as="div">NBA</HeadlineXS>
              {currentUser.nbaUserProfile?.clubName && (
                <ClubName
                  clubName={currentUser.nbaUserProfile?.clubName}
                  shieldUrl={currentUser.nbaUserProfile?.shieldUrl}
                />
              )}
            </div>
            <CheckIcon icon={faCheck} />
          </SportButton>
          <SportButton to={MLB} onClick={onClose}>
            <Logo src={mlbUrl} width={40} height={40} />
            <div>
              <HeadlineXS as="div">MLB</HeadlineXS>
              {currentUser.baseballUserProfile?.clubName && (
                <ClubName
                  clubName={currentUser.baseballUserProfile?.clubName}
                  shieldUrl={currentUser.baseballUserProfile?.shieldUrl}
                />
              )}
            </div>
            <CheckIcon icon={faCheck} />
          </SportButton>
        </MenuSection>

        <MenuSection gap={0.5}>
          <MenuItem as={NavLink} to={MY_SORARE_HOME} onClick={onClose}>
            <FontAwesomeIcon icon={faExchange} width={40} />
            <LabelM as="span">
              <FormattedMessage {...navLabels.mySorare} />
            </LabelM>
          </MenuItem>
          <MenuItem as={NavLink} to={MY_SORARE_AUCTIONS} onClick={onClose}>
            <FontAwesomeIcon icon={faGavel} width={40} />
            <LabelM as="span">
              <FormattedMessage {...navLabels.myAuctions} />
            </LabelM>
          </MenuItem>
          <MenuItem as={NavLink} to={MY_SORARE_SALES} onClick={onClose}>
            <FontAwesomeIcon width={40} icon={faCartShopping} />
            <LabelM as="span">
              <FormattedMessage {...navLabels.myListings} />
            </LabelM>
          </MenuItem>
          <MenuItem
            as={NavLink}
            to={MY_SORARE_OFFERS_RECEIVED}
            onClick={onClose}
          >
            <FontAwesomeIcon width={40} icon={faArrowDownLeft} />
            <LabelM as="span">
              <FormattedMessage {...navLabels.myOffersReceived} />
            </LabelM>
          </MenuItem>
          <MenuItem as={NavLink} to={MY_SORARE_OFFERS_SENT} onClick={onClose}>
            <FontAwesomeIcon width={40} icon={faArrowUpRight} />
            <LabelM as="span">
              <FormattedMessage {...navLabels.myOffersSent} />
            </LabelM>
          </MenuItem>
        </MenuSection>

        <MenuSection gap={0.5}>
          <MenuItem as={NavLink} to={SETTINGS}>
            <FontAwesomeIcon icon={faGear} />
            <LabelM as="span">
              <FormattedMessage {...navLabels.settings} />
            </LabelM>
          </MenuItem>
          {isAndroidApp && isSorareInternal && (
            <MenuItem
              as="button"
              onClick={() => {
                postMessage('debugScreen', {});
                onClose();
              }}
            >
              <FontAwesomeIcon icon={faBug} />
              <LabelM as="span">
                <FormattedMessage {...navLabels.mobileDebugScreen} />
              </LabelM>
            </MenuItem>
          )}
          {useContentCreatorTool && (
            <MenuItem as={NavLink} to={CONTENT_CREATORS} target="_blank">
              <FontAwesomeIcon icon={faVideo} />
              <LabelM as="span">
                <FormattedMessage {...navLabels.contentCreatorTool} />
              </LabelM>
            </MenuItem>
          )}
          <MenuItem as={NavLink} to={inviteLink} onClick={onClose}>
            <FontAwesomeIcon width={40} icon={faUserPlus} />
            <LabelM as="span">
              <FormattedMessage {...navLabels.inviteFriends} />
            </LabelM>
            {hasUnclaimedReferralReward && <Badge variant="red">1</Badge>}
          </MenuItem>
          <MenuItem as={NavLink} to={HELP}>
            <FontAwesomeIcon width={40} icon={faQuestionCircle} />
            <LabelM as="span">
              <FormattedMessage {...navLabels.help} />
            </LabelM>
          </MenuItem>
        </MenuSection>

        <MenuSection gap={0.5}>
          <MenuItem
            as={LogoutButton}
            onClick={() => {
              // this is a promise, we can't return it in an onClick
              walletContext.logOut();
            }}
          >
            <FontAwesomeIcon width={40} icon={faSignOut} />
            <LabelM as="span">
              <FormattedMessage {...glossary.logOut} />
            </LabelM>
          </MenuItem>
        </MenuSection>
      </Vertical>
    </Content>
  );
};

const SwitchButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, ref) => {
    const { currentUser } = useCurrentUserContext();
    const { sport = Sport.FOOTBALL } = useAppBarContext();

    return (
      <Switch ref={ref} {...props} title={sport}>
        <HeadlineXS as="div">{sportsName[sport]}</HeadlineXS>
        <SportImage>
          {logos[sport]}
          <Avatar user={currentUser!} rounded />
        </SportImage>
      </Switch>
    );
  }
);

SwitchButton.displayName = 'SwitchButton';

const [Drawer, classes] = OverrideClasses(MuiDrawer, null, {
  paper: css`
    width: 100%;
    background-color: var(--c-neutral-200);
  `,
});

export const MasterSwitch = () => {
  const { up: isTabletOrDesktop } = useScreenSize('tablet');
  const { currentUser } = useCurrentUserContext();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const locationChanged = useLocationChanged();

  useEffect(() => {
    if (locationChanged && drawerOpen) {
      setDrawerOpen(false);
    }
  }, [locationChanged, drawerOpen]);

  if (!currentUser) {
    return null;
  }

  if (isTabletOrDesktop) {
    return (
      <Dropdown gap={8} label={props => <SwitchButton {...props} />}>
        {({ closeDropdown }) => {
          return <MenuContent onClose={closeDropdown} />;
        }}
      </Dropdown>
    );
  }

  return (
    <>
      <SwitchButton
        onClick={() => {
          setDrawerOpen(true);
        }}
      />
      <Drawer
        anchor="right"
        classes={{
          paper: classes.paper,
        }}
        open={drawerOpen}
      >
        <MenuContent
          onClose={() => {
            setDrawerOpen(false);
          }}
        />
      </Drawer>
    </>
  );
};
