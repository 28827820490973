import styled from 'styled-components';

import { PLAYER_PLACEHOLDER } from 'constants/assets';

const AvatarContainer = styled.div`
  width: calc(4 * var(--unit));
  aspect-ratio: 1/1;
  border-radius: calc(5 * var(--unit));
  background-color: var(--c-neutral-300);
  overflow: hidden;
  text-align: center;
`;
const Img = styled.img`
  height: 100%;
`;

type Props = Pick<React.ImgHTMLAttributes<HTMLImageElement>, 'alt'> & {
  src?: string | null;
};

export const PlayerAvatar = ({ src, alt }: Props) => (
  <AvatarContainer>
    <Img src={src || PLAYER_PLACEHOLDER} alt={alt} />
  </AvatarContainer>
);
