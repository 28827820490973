import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Rarity } from '@sorare/core/src/__generated__/globalTypes';
import IconButton from '@sorare/core/src/atoms/buttons/IconButton';
import ScarcityIcon from '@sorare/core/src/atoms/icons/ScarcityIcon';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { Caption, LabelM } from '@sorare/core/src/atoms/typography';
import { glossary } from '@sorare/core/src/lib/glossary';
import { mobileAndAbove } from '@sorare/core/src/style/mediaQuery';

import bgImg from './assets/bg.jpg';

type Props = {
  rounded?: boolean;
  withBgImage?: boolean;
};

const Wrapper = styled.div`
  width: 100%;
  background: linear-gradient(
    84.1deg,
    #f8d3da 0%,
    #b3a9f4 28.32%,
    #fbe9fb 54.01%,
    #4f94fd 100%
  );
  padding: 1px;
  &.rounded {
    border-radius: var(--unit);
  }
`;

const Root = styled(Horizontal).attrs({ center: true, gap: 1 })`
  width: 100%;
  color: var(--c-neutral-100);
  padding: 2px 4px;

  &.rounded {
    border-radius: var(--unit);
  }

  &.withBgImage {
    background-image: url(${bgImg});
    background-position: center;
    background-size: cover;
    color: var(--c-neutral-1000);
  }

  @media ${mobileAndAbove} {
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    grid-template-areas: 'gutter content button';
  }
`;

const Content = styled(Horizontal).attrs({ center: true, gap: 1 })`
  grid-area: content;
`;

const ButtonContainer = styled.div`
  color: var(--c-neutral-1000);
  grid-area: button;
`;

const Text = styled(Horizontal).attrs({ gap: 0 })`
  flex-wrap: wrap;
  column-gap: var(--unit);
`;

const Terms = styled(Caption)`
  text-decoration: underline;
  color: inherit;
`;

export const BuyPromoBanner = ({ rounded, withBgImage }: Props) => {
  return (
    <Wrapper className={classNames({ rounded })}>
      <Root className={classNames({ withBgImage, rounded })}>
        <Content>
          <ScarcityIcon size="md" scarcity={Rarity.limited} />
          <Text>
            <LabelM bold>
              <FormattedMessage
                id="FirstEditionBanner.winTicketsForCardsV2"
                defaultMessage="Win raffle tickets for Cards"
              />
            </LabelM>
            <Terms
              rel="noopener noreferrer"
              target="_blank"
              href="https://sorare.com/blog/international-break-sweepstakes-the-sweepstakes"
              as="a"
            >
              (<FormattedMessage {...glossary.termsApply} />)
            </Terms>
          </Text>
        </Content>
        <ButtonContainer>
          <IconButton
            to="https://sorare.com/blog/discover-our-goal-rush-market-event"
            externalLink
            small
            icon={faInfoCircle}
            color="transparent"
          />
        </ButtonContainer>
      </Root>
    </Wrapper>
  );
};
