import { TypedDocumentNode, gql } from '@apollo/client';
import { faAngleDown, faClock } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { SeasonEligibility } from '@sorare/core/src/__generated__/globalTypes';
import ButtonBase from '@sorare/core/src/atoms/buttons/ButtonBase';
import { FullViewportScroll } from '@sorare/core/src/atoms/container/FullViewportScroll';
import { Dropdown } from '@sorare/core/src/atoms/dropdowns/Dropdown';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal, SBHorizontal } from '@sorare/core/src/atoms/layout/flex';
import { LabelS } from '@sorare/core/src/atoms/typography';
import Avatar from '@sorare/core/src/components/user/Avatar';
import TimeLeft from '@sorare/core/src/contexts/ticker/TimeLeft';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';
import useEvents from '@sorare/core/src/lib/events/useEvents';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';
import { scarcityMessages } from '@sorare/core/src/lib/scarcity';
import { format as formatSeason } from '@sorare/core/src/lib/seasons';
import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';

import PriceHistoryFromProps from 'components/price/PriceHistoryFromProps';
import { AuctionOwnershipDetails } from 'components/token/Ownership/AuctionOwnershipDetails';

import { PaymentBoxAmountWithConversion } from '../../AmountWithConversion';
import { AuctionMetas_auction } from './__generated__/index.graphql';

interface Props {
  auction: AuctionMetas_auction;
}

const Root = styled(SBHorizontal)`
  width: 100%;

  @media ${laptopAndAbove} {
    flex-direction: row-reverse;
  }
`;

const Dropdowns = styled(Horizontal)`
  align-items: stretch;
  flex-shrink: 0;
`;

const DropdownLabel = styled(ButtonBase)`
  display: flex;
  align-items: center;
  gap: var(--half-unit);
  padding: var(--half-unit) var(--unit);
  border-radius: var(--double-unit);
  border: 1px solid rgba(255, 255, 255, 0.17);
  backdrop-filter: blur(16px);
`;

const DropdownHeader = styled.div`
  border-radius: var(--unit);
  padding: var(--half-unit) var(--unit);
  background: rgba(255, 255, 255, 0.07);
  margin-bottom: var(--unit);
  text-align: center;
`;

const DropdownContent = styled.div`
  max-height: 500px;
  border-radius: var(--double-unit);
  background: var(--c-neutral-300);
  border: 1px solid var(--c-neutral-150);
`;

const DropdownContentWithPadding = styled(DropdownContent)`
  padding: var(--intermediate-unit);
`;

const Date = styled(Horizontal)`
  border-radius: var(--double-unit);
  padding: var(--unit) var(--unit);
  background: var(--surface-primary-nd-50, rgba(255, 255, 255, 0.07));
  backdrop-filter: blur(16px);
`;

const BoldLabelS = styled(LabelS).attrs({ bold: true })``;

const AuctionMetas = (props: Props) => {
  const { auction } = props;
  const track = useEvents();
  const {
    flags: { useAuctionRevamp = false },
  } = useFeatureFlags();

  if (!useAuctionRevamp) return null;

  const { bidsCount, bestBid, anyCards } = auction;

  return (
    <FullViewportScroll>
      <Root>
        <Date>
          <FontAwesomeIcon
            color="var(--c-neutral-600)"
            size="xs"
            icon={faClock}
          />
          <LabelS bold>
            <TimeLeft time={auction.endDate} />
          </LabelS>
        </Date>
        <Dropdowns>
          <Dropdown
            onChange={() => {}}
            label={dropdownProps => (
              <DropdownLabel {...dropdownProps} disabled={bidsCount === 0}>
                <LabelS bold>
                  <FormattedMessage
                    id="AuctionMetas.bidsCount"
                    defaultMessage="{bidsCount} {bidsCount, plural, one {bid} other {bids}}"
                    values={{
                      bidsCount,
                    }}
                  />
                </LabelS>
                {bestBid && (
                  <>
                    {' · '}
                    {bestBid.bidder && (
                      <Avatar rounded size={2} user={bestBid.bidder} />
                    )}
                    <PaymentBoxAmountWithConversion
                      monetaryAmount={bestBid.amounts}
                      hideExponent
                      MainAmountComponent={BoldLabelS}
                    />
                    <FontAwesomeIcon size="xs" icon={faAngleDown} />
                  </>
                )}
              </DropdownLabel>
            )}
            triggerOnHover
          >
            <DropdownContent>
              <AuctionOwnershipDetails auction={auction} skipFirst={false} />
            </DropdownContent>
          </Dropdown>
          {anyCards.length === 1 && (
            <Dropdown
              onChange={() => {}}
              onOpen={() => {
                track('Open Recent Sales', {
                  context: 'Payment Box',
                });
              }}
              triggerOnHover
              label={dropdownProps => (
                <DropdownLabel {...dropdownProps}>
                  <LabelS bold>
                    <FormattedMessage
                      id="AuctionMetas.recentSales"
                      defaultMessage="Recent sales"
                      values={{
                        bidsCount,
                      }}
                    />
                  </LabelS>
                  <FontAwesomeIcon size="xs" icon={faAngleDown} />
                </DropdownLabel>
              )}
            >
              <DropdownContentWithPadding>
                <DropdownHeader>
                  <LabelS color="var(--c-neutral-600)">
                    {formatSeason(anyCards[0].seasonYear)} -{' '}
                    <FormattedMessage
                      {...scarcityMessages[anyCards[0].rarityTyped]}
                    />
                  </LabelS>
                </DropdownHeader>
                <PriceHistoryFromProps
                  variables={{
                    playerSlug: anyCards[0].anyPlayer.slug,
                    rarity: anyCards[0].rarityTyped,
                    seasonEligibility: anyCards[0].inSeasonEligible
                      ? SeasonEligibility.IN_SEASON
                      : SeasonEligibility.CLASSIC,
                  }}
                  count={5}
                  inBlock={false}
                />
              </DropdownContentWithPadding>
            </Dropdown>
          )}
        </Dropdowns>
      </Root>
    </FullViewportScroll>
  );
};

AuctionMetas.fragments = {
  auction: gql`
    fragment AuctionMetas_auction on TokenAuction {
      id
      bidsCount
      endDate
      anyCards {
        slug
        rarityTyped
        inSeasonEligible
        seasonYear
        anyPlayer {
          slug
        }
      }
      bestBid {
        id
        bidder {
          ... on User {
            slug
            status
            ...Avatar_user
          }
          ... on AnonymousUser {
            id
            ...Avatar_anonymousUser
          }
        }
        amounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
      }
    }
    ${Avatar.fragments.user}
    ${Avatar.fragments.anonymousUser}
    ${monetaryAmountFragment}
  ` as TypedDocumentNode<AuctionMetas_auction>,
};

export default AuctionMetas;
