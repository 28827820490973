import { PropsWithChildren, forwardRef } from 'react';
import styled, { css } from 'styled-components';

import ButtonBase, {
  ButtonProps,
} from '@sorare/core/src/atoms/buttons/ButtonBase';
import { ChevronDownBold } from '@sorare/core/src/atoms/icons/ChevronDownBold';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';

export const PaymentBoxBlockV2 = styled(Vertical)<{ fullWidth?: boolean }>`
  ${({ fullWidth }) =>
    !fullWidth
      ? css`
          border: solid 1px var(--c-neutral-400);
          padding: var(--intermediate-unit);
          border-radius: var(--double-unit);
        `
      : ''}
`;

export const PaymentBoxBlock = styled(PaymentBoxBlockV2).attrs({ gap: 2 })`
  padding: var(--double-unit);
`;

const Header = styled(ButtonBase)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Reverse = styled.div<{ enable?: boolean }>`
  transition: 0.3s;
  transform: ${({ enable }) => (enable ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

type Props = {
  withArrow?: boolean;
  reverseArrow?: boolean;
  onToggle?: () => void;
} & ButtonProps;

export const PaymentBoxBlockHeader = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<Props>
>(({ children, withArrow, reverseArrow, onToggle, ...buttonProps }, ref) => {
  if (!withArrow) {
    return <div>{children}</div>;
  }

  return (
    <Header
      {...buttonProps}
      ref={ref}
      color="transparent"
      onClick={e => {
        buttonProps.onClick?.(e);
        onToggle?.();
      }}
      disableDebounce
    >
      {children}
      <Reverse enable={reverseArrow}>
        <ChevronDownBold color="var(--c-brand-600)" />
      </Reverse>
    </Header>
  );
});

PaymentBoxBlockHeader.displayName = 'PaymentBoxBlockHeader';
