import { Suspense } from 'react';

import { AppLayout } from '@sorare/core/src/components/navigation/AppLayout';
import { lazy } from '@sorare/core/src/lib/retry';

const FootballLanding = lazy(
  async () => import('@sorare/football/src/pages/page')
);

const Landing = () => {
  return (
    <AppLayout>
      <Suspense>
        <FootballLanding isRoot />
      </Suspense>
    </AppLayout>
  );
};

export default Landing;
