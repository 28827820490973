import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

import {
  Currency,
  SupportedCurrency,
  TokenPaymentMethod,
} from '@sorare/core/src/__generated__/globalTypes';
import { Title5 } from '@sorare/core/src/atoms/typography';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';
import { MonetaryAmountOutput } from '@sorare/core/src/hooks/useMonetaryAmount';
import { isType } from '@sorare/core/src/lib/gql';
import { getMonetaryAmountIndex } from '@sorare/core/src/lib/monetaryAmount';

import LazyPaymentProvider from 'components/buyActions/LazyPaymentProvider';
import CardOverview from 'components/buyActions/PaymentBox/CardOverview';
import { fragments as paymentProviderFragments } from 'components/buyActions/PaymentProvider/fragments';
import { BuyPromoBanner } from 'components/market/BuyPromoBanner';
import useBestBidBelongsToUser from 'hooks/auctions/useBestBidBelongsToUser';
import useBidWithWallet, {
  ResultingBid,
} from 'hooks/auctions/useBidWithWallet';
import { auctionMinNextBid } from 'lib/auctions';

import {
  BidPaymentModal_anyCard,
  BidPaymentModal_auction,
} from './__generated__/index.graphql';
import usePollAuctionBestBid from './usePollAuctionBestBid';

export const messages = defineMessages({
  bid: { id: 'BidField.bid', defaultMessage: 'Bid' },
  confirmBid: { id: 'BidField.confirmBid', defaultMessage: 'Confirm bid' },
  increaseBid: { id: 'BidField.increaseBid', defaultMessage: 'Increase bid' },
  autoBid: { id: 'BidPaymentModal.autoBid', defaultMessage: 'Set max bid' },
});

type Props = {
  auction: BidPaymentModal_auction;
  cards: BidPaymentModal_anyCard[];
  onSuccess: () => void;
  onClose: () => void;
};

const BidPaymentModal = ({ auction, cards, onSuccess, onClose }: Props) => {
  const {
    flags: {
      useEnableGoalRushFlow,
      useAuctionRevamp = false,
      usePaypalForAuctions = false,
    },
  } = useFeatureFlags();
  const [polling, setPolling] = useState(false);
  const [outBidByAutoBid, setOutBidByAutoBid] = useState(false);
  const [outBidCallback, setOutBidCallback] =
    useState<(amount: string) => void>();

  const [fiatPaymentMonetaryAmount, setFiatPaymentMonetaryAmount] =
    useState<MonetaryAmountOutput | null>(null);
  const [timeoutPolling, setTimeoutPolling] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  const {
    walletPreferences: { showEthWallet },
  } = useCurrentUserContext();

  const doesBestBidBelongsToUser = useBestBidBelongsToUser();
  const bidWithWallet = useBidWithWallet(auction);

  const onPollingEnd = (winning: boolean) => {
    setPolling(false);
    if (timeoutPolling) clearTimeout(timeoutPolling);

    if (!winning) {
      setOutBidByAutoBid(true);
      if (outBidCallback) outBidCallback(auction.minNextBid);
    } else {
      onSuccess();
    }
  };

  usePollAuctionBestBid(
    polling,
    auction,
    fiatPaymentMonetaryAmount,
    onPollingEnd
  );

  const onSuccessWithFiat = useCallback(
    (monetaryAmount?: MonetaryAmountOutput) => {
      if (auction.autoBid && monetaryAmount) {
        setFiatPaymentMonetaryAmount(monetaryAmount);
        setPolling(true);
        setTimeoutPolling(
          setTimeout(() => {
            setPolling(false);
            onSuccess();
          }, 10000)
        );
      } else {
        onSuccess();
      }
    },
    [auction, onSuccess]
  );

  const onSuccessWithWallet = useCallback(
    (updatedAuction: NonNullable<ResultingBid>['auction']) => {
      if (auction.autoBid) {
        const hasBeenOutBid =
          updatedAuction.bestBid &&
          !doesBestBidBelongsToUser(updatedAuction.bestBid);
        if (hasBeenOutBid) {
          setOutBidByAutoBid(true);
          if (outBidCallback) outBidCallback(updatedAuction.minNextBid);
          return;
        }
      }
      onSuccess();
    },
    [auction, outBidCallback, onSuccess, doesBestBidBelongsToUser]
  );

  const bid = useCallback(
    async ({
      supportedCurrency,
      monetaryAmount,
      conversionCreditId,
      tokenPaymentMethod,
    }: {
      supportedCurrency: SupportedCurrency;
      monetaryAmount: MonetaryAmountOutput;
      conversionCreditId?: string;
      tokenPaymentMethod?: TokenPaymentMethod;
    }) => {
      const monetaryAmountIndex = getMonetaryAmountIndex(supportedCurrency);
      const newBid = await bidWithWallet({
        supportedCurrency,
        amount: monetaryAmount[monetaryAmountIndex].toString(),
        conversionCreditId,
        tokenPaymentMethod,
      });
      if (newBid && '__typename' in newBid && isType(newBid, 'TokenBid')) {
        onSuccessWithWallet(newBid?.auction);
      }
    },
    [bidWithWallet, onSuccessWithWallet]
  );

  const minNextBid = auctionMinNextBid(auction);

  const confirmMessage =
    auction?.bestBid && doesBestBidBelongsToUser(auction?.bestBid)
      ? messages.increaseBid
      : messages.confirmBid;

  const price = useMemo(
    () => ({
      [auction.currency.toLowerCase()]: minNextBid,
      referenceCurrency: auction.currency,
    }),
    [auction.currency, minNextBid]
  );

  return (
    <LazyPaymentProvider
      paymentProps={{
        auction,
        objectId: auction.id,
        onSuccess: onSuccessWithFiat,
        onSubmit: bid,
        price,
        cta: confirmMessage,
        canUseConversionCredit: true,
        currencies: [Currency.FIAT, ...(showEthWallet ? [Currency.ETH] : [])],
        sport: cards[0].sport,
        usePaypal: usePaypalForAuctions,
      }}
      paymentBoxProps={{
        onClose,
        title: (
          <Title5>
            <FormattedMessage
              id="BidPaymentModal.title"
              defaultMessage="Place your bid"
            />
          </Title5>
        ),
        tokenPreview: (
          <CardOverview
            cards={cards}
            soldOnPrimary
            showRecentSales={!useAuctionRevamp}
          />
        ),
        banner:
          auction.eligibleForBlueprintRewards && useEnableGoalRushFlow ? (
            <BuyPromoBanner />
          ) : null,
        loadingPolling: polling,
        outBidByAutoBid,
        setOutBidCallback,
      }}
    />
  );
};

BidPaymentModal.fragments = {
  anyCard: gql`
    fragment BidPaymentModal_anyCard on AnyCardInterface {
      slug
      ...CardOverview_anyCard
    }
    ${CardOverview.fragments.anyCard}
  ` as TypedDocumentNode<BidPaymentModal_anyCard>,
  auction: gql`
    fragment BidPaymentModal_auction on TokenAuction {
      id
      autoBid
      open
      cancelled
      bidsCount
      minNextBid
      privateMinNextBid
      currency
      bestBid {
        id
        ...UseBestBidBelongsToUser_bestBid
      }
      eligibleForBlueprintRewards
      ...useBidWithWallet_auction
      ...usePollAuctionBestBid_auction
      ...PaymentProvider_auction
    }
    ${useBidWithWallet.fragments.auction}
    ${useBestBidBelongsToUser.fragments.bestBid}
    ${usePollAuctionBestBid.fragments.auction}
    ${paymentProviderFragments.auction}
  ` as TypedDocumentNode<BidPaymentModal_auction>,
};
export default BidPaymentModal;
