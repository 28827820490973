import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Skeleton } from '@sorare/core/src/atoms/loader/Skeleton';
import { BodyS } from '@sorare/core/src/atoms/typography';
import { Chip } from '@sorare/core/src/atoms/ui/Chip';

import { Cards } from 'assets/icons/Cards';

const Card = styled(Vertical).attrs({ center: true })`
  aspect-ratio: var(--card-aspect-ratio);
  border: 1.5px solid rgba(var(--c-rgb-neutral-1000), 0.17);
  border-radius: var(--half-unit);
  color: rgba(var(--c-rgb-neutral-1000), 0.6);
  backdrop-filter: blur(var(--double-unit));
  background: rgba(var(--c-rgb-neutral-100), 0.1);

  &.hasCards {
    background: rgba(var(--c-rgb-neutral-1000), 0.1);
  }
`;

type Props = {
  icon: ReactNode;
  label: string;
  count?: number;
  loading: boolean;
  Footer: FC<{ children?: ReactNode }>;
};

export const SimpleCardPlaceholder = ({
  icon,
  label,
  count,
  loading,
  Footer,
}: Props) => {
  return (
    <Vertical>
      <Skeleton loading={loading}>
        <Card
          className={classNames({
            loading,
            hasCards: count === undefined || count > 0,
          })}
        >
          {icon}
          <BodyS as="p" bold>
            {label}
          </BodyS>
        </Card>
      </Skeleton>
      <Footer>
        {!loading && typeof count === 'number' && (
          <Chip
            size="smaller"
            {...(count
              ? { color: 'black' }
              : {
                  custom: {
                    background: 'var(--c-neutral-300)',
                    color: 'var(--c-neutral-600)',
                  },
                })}
            label={
              <Horizontal as="span" gap={0.5}>
                <Cards size={1.5} />
                <BodyS as="p" bold>
                  {count}
                </BodyS>
              </Horizontal>
            }
          />
        )}
      </Footer>
    </Vertical>
  );
};
