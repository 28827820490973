export const defaultTypePolicies = {
  Account: { keyFields: ["id"] },
  ActionReward: { keyFields: ["id"] },
  ActivitiesInterface: { keyFields: ["id"] },
  Announcement: { keyFields: ["id"] },
  AnnouncementNotification: { keyFields: ["id"] },
  AnonymousUser: { keyFields: ["id"] },
  AnyCardInterface: { keyFields: ["slug"] },
  AnyCardReward: { keyFields: ["id"] },
  AnyContestantInterface: { keyFields: ["id"] },
  AnyConversionCreditReward: { keyFields: ["id"] },
  AnyGameInterface: { keyFields: ["id"] },
  AnyPlayerGameStatsInterface: { keyFields: ["id"] },
  AnyPlayerInterface: { keyFields: ["slug"] },
  AnyRewardInterface: { keyFields: ["id"] },
  AppearanceBoostShopItem: { keyFields: ["id"] },
  AuctionDrop: { keyFields: ["id"] },
  AuctionNotification: { keyFields: ["id"] },
  AuctionReminder: { keyFields: ["id"] },
  AuthorizationRequest: { keyFields: ["id"] },
  BankAccountInterface: { keyFields: ["id"] },
  Banner: { keyFields: ["id"] },
  BannersSet: { keyFields: ["id"] },
  BaseballBoostApplied: { keyFields: ["id"] },
  BaseballCard: { keyFields: ["slug"] },
  BaseballCardBundleOffer: { keyFields: ["id"] },
  BaseballCardTrade: { keyFields: ["id"] },
  BaseballCoinReward: { keyFields: ["id"] },
  BaseballCollection: { keyFields: ["slug"] },
  BaseballCommonCardSample: { keyFields: ["id"] },
  BaseballContestantType: { keyFields: ["id"] },
  BaseballFixture: { keyFields: ["slug"] },
  BaseballGame: { keyFields: ["id"] },
  BaseballLeaderboard: { keyFields: ["slug"] },
  BaseballLeague: { keyFields: ["slug"] },
  BaseballLineup: { keyFields: ["id"] },
  BaseballPlayer: { keyFields: ["slug"] },
  BaseballPlayerGameScore: { keyFields: ["id"] },
  BaseballReferralReward: { keyFields: ["id"] },
  BaseballReward: { keyFields: ["id"] },
  BaseballScoutingMission: { keyFields: ["slug"] },
  BaseballShopListingBoost: { keyFields: ["slug"] },
  BaseballShopListingCardTrade: { keyFields: ["slug"] },
  BaseballShopListingMerchandise: { keyFields: ["slug"] },
  BaseballTeam: { keyFields: ["slug"] },
  BaseballUserInventoryBoost: { keyFields: ["slug"] },
  BaseballUserInventoryCardTrade: { keyFields: ["slug"] },
  BaseballUserInventoryMerchandise: { keyFields: ["slug"] },
  BaseballUserItemBoost: { keyFields: ["id"] },
  BaseballUserItemCardTrade: { keyFields: ["id"] },
  BaseballUserItemMerchandise: { keyFields: ["id"] },
  BaseballUserSportProfile: { keyFields: ["id"] },
  BasketballContestantType: { keyFields: ["id"] },
  BasketballPlayerGameScore: { keyFields: ["id"] },
  Blueprint: { keyFields: ["id"] },
  BoxReward: { keyFields: ["id"] },
  CaBankAccount: { keyFields: ["id"] },
  Card: { keyFields: ["slug"] },
  CardBoost: { keyFields: ["id"] },
  CardBundleOffer: { keyFields: ["id"] },
  CardCollection: { keyFields: ["slug"] },
  CardCollectionCard: { keyFields: ["id"] },
  CardCollectionNotification: { keyFields: ["id"] },
  CardCollectionSlot: { keyFields: ["id"] },
  CardEdition: { keyFields: ["id"] },
  CardInterface: { keyFields: ["slug"] },
  CardNotification: { keyFields: ["id"] },
  CardReward: { keyFields: ["id"] },
  CardSample: { keyFields: ["id"] },
  CardShardsChest: { keyFields: ["id"] },
  CardShardsReward: { keyFields: ["id"] },
  CardStats: { keyFields: ["id"] },
  CardSubscription: { keyFields: ["slug"] },
  CardTradeInterface: { keyFields: ["id"] },
  CashReward: { keyFields: ["id"] },
  Challenge: { keyFields: ["id"] },
  CheckoutCreditCardAuthorizationRequest: { keyFields: ["id"] },
  Club: { keyFields: ["slug"] },
  CoinReward: { keyFields: ["id"] },
  CollectionInterface: { keyFields: ["slug"] },
  CommonAccount: { keyFields: ["id"] },
  CommonCardSampleInterface: { keyFields: ["id"] },
  CommonCardSwapShopItem: { keyFields: ["id"] },
  CommonDraftCampaign: { keyFields: ["slug"] },
  CommonDraftSwap: { keyFields: ["id"] },
  CommonPlayer: { keyFields: ["id"] },
  Competition: { keyFields: ["slug"] },
  ComposeTeamBenchCard: { keyFields: ["id"] },
  ComposeTeamBenchCommonPlayer: { keyFields: ["id"] },
  ComposeTeamBenchObjectInterface: { keyFields: ["id"] },
  Config: { keyFields: ["id"] },
  ConnectedOAuth: { keyFields: ["id"] },
  ContentTileInterface: { keyFields: ["id"] },
  ContentUnit: { keyFields: ["id"] },
  ContentUnitsSet: { keyFields: ["id"] },
  Contestant: { keyFields: ["id"] },
  Contract: { keyFields: ["id"] },
  ConversionCredit: { keyFields: ["id"] },
  ConversionCreditCampaign: { keyFields: ["id"] },
  ConversionCreditReward: { keyFields: ["id"] },
  Country: { keyFields: ["slug"] },
  CumulativeLeaderboard: { keyFields: ["slug"] },
  CumulativeLeaderboardRank: { keyFields: ["id"] },
  CurrentUser: { keyFields: ["slug"] },
  CustomBanner: { keyFields: ["id"] },
  CustomDeck: { keyFields: ["slug"] },
  DataPartner: { keyFields: ["slug"] },
  DecisiveActionNotification: { keyFields: ["id"] },
  DecisivePurchaseNotification: { keyFields: ["id"] },
  Deck: { keyFields: ["slug"] },
  DeckCard: { keyFields: ["id"] },
  DeckInterface: { keyFields: ["slug"] },
  DeliverableItemInterface: { keyFields: ["slug"] },
  DeliverableItemOrder: { keyFields: ["id"] },
  DeliverableWithCustomVariantShopItem: { keyFields: ["id"] },
  DeliverableWithNoVariantShopItem: { keyFields: ["id"] },
  DraftableAppearance: { keyFields: ["id"] },
  DraftablePlayer: { keyFields: ["id"] },
  EmailSubscription: { keyFields: ["slug"] },
  EmailSubscriptionPreferences: { keyFields: ["slug"] },
  EmoteSentActivity: { keyFields: ["id"] },
  EthMigration: { keyFields: ["id"] },
  EthereumAccount: { keyFields: ["id"] },
  EthereumTransaction: { keyFields: ["id"] },
  ExchangeRate: { keyFields: ["id"] },
  ExperienceDeliverableItem: { keyFields: ["slug"] },
  ExternalDepositNotification: { keyFields: ["id"] },
  ExtraSwapShopItem: { keyFields: ["id"] },
  FastWithdrawal: { keyFields: ["id"] },
  FastWithdrawalWithRates: { keyFields: ["id"] },
  FiatWalletAccount: { keyFields: ["id"] },
  FirstRivalsLineupSubmittedActivity: { keyFields: ["id"] },
  FixtureInterface: { keyFields: ["slug"] },
  FootballContestantType: { keyFields: ["id"] },
  FootballManagerTask: { keyFields: ["id"] },
  FootballManagerTaskContentTile: { keyFields: ["id"] },
  FootballRivalLeaderboardInterface: { keyFields: ["id"] },
  FootballRivalLeaderboardRankingInterface: { keyFields: ["id"] },
  FootballRivalsAggregatedArenaStory: { keyFields: ["id"] },
  FootballRivalsAppearance: { keyFields: ["id"] },
  FootballRivalsArenaRequest: { keyFields: ["id"] },
  FootballRivalsChallenge: { keyFields: ["id"] },
  FootballRivalsChallengeContentTile: { keyFields: ["id"] },
  FootballRivalsChallenger: { keyFields: ["id"] },
  FootballRivalsConfig: { keyFields: ["id"] },
  FootballRivalsContestant: { keyFields: ["id"] },
  FootballRivalsCurrentManager: { keyFields: ["id"] },
  FootballRivalsDivisionLeaderboard: { keyFields: ["id"] },
  FootballRivalsDivisionLeaderboardDivisionConfig: { keyFields: ["id"] },
  FootballRivalsDivisionRanking: { keyFields: ["id"] },
  FootballRivalsDraftableCard: { keyFields: ["id"] },
  FootballRivalsDraftableObjectInterface: { keyFields: ["id"] },
  FootballRivalsDraftablePlayer: { keyFields: ["id"] },
  FootballRivalsEloGroup: { keyFields: ["id"] },
  FootballRivalsFeaturedGame: { keyFields: ["id"] },
  FootballRivalsGame: { keyFields: ["slug"] },
  FootballRivalsGameContentTile: { keyFields: ["id"] },
  FootballRivalsGameNotification: { keyFields: ["id"] },
  FootballRivalsInvite: { keyFields: ["id"] },
  FootballRivalsLineup: { keyFields: ["id"] },
  FootballRivalsLineupTactic: { keyFields: ["slug"] },
  FootballRivalsManager: { keyFields: ["id"] },
  FootballRivalsManagerNotification: { keyFields: ["id"] },
  FootballRivalsManagerSquadRanking: { keyFields: ["id"] },
  FootballRivalsPrivateRanking: { keyFields: ["id"] },
  FootballRivalsPromotionRelegationStory: { keyFields: ["id"] },
  FootballRivalsPromotionRelegationStoryInterface: { keyFields: ["id"] },
  FootballRivalsRivalry: { keyFields: ["id"] },
  FootballRivalsRoot: { keyFields: ["id"] },
  FootballRivalsSeason: { keyFields: ["slug"] },
  FootballRivalsSeasonSquad: { keyFields: ["id"] },
  FootballRivalsSquad: { keyFields: ["slug"] },
  FootballRivalsSquadMembershipChallenge: { keyFields: ["id"] },
  FootballRivalsSquadMembershipsLeaderboard: { keyFields: ["id"] },
  FootballRivalsSquadMembershipsLeaderboardRanking: { keyFields: ["id"] },
  FootballRivalsSquadPromotionRelegationStory: { keyFields: ["id"] },
  FootballRivalsSquadsLeaderboard: { keyFields: ["id"] },
  FootballRivalsSquadsLeaderboardRanking: { keyFields: ["id"] },
  FootballRivalsSuggestedManager: { keyFields: ["id"] },
  FootballRoot: { keyFields: ["id"] },
  FootballSo5NoCardRouteRoot: { keyFields: ["id"] },
  FootballUserSportProfile: { keyFields: ["id"] },
  FullWithdrawalRequest: { keyFields: ["id"] },
  Game: { keyFields: ["id"] },
  GameInterface: { keyFields: ["id"] },
  GameOfBaseball: { keyFields: ["id"] },
  GameOfBasketball: { keyFields: ["id"] },
  GbBankAccount: { keyFields: ["id"] },
  GiphySentActivity: { keyFields: ["id"] },
  HeroBanner: { keyFields: ["id"] },
  HeroBannersSet: { keyFields: ["id"] },
  IbanBankAccount: { keyFields: ["id"] },
  Injury: { keyFields: ["id"] },
  JerseyDeliverableItem: { keyFields: ["slug"] },
  JerseyShopItem: { keyFields: ["id"] },
  KycRequestNotification: { keyFields: ["id"] },
  LeaderboardInterface: { keyFields: ["slug"] },
  League: { keyFields: ["slug"] },
  LeagueInterface: { keyFields: ["slug"] },
  LevelUpShopItem: { keyFields: ["id"] },
  LineupInterface: { keyFields: ["id"] },
  LobbyTile: { keyFields: ["id"] },
  LoomAccount: { keyFields: ["id"] },
  ManagerProgression: { keyFields: ["id"] },
  ManagerProgressionCategory: { keyFields: ["id"] },
  ManagerProgressionMission: { keyFields: ["id"] },
  ManagerProgressionMissionContentTile: { keyFields: ["id"] },
  ManagerProgressionNotification: { keyFields: ["id"] },
  ManagerProgressionTask: { keyFields: ["id"] },
  ManagerTaskInterface: { keyFields: ["id"] },
  ManagerTeam: { keyFields: ["id"] },
  MangopayCreditCardAuthorizationRequest: { keyFields: ["id"] },
  MangopayKycDocument: { keyFields: ["id"] },
  MangopayKycRequest: { keyFields: ["id"] },
  MangopayRoot: { keyFields: ["id"] },
  MangopayUser: { keyFields: ["id"] },
  MangopayWithdrawal: { keyFields: ["id"] },
  MarketplacePromotionalEvent: { keyFields: ["id"] },
  MarketplacePromotionalEvents: { keyFields: ["id"] },
  MarketplaceSpecialEvent: { keyFields: ["id"] },
  MarketplaceSpecialEvents: { keyFields: ["id"] },
  Membership: { keyFields: ["id"] },
  MonetaryReward: { keyFields: ["id"] },
  MutuallyFollowedManagerRivalsGameContext: { keyFields: ["slug"] },
  NBACard: { keyFields: ["slug"] },
  NBACardBundleOffer: { keyFields: ["id"] },
  NBACardTrade: { keyFields: ["id"] },
  NBACollection: { keyFields: ["slug"] },
  NBACommonCardDrop: { keyFields: ["id"] },
  NBACommonCardSample: { keyFields: ["id"] },
  NBAFixture: { keyFields: ["slug"] },
  NBAGame: { keyFields: ["id"] },
  NBALeaderboard: { keyFields: ["slug"] },
  NBALeague: { keyFields: ["slug"] },
  NBALineup: { keyFields: ["id"] },
  NBAPlayer: { keyFields: ["slug"] },
  NBAReferralReward: { keyFields: ["id"] },
  NBAReward: { keyFields: ["id"] },
  NBARivalsArenaCompetition: { keyFields: ["slug"] },
  NBARivalsArenaLineup: { keyFields: ["id"] },
  NBARivalsArenaMatchup: { keyFields: ["id"] },
  NBARivalsCasualGame: { keyFields: ["slug"] },
  NBARivalsCasualLineup: { keyFields: ["id"] },
  NBARivalsCasualMatchup: { keyFields: ["id"] },
  NBARivalsEligiblePlayerSelectionCard: { keyFields: ["slug"] },
  NBARivalsEligiblePlayerSelectionCardSample: { keyFields: ["slug"] },
  NBARivalsEligiblePlayerSelectionInterface: { keyFields: ["slug"] },
  NBARivalsGameBundle: { keyFields: ["slug"] },
  NBARivalsLineupAppearanceCardBack: { keyFields: ["slug"] },
  NBARivalsLineupAppearanceCommonCardSample: { keyFields: ["slug"] },
  NBARivalsLineupAppearanceInterface: { keyFields: ["slug"] },
  NBARivalsLineupAppearanceRarityCard: { keyFields: ["slug"] },
  NBARivalsLineupV2: { keyFields: ["id"] },
  NBARivalsManager: { keyFields: ["slug"] },
  NBARivalsRivalryContext: { keyFields: ["slug"] },
  NBARivalsUserRivals: { keyFields: ["id"] },
  NBATeam: { keyFields: ["slug"] },
  NBAUserSportProfile: { keyFields: ["id"] },
  NationalTeam: { keyFields: ["slug"] },
  NoCardLineup: { keyFields: ["id"] },
  NoCardRoute: { keyFields: ["id"] },
  NoCardRouteLeaderboard: { keyFields: ["id"] },
  Node: { keyFields: ["id"] },
  NotificationInterface: { keyFields: ["id"] },
  OAuthAccessToken: { keyFields: ["id"] },
  OAuthApplication: { keyFields: ["id"] },
  OfferNotification: { keyFields: ["id"] },
  Onboarding: { keyFields: ["id"] },
  OnboardingCompetition: { keyFields: ["slug"] },
  OtherBankAccount: { keyFields: ["id"] },
  Owner: { keyFields: ["id"] },
  OwnerInterface: { keyFields: ["id"] },
  OwnerWithRates: { keyFields: ["id"] },
  Payment: { keyFields: ["id"] },
  PaymentMethod: { keyFields: ["id"] },
  PaypalAuthorizationRequest: { keyFields: ["id"] },
  PendingDeposit: { keyFields: ["id"] },
  PickablePlayer: { keyFields: ["id"] },
  Player: { keyFields: ["slug"] },
  PlayerGameScore: { keyFields: ["id"] },
  PlayerGameScoreInterface: { keyFields: ["id"] },
  PlayerGameStats: { keyFields: ["id"] },
  PlayerGameStatsBaseball: { keyFields: ["id"] },
  PlayerGameStatsBasketball: { keyFields: ["id"] },
  PlayerInterface: { keyFields: ["slug"] },
  PlayerRecommendation: { keyFields: ["id"] },
  PlayerStatus: { keyFields: ["id"] },
  PlayerWithSupply: { keyFields: ["slug"] },
  PreRegistrationData: { keyFields: ["id"] },
  PrivateAccount: { keyFields: ["id"] },
  PrivateEmptyAccount: { keyFields: ["id"] },
  PrivateFiatWalletAccount: { keyFields: ["id"] },
  PrivateKeyRecoveryOption: { keyFields: ["id"] },
  ProbabilisticBundle: { keyFields: ["id"] },
  ProbabilisticBundleConfig: { keyFields: ["id"] },
  ProbabilisticBundleReward: { keyFields: ["id"] },
  ProbabilisticBundleSlotArenaTicketItem: { keyFields: ["id"] },
  ProbabilisticBundleSlotCardItem: { keyFields: ["id"] },
  ProbabilisticBundleSlotCardShardItem: { keyFields: ["id"] },
  ProbabilisticBundleSlotConversionCreditItem: { keyFields: ["id"] },
  ProbabilisticBundleSlotDeliverableItem: { keyFields: ["id"] },
  ProbabilisticBundleSlotItemInterface: { keyFields: ["id"] },
  ProbabilisticBundleSlotRewardShopItem: { keyFields: ["id"] },
  ProbablePitchersForGameWeek: { keyFields: ["slug"] },
  PublicUserInfoInterface: { keyFields: ["slug"] },
  RaffleShopItem: { keyFields: ["id"] },
  Referral: { keyFields: ["id"] },
  ReferralLeaderboard: { keyFields: ["id"] },
  ReferralLeaderboardRanking: { keyFields: ["id"] },
  ReferralProgramConfig: { keyFields: ["id"] },
  ReferralReward: { keyFields: ["id"] },
  ReferralRewardInterface: { keyFields: ["id"] },
  ReferralRewardNotification: { keyFields: ["id"] },
  ReferralSpecialEvent: { keyFields: ["id"] },
  ReferralSpecialEventChallenge: { keyFields: ["id"] },
  ReferralTile: { keyFields: ["id"] },
  ReminderNotification: { keyFields: ["id"] },
  ResponsiveBanner: { keyFields: ["id"] },
  ResponsiveBannersSet: { keyFields: ["id"] },
  RevealedCardsSummary: { keyFields: ["id"] },
  RewardCard: { keyFields: ["id"] },
  RewardInterface: { keyFields: ["id"] },
  RivalsGameEndedActivity: { keyFields: ["id"] },
  RivalsGameStartedActivity: { keyFields: ["id"] },
  RivalsSquadMembership: { keyFields: ["id"] },
  RivalsUserGroup: { keyFields: ["slug"] },
  RivalsUserGroupMembership: { keyFields: ["id"] },
  RivalsUserGroupTournament: { keyFields: ["id"] },
  SaleNotification: { keyFields: ["id"] },
  ScoringMatrix: { keyFields: ["id"] },
  Season: { keyFields: ["id"] },
  SeasonPicture: { keyFields: ["id"] },
  ShopItem: { keyFields: ["id"] },
  ShopItemInterface: { keyFields: ["id"] },
  ShopItemNotification: { keyFields: ["id"] },
  ShopItemReward: { keyFields: ["id"] },
  ShopListingInterface: { keyFields: ["slug"] },
  SkinShopItem: { keyFields: ["id"] },
  So5Appearance: { keyFields: ["id"] },
  So5Campaign: { keyFields: ["slug"] },
  So5Config: { keyFields: ["id"] },
  So5Fixture: { keyFields: ["slug"] },
  So5Leaderboard: { keyFields: ["slug"] },
  So5LeaderboardContender: { keyFields: ["slug"] },
  So5LeaderboardGroup: { keyFields: ["slug"] },
  So5LeaderboardGroupInterface: { keyFields: ["slug"] },
  So5League: { keyFields: ["slug"] },
  So5LeagueTrack: { keyFields: ["slug"] },
  So5Lineup: { keyFields: ["id"] },
  So5LineupImport: { keyFields: ["id"] },
  So5LineupImportsBatch: { keyFields: ["id"] },
  So5LineupNotification: { keyFields: ["id"] },
  So5NoCardDraftableAppearance: { keyFields: ["id"] },
  So5NoCardLineup: { keyFields: ["id"] },
  So5Ranking: { keyFields: ["id"] },
  So5Reward: { keyFields: ["slug"] },
  So5RewardTargetConfig: { keyFields: ["slug"] },
  So5Root: { keyFields: ["id"] },
  So5Rules: { keyFields: ["id"] },
  So5Score: { keyFields: ["id"] },
  So5Tournament: { keyFields: ["id"] },
  So5UserGroup: { keyFields: ["slug"] },
  So5UserGroupMembership: { keyFields: ["id"] },
  So5UserGroupNotification: { keyFields: ["id"] },
  So5UserGroupTournament: { keyFields: ["id"] },
  SpecialWeeklyBanner: { keyFields: ["slug"] },
  SportsNode: { keyFields: ["id"] },
  SportsTeamInterface: { keyFields: ["slug"] },
  StageGroup: { keyFields: ["id"] },
  StarkwareAccount: { keyFields: ["id"] },
  StarkwarePrivateAccount: { keyFields: ["id"] },
  StarkwareWithdrawal: { keyFields: ["id"] },
  StarterPack: { keyFields: ["id"] },
  StartingLineupRevealedActivity: { keyFields: ["id"] },
  Stats: { keyFields: ["id"] },
  Story: { keyFields: ["id"] },
  StoryInterface: { keyFields: ["id"] },
  StripeCreditCardAuthorizationRequest: { keyFields: ["id"] },
  SubscriptionStats: { keyFields: ["id"] },
  Suspension: { keyFields: ["id"] },
  SwappablePlayer: { keyFields: ["id"] },
  TeamInterface: { keyFields: ["slug"] },
  TeamsPlayingNextGameWeek: { keyFields: ["slug"] },
  TicketsDeliverableItem: { keyFields: ["slug"] },
  Token: { keyFields: ["slug"] },
  TokenAuction: { keyFields: ["id"] },
  TokenBaseballMetadata: { keyFields: ["id"] },
  TokenBid: { keyFields: ["id"] },
  TokenCardMetadataInterface: { keyFields: ["id"] },
  TokenFootballMetadata: { keyFields: ["id"] },
  TokenMonetaryReward: { keyFields: ["id"] },
  TokenMyBid: { keyFields: ["id"] },
  TokenOffer: { keyFields: ["id"] },
  TokenOfferSide: { keyFields: ["id"] },
  TokenOwner: { keyFields: ["id"] },
  TokenPrice: { keyFields: ["id"] },
  TokenPrimaryOffer: { keyFields: ["id"] },
  TokenWithdrawal: { keyFields: ["id"] },
  TournamentCalendarStage: { keyFields: ["id"] },
  UsBankAccount: { keyFields: ["id"] },
  User: { keyFields: ["slug"] },
  UserAccountEntry: { keyFields: ["id"] },
  UserAward: { keyFields: ["slug"] },
  UserAwardNotification: { keyFields: ["id"] },
  UserCardCollection: { keyFields: ["id"] },
  UserCardCollectionSlot: { keyFields: ["slug"] },
  UserCardsCount: { keyFields: ["slug"] },
  UserDevice: { keyFields: ["id"] },
  UserDeviceEvent: { keyFields: ["id"] },
  UserFlag: { keyFields: ["id"] },
  UserGroupCreatedActivity: { keyFields: ["id"] },
  UserGroupInterface: { keyFields: ["slug"] },
  UserGroupMemberJoinedActivity: { keyFields: ["id"] },
  UserGroupMembershipInterface: { keyFields: ["id"] },
  UserGroupRankedActivity: { keyFields: ["id"] },
  UserInterface: { keyFields: ["slug"] },
  UserInventoryInterface: { keyFields: ["slug"] },
  UserItemInterface: { keyFields: ["id"] },
  UserNBACollection: { keyFields: ["id"] },
  UserOwner: { keyFields: ["id"] },
  UserProfile: { keyFields: ["id"] },
  UserProfileBadge: { keyFields: ["slug"] },
  UserRankingInterface: { keyFields: ["id"] },
  UserRelation: { keyFields: ["id"] },
  UserRelationNotification: { keyFields: ["id"] },
  UserSettings: { keyFields: ["id"] },
  UserShopItem: { keyFields: ["id"] },
  UserSource: { keyFields: ["id"] },
  UserSportProfileInterface: { keyFields: ["id"] },
  UserSportStatistic: { keyFields: ["id"] },
  UserWallet: { keyFields: ["id"] },
  UserWithSubscriptionSlug: { keyFields: ["slug"] },
  VerificationSession: { keyFields: ["id"] },
  WearableDeliverableItem: { keyFields: ["slug"] },
  WithSubscriptionsInterface: { keyFields: ["slug"] },
  Withdrawal: { keyFields: ["id"] },
  WithdrawalWithRates: { keyFields: ["id"] },
};
