import { useCallback } from 'react';
import { NavLinkProps } from 'react-router-dom';

import { NavLink as ReactRouterNavLink } from '@sorare/routing';

import { useEmitClickButton } from 'hooks/useEmitClickButton';

const NavLink = (props: NavLinkProps) => {
  const emitClickButton = useEmitClickButton();
  const { children, onClick, ...rest } = props;

  const clickHandler = useCallback(
    (e: any) => {
      onClick?.(e);
      emitClickButton();
    },
    [emitClickButton, onClick]
  );

  return (
    <ReactRouterNavLink onClick={clickHandler} {...rest}>
      {children}
    </ReactRouterNavLink>
  );
};

export default NavLink;
