import { CSSProperties, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Vertical } from 'atoms/layout/flex';
import { HexColor } from 'style/types';

import { Diamond } from '../Diamond';

const Wrapper = styled(Vertical).attrs({ gap: 0 })`
  position: relative;
  isolation: isolate;
  width: var(--size);
  height: var(--size);
`;
const RotatingWrapper = styled.div`
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
`;
const RotatedDiamond = styled(Diamond)`
  transform: rotate(90deg);
`;
const InnerImageWrapper = styled.div`
  & > * {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(1.1 * var(--size));
    aspect-ratio: 1;
    object-fit: contain;
  }
`;

const OuterImageWrapper = styled(InnerImageWrapper)`
  & > * {
    z-index: 1;
    clip-path: rect(0 100% calc(0.6 * var(--size)) 0);
  }
`;

type Props = {
  color?: HexColor;
  size?: number;
  className?: string;
};

export const DiamondImage = ({
  color,
  children,
  size,
  className,
}: PropsWithChildren<Props>) => {
  return (
    <Wrapper
      className={className}
      style={
        {
          '--size': `${size}px`,
        } as CSSProperties
      }
    >
      <RotatedDiamond width={size} height={size} color={color}>
        <RotatingWrapper>
          <InnerImageWrapper>{children}</InnerImageWrapper>
        </RotatingWrapper>
      </RotatedDiamond>
      <OuterImageWrapper>{children}</OuterImageWrapper>
    </Wrapper>
  );
};
