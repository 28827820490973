import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { Destination } from '@segment/consent-manager/types/types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import Button from 'atoms/buttons/Button';
import ButtonBase from 'atoms/buttons/ButtonBase';
import { FontAwesomeIcon } from 'atoms/icons';
import Switch from 'atoms/inputs/Switch';
import { ConditionalWrapper } from 'atoms/layout/ConditionalWrapper';
import { SBHorizontal, Vertical } from 'atoms/layout/flex';
import { Text16 } from 'atoms/typography';
import { glossary } from 'lib/glossary';

import LinkToCookiePolicy from './LinkToCookiePolicy';

const Root = styled(Vertical).attrs({ gap: 0 })`
  padding: 20px;
  gap: 20px;
  color: var(--c-neutral-1000);
  background: var(--c-neutral-200);
`;
const Title = styled.h2`
  font-size: 24px;
  margin: 0px 0px 10px;
`;
const Category = styled(SBHorizontal).attrs({ gap: 0 })`
  gap: 20px;
`;
const CategoryTitle = styled.div`
  font-weight: bold;
`;
const Destinations = styled.div`
  color: var(--c-neutral-600);
  & > *:not(:last-child):after {
    content: ', ';
  }
`;
const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const Continue = styled(ButtonBase)`
  border: none;
  padding: 0;
  margin: 0;
  background: 0;
  display: flex;
  align-self: flex-end;
  gap: 0.5em;
  align-items: center;
  cursor: pointer;
  font: inherit;
  color: var(--c-neutral-600);
`;

type Props = {
  saveConsent: (agree?: boolean) => void;
  seeDetails: () => void;
};

export const SimpleConsent = ({ saveConsent, seeDetails }: Props) => {
  return (
    <Root>
      <Continue type="button" onClick={() => saveConsent(false)}>
        <FormattedMessage
          tagName="span"
          id="CookieConsentBanner.reject"
          defaultMessage="Continue without agreeing"
        />
        <FontAwesomeIcon icon={faCaretRight} />
      </Continue>
      <div>
        <Title>
          <FormattedMessage
            id="CookieConsentBanner.title"
            defaultMessage="Sorare respects your privacy"
          />
        </Title>
        <Text16 color="var(--c-neutral-600)">
          <FormattedMessage
            id="CookieConsentBanner.description2"
            defaultMessage="We use cookies to provide you with the best browsing experience. The data collected by cookies is used to optimize the website for our visitors and deliver targeted information as well as personalized advertising to Sorare users."
          />
        </Text16>
        <Text16 color="var(--c-neutral-600)">
          <FormattedMessage
            id="CookieConsentBanner.cookie_policy"
            defaultMessage="To learn more, check our <link>Cookie Policy</link>."
            values={{
              link: LinkToCookiePolicy,
            }}
          />
        </Text16>
      </div>
      <Actions>
        <Button size="medium" color="tertiary" onClick={seeDetails}>
          <FormattedMessage tagName="span" {...glossary.seeDetails} />
        </Button>

        <Button
          size="medium"
          color="primary"
          onClick={() => {
            saveConsent(true);
          }}
        >
          <FormattedMessage
            id="CookieConsentBanner.agree"
            defaultMessage="Agree"
          />
        </Button>
      </Actions>
    </Root>
  );
};

type DetailedProps = {
  saveConsent: (agree?: boolean) => void;
  destinations: Destination[];
  preferences: { [key: string]: boolean | null | undefined };
  setPreferences: (newvalue: { [id: string]: boolean }) => void;
};

const LABELS: Record<string, string> = {
  'Facebook Conversions API': 'Facebook Ads',
  'Google Ads (Gtag)': 'Google Ads, Google Dynamic Retargeting, DoubleClick G',
  'Snapchat Conversions API': 'Snapchat Ads',
  'TikTok Conversions': 'TikTok Ads',
  'Twitter Ads': 'Twitter Ads',
  'Braze Web Device Mode (Actions)': 'Braze Web',
};

const LINKS: Record<string, string> = {
  'Google Analytics 4 Cloud': 'https://business.safety.google/privacy/',
  'Google Analytics 4 Web': 'https://business.safety.google/privacy/',
  'Google Ads (Gtag)': 'https://business.safety.google/privacy/',
  'Facebook Conversions API':
    'https://www.facebook.com/privacy/center/?entry_point=facebook_page_footer',
  'Snapchat Conversions API':
    'https://values.snap.com/en-GB/privacy/privacy-policy',
  'TikTok Conversions':
    'https://www.tiktok.com/legal/page/eea/privacy-policy/en',
  'Twitter Ads': 'https://twitter.com/en/privacy',
};

const DestinationLabel = ({ name }: { name: string }) => {
  const label = LABELS[name] || name;
  const linkProps =
    name in LINKS
      ? {
          to: LINKS[name],
          target: '_blank',
          rel: 'noreferrer',
        }
      : undefined;

  return (
    <ConditionalWrapper wrap={!!linkProps} Wrapper={Link} props={linkProps}>
      {label}
    </ConditionalWrapper>
  );
};

export const DetailedConsent = ({
  saveConsent,
  destinations,
  preferences,
  setPreferences,
}: DetailedProps) => {
  const categories: { [key: string]: Destination[] } = {};
  destinations.forEach(d => {
    if (!(d.category in categories)) {
      categories[d.category] = [];
    }
    categories[d.category].push(d);
  });

  const setCategory = (category: string, consent: boolean) => {
    setPreferences(
      Object.fromEntries(categories[category].map(d => [d.id, consent]))
    );
  };

  return (
    <Root>
      <div>
        <Title>
          <FormattedMessage
            id="CookieConsentBanner.details.title"
            defaultMessage="Your preferences"
          />
        </Title>

        <Text16 color="var(--c-neutral-600)">
          <FormattedMessage
            id="CookieConsentBanner.details.description"
            defaultMessage="We and our partners use cookies and non sensible information from your device to improve our product."
          />
        </Text16>
        <Text16 color="var(--c-neutral-600)">
          <FormattedMessage
            id="CookieConsentBanner.details.description2"
            defaultMessage="You can accept or reject those operations."
          />
        </Text16>
        <Text16 color="var(--c-neutral-600)">
          <FormattedMessage
            id="CookieConsentBanner.details.cookie_policy"
            defaultMessage="To learn more about the cookies, the data, the processings we do and the partners we work with, you can check our <link>cookie policy</link>."
            values={{
              link: LinkToCookiePolicy,
            }}
          />
        </Text16>
      </div>
      {Object.keys(categories).map(category => {
        const accepted = categories[category].every(
          destination => preferences[destination.id] === true
        );
        return (
          <Category key={category}>
            <div>
              <CategoryTitle>{category}</CategoryTitle>
              <Destinations>
                {categories[category].map(destination => (
                  <span key={destination.id}>
                    <DestinationLabel name={destination.name} />
                  </span>
                ))}
              </Destinations>
            </div>
            <Switch
              checked={accepted}
              onChange={() => setCategory(category, !accepted)}
            />
          </Category>
        );
      })}
      <Actions>
        <Button
          size="medium"
          color="tertiary"
          onClick={() => {
            saveConsent(false);
          }}
        >
          <FormattedMessage
            id="CookieConsentBanner.details.reject"
            defaultMessage="Reject all"
          />
        </Button>

        <Button
          size="medium"
          color="primary"
          onClick={() => {
            saveConsent();
          }}
        >
          <FormattedMessage
            id="CookieConsentBanner.details.agree"
            defaultMessage="Accept selected"
          />
        </Button>
      </Actions>
    </Root>
  );
};
